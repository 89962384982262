import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import env from '../../env/src_config'

const Leftbar = () => {
    return (
        <Fragment>
            <div className="header-logo-wrapper col-auto p-0" id="out_side_click">
                <div className="logo-wrapper">
                    <Link to={`${process.env.PUBLIC_URL}/`}>
                        <img style={{ width: 'auto', height: '37px' }} className="img-fluid for-light d-inline-block" src={env.api + '/media/images/logo.png'} alt="logo" />
                        {/* <img style={{ width: 'auto', height: '37px' }} className="img-fluid for-dark" src={env.api + '/media/images/logo.png'} alt="Dark-logo" /> */}
                        <span className='ms-2'>ERP SYSTEM</span>
                    </Link>
                </div>
            </div>
      </Fragment>
    )
}

export default Leftbar
