import React, {useState, useEffect} from 'react'
import {t} from 'i18next'
import { FormGroup, Label, Input } from 'reactstrap'
import { isNull, number_format, fieldsWithCheckboxes } from '../../izUtils'

const TextInput = ({data, onChange, onBlur, showErrors, lsFilterName}) => {
    const { type, name, display, errors, title, disabled, mandatory} = data;
    const isFilter = !isNull(lsFilterName)

    const [value, setValue] = useState("")
    const [checkboxValue, setCheckboxValue] = useState(false)

    useEffect(() => {
        let valComma = (data.value+'').replace(/\./g, ',')
        setValue(isNull(data.value) ? '' : valComma)
        setCheckboxValue(isNull(data.value) || data.value === '' ? true : false)

    }, [data.value])

    const handleChange = (e) => {
        setValue(e.target.value)

        // Save filter data to LS, so it can be used if user clicks "Confirm" button
        if (isFilter) {
            let lsFilter = JSON.parse(localStorage.getItem(lsFilterName))
            if (!isNull(lsFilter)) {
                for (const filterName in lsFilter) {
                    if (filterName === name) {
                        lsFilter[filterName].value = e.target.value;
                        localStorage.setItem(lsFilterName, JSON.stringify(lsFilter))
                    }
                }
            }
        }
    };

    const numericFilter = (e) => {
        if (type === 'number') { // Float
            /* allow only one comma */
            if ((e.which === 188) || (e.which === 110)) {
                for (let i = 0; i < e.target.value.length;i++) {
                    if (e.target.value[i] === ',') {
                        e.preventDefault();
                    }
                }
            }

            if (e.shiftKey) e.preventDefault();
    
            if (((e.which >= 48) && (e.which <= 57)) || // Numbers
                (e.which === 8) || // Backspace
                (e.which === 46) || // Del
                (e.which === 9) || // Tab
                ((e.which >= 37) && (e.which <= 40)) || // Arrows
                ((e.which >= 96) && (e.which <= 105)) || // Num pad
                ((e.which === 190) || (e.which === 188)) || // Dot and comma
                ((e.which === 110)) // Num pad comma
            ) {
            } else {
                e.preventDefault();
            }
        } else { // Integer
            if (e.shiftKey) e.preventDefault();
    
            if (((e.which >= 48) && (e.which <= 57)) || // Numbers
                (e.which === 8) || // Backspace
                (e.which === 46) || // Del
                (e.which === 9) || // Tab
                ((e.which >= 37) && (e.which <= 40)) || // Arrows
                ((e.which >= 96) && (e.which <= 105)) // Num pad
            ) {
            } else {
                e.preventDefault();
            }
        }
    }

    const handleBlur = () => {
        let val = value;
        if (value.length !== 0) {
            val = value.replace(/\./g, '');
            val = val.replace(',', '.');
            val = number_format(val, 2, '.', '');
            val = parseFloat(val);
        }
        if (!isNull(onBlur)) onBlur(val, name)
    }

    // Handle checkbox after text input
    const handleCheckbox = () => {
        if (!checkboxValue) {
            setValue('')
            onBlur('', name)
        }
        setCheckboxValue(!checkboxValue)
    }

    let hasCheckbox = false
    let displayNumberInput = true
    if (window.location.pathname.includes('contracts/') && fieldsWithCheckboxes.indexOf(name) !== -1) {
        hasCheckbox = true
        if (checkboxValue) displayNumberInput = false
    }
    // END handle checkbox after text input

    if (display) {
        return (
            <FormGroup>
                <Label>{title + (mandatory ? " *" : "")}</Label>
                {displayNumberInput &&
                    <input
                        className={"form-control"}
                        disabled={disabled}
                        type={'text'}
                        name={name}
                        placeholder={t(`form.placeholder.${name}`)}
                        onKeyDown={numericFilter}
                        onChange={handleChange}
                        value={value+''}
                        onBlur={handleBlur}
                    />
                }
                {name === 'work_estimate' && <div className='input-note'>{t(`form.note.${name}`)}</div>}
                {hasCheckbox &&
                    <div className="form-checkbox-group ms-4 mt-1">
                        <Input
                            className={"custom-control-input"}
                            id={'checkbox-'+name}
                            type="checkbox"
                            onChange={handleCheckbox}
                            checked={checkboxValue}
                            disabled={disabled}
                        />
                        <Label className="checkmark" htmlFor={'checkbox-'+name}></Label>
                        <Label className="custom-control-label" htmlFor={'checkbox-'+name} style={{ cursor: 'pointer', fontWeight: '400' }} >{t('form.note.priceList')}</Label>
                        <div className="invalid-feedback">{"Example invalid feedback text"}</div>
                    </div>
                }

                {!isNull(showErrors) && showErrors && !isNull(errors) && errors.length !== 0 && <div style={{ color: "red", marginBottom: "10px" }}>{errors[0]}</div>}
            </FormGroup>
        );
    } else {
        return null;
    }
}

export default TextInput