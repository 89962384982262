import { atom } from 'recoil';

export const headersState = atom({
  key: 'headersState',
  default: {
    "Accept": 'application/json',
    "Accept-Language": "sl-SI",
    "Time-Adjustment": (new Date().getTimezoneOffset() / 60)*-1,
  },
});

export let tokenState = atom({
  key: 'tokenState',
  default: {
    token: null,
    parsedToken: null,
  }
});

export let refreshException = atom({
  key: 'refreshException',
  default: false
});

export let multiSelectLoading = atom({
  key: 'multiSelectLoading',
  default: false
});

export let materialLoading = atom({
  key: 'materialLoading',
  default: false
});

export let serviceDraft = atom({
  key: 'serviceDraft',
  default: {
    isDraft: false,
    serviceId: null,
    values: null,
  }
});

export let saveContractToLinkedSystem = atom({
  key: 'saveContractToLinkedSystem',
  default: null
});