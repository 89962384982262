import React, { Fragment, useEffect, useState } from 'react'
import {t} from 'i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil'
import axios from 'axios';
import { Accordion } from 'react-bootstrap';
import { Container, Card, CardBody, Button, Row, Col, Collapse, Modal, ModalHeader, ModalBody } from 'reactstrap'

import env from '../../env/src_config';
import { headersState } from '../../recoil/recoil';
import { isNull } from '../../izUtils';
import Spinner from '../../components/spinner/Spinner';
import Breadcrumbs from '../../layout/breadcrumb/Breadcrumb';
import { transformer } from '../../helpers/fields';
import { axiosError } from '../../helpers/response';
import PaginationTop from '../../components/Pagination/PaginationTop';
import PaginationBottom from '../../components/Pagination/PaginationBottom';
import AllFacilityList from '../../components/facilities/AllFacilityList';
import Filter from '../../components/filters/Filter';
import Loader from '../../components/spinner/Loader';

const AllFacilities = () => {
    const navigate = useNavigate();
    const headers = useRecoilValue(headersState);

    const [facilities, setFacilities] = useState(null)
    const [tableLoading, setTableLoading] = useState(false)
    const [filters, setFilters] = useState(null)
    const [isFilterOpen, setIsFilterOpen] = useState(true);
    const [securitySystemComponents, setSecuritySystemComponents] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)

    const lsFilterName = 'AKODA.facilitiesAllFilter';

    const modaltoggle = () => setModalOpen(val => !val)

    useEffect(() => {
        const lsFilter = JSON.parse(localStorage.getItem(lsFilterName));
        getFacilities(isNull(lsFilter) ? {} : lsFilter);

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getFacilities = (passedFilters) => {
        setTableLoading(true);

        const keys = Object.keys(passedFilters);
        let payload = { filters: {} };
        for (let i = 0; i < keys.length; i++) {
            if (!isNull(passedFilters[keys[i]].value) && passedFilters[keys[i]].value.length !== 0) {
                payload.filters[keys[i]] = passedFilters[keys[i]].value;
            } else {
                payload.filters[keys[i]] = "";
            }
        }

        axios.post(env.api + '/api/client/facility/all', payload, {headers}).then(response => {
            if (!isNull(response.data.data)) {
                setFacilities(response.data.data);
                if (!isNull(response.data.filters)) {
                    setFilters(transformer(response.data.filters));
                    localStorage.setItem(lsFilterName, JSON.stringify(transformer(response.data.filters)))
                }
            }
            setTableLoading(false);
        }).catch(error => {
            setTableLoading(false);
            axiosError(error, navigate);
        });
    }

    const clearData = () => {
        localStorage.removeItem(lsFilterName);
        getFacilities({});
    }

    const showSystems = (system) => {
        modaltoggle();
        axios.post(env.api + '/api/client/facility/system/components/list/' + system.id, {}, {headers}).then(response => {
            if (!isNull(response.data.data)) {
                setSecuritySystemComponents(response.data.data);
            }
        }).catch(error => {
            axiosError(error, navigate);
        });
    }

    const closeSystems = (system) => {
        setSecuritySystemComponents(null);
        modaltoggle();
    }

    if (isNull(facilities)) {
        return <Spinner />
    } else {
        const filterFields = ['client', 'name', 'location', 'security_system', 'manufacturer', 'system', 'central']
        return (
            <Fragment>
                <Breadcrumbs title={t('breadcrumb.allFacilities')} />
                <Container fluid={true}>
                    <Row>
                        <Col sm="12"> 
                            <Card className="ribbon-wrapper">
                                <CardBody>
                                    <div className="ribbon ribbon-clip ribbon-primary">{t('facilities.all.title')}</div>
                                    <div>
                                        <Accordion className='mb-3'>
                                            <div className="default-according style-1" id="facilityfilter">
                                                <Button color="secondary" className="acc-btn d-flex justify-content-between align-items-center" data-toggle="collapse" onClick={() => setIsFilterOpen(!isFilterOpen)}>
                                                    {t('filters')}
                                                    <div className={'filter-icon' + (isFilterOpen ? ' rotate-icon' : '')}>
                                                        <i className="fa fa-chevron-down"></i>
                                                    </div>
                                                </Button>
                                                <Collapse isOpen={isFilterOpen}>
                                                    <div className='p-3'>
                                                        <Filter filterFields={filterFields} filters={filters} getData={(clonedFilters) => getFacilities(clonedFilters)} lsFilterName={lsFilterName} clearData={clearData} />
                                                    </div>
                                                </Collapse>
                                            </div>
                                        </Accordion>
                                        <div style={{ position: 'relative' }}>
                                            {tableLoading && <Loader />}
                                            <PaginationTop filters={filters} getData={clonedFilters => getFacilities(clonedFilters)} />
                                            <AllFacilityList data={facilities} headers={headers} showSystems={showSystems}/>
                                            <PaginationBottom filters={filters} getData={clonedFilters => getFacilities(clonedFilters)} />
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <hr/>
                    <div className='d-flex flex-wrap justify-content-between mb-4'>
                        <Button color="danger" className="mt-2 me-2" onClick={() => navigate(-1)}>{t('back')}</Button> 
                    </div>
                </Container>
                <Modal isOpen={modalOpen} toggle={closeSystems} centered size="xl">
                    <ModalHeader toggle={closeSystems}>
                        {t('facilities.securitySystems.title')}
                    </ModalHeader>
                    <ModalBody>
                        {isNull(securitySystemComponents) ?
                            <Spinner />
                            :
                            (securitySystemComponents.length === 0 ?
                                t('facilities.securitySystems.noData')
                                :
                                <Col sm="12">
                                    <Row className='d-none d-lg-flex m-0 py-1'>
                                        <Col lg="3" className="fw-bold">{t('table.manufacturer')}</Col>
                                        <Col lg="3" className="fw-bold">{t('table.system')}</Col>
                                        <Col lg="2" className="fw-bold">{t('table.central_station')}</Col>
                                        <Col lg="2" className="fw-bold">{t('table.component_notes')}</Col>
                                        <Col lg="2" className="fw-bold">{t('table.component_number')}</Col>
                                    </Row>
                                    <div className='contract-system-list'>
                                        {securitySystemComponents.map((ssc, i) => {
                                            return <Row key={"systemComponent-"+i} className="m-0 py-1">
                                                <Col lg="3"><span className="d-inline d-lg-none fw-bold">{t('table.manufacturer')}: </span>{ssc?.manufacturer[0]?.title}</Col>
                                                <Col lg="3"><span className="d-inline d-lg-none fw-bold">{t('table.system')}: </span>{ssc?.system[0]?.title}</Col>
                                                <Col lg="2"><span className="d-inline d-lg-none fw-bold">{t('table.central_station')}: </span>{ssc?.central_station[0]?.title}</Col>
                                                <Col lg="2"><span className="d-inline d-lg-none fw-bold">{t('table.component_notes')}: </span>{ssc?.component_notes}</Col>
                                                <Col lg="2"><span className="d-inline d-lg-none fw-bold">{t('table.component_number')}: </span>{ssc?.component_number}</Col>
                                            </Row>
                                        })}
                                    </div>
                                </Col>
                            )
                        }
                    </ModalBody>
                </Modal>
            </Fragment>
        )
    }
}

export default AllFacilities