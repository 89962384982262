import React, { useState, useEffect } from 'react'
import {t} from 'i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Row, Col, Button, Collapse, Card, CardBody, CardFooter } from 'reactstrap'
import { useRecoilValue } from 'recoil'
import axios from 'axios'
import { Accordion } from 'react-bootstrap';

import env from '../../env/src_config'
import { headersState, tokenState } from '../../recoil/recoil';
import Spinner from '../spinner/Spinner'
import UserList from './lists/UserList'
import PaginationTop from '../Pagination/PaginationTop'
import PaginationBottom from '../Pagination/PaginationBottom'
import { axiosError } from '../../helpers/response'
import { isNull } from '../../izUtils'
import { transformer } from '../../helpers/fields'
import AddUpdateUser from './AddUpdateUser'
import Filter from '../filters/Filter'

const UsersComponent = ({usersConfirmed}) => {
    const Navigate = useNavigate();
    const { userId, confirmed } = useParams();
    
    const headers = useRecoilValue(headersState);
    const tokenData = useRecoilValue(tokenState);
    const [isOpen, setIsOpen] = useState(true);
    const [users, setUsers] = useState(null);
    const [filters, setFilters] = useState(null);

    const lsFilterName = usersConfirmed ? 'AKODA.userFilter-confirmed' : 'AKODA.userFilter-notConfirmed';

    useEffect(() => {
        const lsFilter = JSON.parse(localStorage.getItem(lsFilterName));
        let filter = isNull(lsFilter) ? {} : lsFilter;
        if (usersConfirmed) {
            filter.confirmed = {value: true};
        } else {
            filter.type = {value: "client"};
            filter.confirmed = {value: false};
        }

        getUsers(filter);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getUsers = (passedFilters) => {
        const keys = Object.keys(passedFilters);
        let payload = { filters: {} };
        for (let i = 0; i < keys.length; i++) {
            if (!isNull(passedFilters[keys[i]].value) && passedFilters[keys[i]].value.length !== 0) {
                payload.filters[keys[i]] = passedFilters[keys[i]].value;
            } else {
                payload.filters[keys[i]] = "";
            }
        }
        
        axios.post(env.api + '/api/user/list', payload, {headers}).then(response => {
            if (!isNull(response.data.data)) {
                setUsers(response.data.data);
                if (!isNull(response.data.filters)) {
                    setFilters(transformer(response.data.filters));
                    localStorage.setItem(lsFilterName, JSON.stringify(transformer(response.data.filters)))
                }
            }
        }).catch(error => {
            axiosError(error, Navigate);
        });
    }

    const clearData = () => {
        localStorage.removeItem(lsFilterName)
        let filter = {};
        if (usersConfirmed) {
            filter.confirmed = {value: true};
        } else {
            filter.type = {value: "client"};
            filter.confirmed = {value: false};
        }

        getUsers(filter);
    }

    const setParamsForFilter = (filters) => {
        filters.confirmed = {value: usersConfirmed};
        getUsers(filters)
    }

    if (isNull(users)) {
        return <Spinner />
    } else {
        const filterFields = ['type']
        return (
            <Row>
                <Col md="6">
                    <Card className="ribbon-wrapper">
                        <CardBody>
                            <div className="ribbon ribbon-clip ribbon-primary">{usersConfirmed ? t('users.list.title') : t('users.listNotConfirmed.title')}</div>
                            <div>
                                <Accordion className='mb-3'>
                                    <div className="default-according style-1" id="userfilter">
                                        <Button color="secondary" className="acc-btn d-flex justify-content-between align-items-center" data-toggle="collapse" onClick={() => setIsOpen(!isOpen)}>
                                            {t('filters')}
                                            <div className={'filter-icon' + (isOpen ? ' rotate-icon' : '')}>
                                                <i className="fa fa-chevron-down"></i>
                                            </div>
                                        </Button>
                                        <Collapse isOpen={isOpen}>
                                            <div className='p-3'>
                                                <Filter filterFields={filterFields} filters={filters} getData={(clonedFilters) => setParamsForFilter(clonedFilters)} lsFilterName={lsFilterName} clearData={clearData} />
                                            </div>
                                        </Collapse>
                                    </div>
                                </Accordion>
                                <PaginationTop filters={filters} getData={clonedFilters => getUsers(clonedFilters)} />
                                <UserList data={users} usersConfirmed={usersConfirmed}/>
                                <PaginationBottom filters={filters} getData={clonedFilters => getUsers(clonedFilters)} />
                            </div>
                        </CardBody>
                        {(tokenData.parsedToken.type === 'admin') &&
                            <CardFooter>
                                {usersConfirmed && <Button color="primary" className="mt-2 me-2" onClick={() => Navigate('/users/' + confirmed + '/create')}>{t('users.list.addNew')}</Button>}
                            </CardFooter>
                        }
                    </Card>
                </Col>
                <Col md="6">
                    {!isNull(userId) && <AddUpdateUser getUsers={filter => getUsers(filter)} usersConfirmed={usersConfirmed} users={users} />}
                </Col>  
            </Row>
        )
    }
}

export default UsersComponent