import React, { Fragment, useEffect, useState, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { t } from 'i18next'
import { useRecoilState } from 'recoil'
import { Button, Form, Card, CardBody, CardFooter } from 'reactstrap'
import Swal from 'sweetalert2'

import env from '../../../env/src_config'
import { headersState } from '../../../recoil/recoil'
import { axiosError } from '../../../helpers/response'
import { isNull } from '../../../izUtils'
import { transformer, linker } from '../../../helpers/fields'
import Spinner from '../../spinner/Spinner'
import { errorStatus } from '../../../helpers/response'
import SecuritySystemComponent from '../securitySystemComponents/SecuritySystemComponent'

const AddUpdateLinkedSystem = ({getLinkedSystems}) => {
    const Navigate = useNavigate();
    const [headers] = useRecoilState(headersState);
    const { clientId, facilityId, linkedSystemId } = useParams()
    const linkedSystemRef = useRef(null);

    const [inputs, setInputs] = useState(null)
    const [showErrors, setShowErrors] = useState(false)

    useEffect(() => {
        if (!isNull(inputs)) setInputs(null)
        setShowErrors(false);
        updateData(false, {});
        if (!isNull(linkedSystemRef.current)) linkedSystemRef.current.scrollIntoView()
        
    }, [linkedSystemId]) // eslint-disable-line react-hooks/exhaustive-deps

    const updateData = (save, passedInputs) => {
        let payload = { save };
        if (!isNull(passedInputs)) {
            // Get data from inputs
            const keys = Object.keys(passedInputs);
            payload.data = {};
            for (let i = 0; i < keys.length; i++) {
                if (!isNull(passedInputs[keys[i]].value) && passedInputs[keys[i]].value.length !== 0) {
                    payload.data[keys[i]] = passedInputs[keys[i]].value;
                } else {
                    payload.data[keys[i]] = "";
                }
            }
        }

        let method = "patch";
        if (linkedSystemId === 'create') method = "post";
        axios[method](env.api + '/api/client/facility/system/' + linkedSystemId + ((linkedSystemId === 'create') ? '/' + facilityId : ''), payload, {headers}).then(response => {
            if (save) {
                getLinkedSystems();

                if (!isNull(response.data.state) && response.data.state === 'success') {
                    Swal.fire({
                        title: t('saved'), 
                        text: response.data.message,
                        icon: 'success',
                        confirmButtonColor: 'var(--theme-default)',
                        confirmButtonText: t('ok'),
                    }).then(() => Navigate('/clients/' + clientId + '/facilities/' + facilityId + '/linked-systems/' + response.data.id))
                } else {
                    setShowErrors(true)
                    errorStatus(response.data, t);
                } 
            }

            if (!isNull(response.data.data)) setInputs(transformer(response.data.data));
        }).catch(error => {
            axiosError(error, Navigate);
        });
    }

    const textChange = (value, name) => {
        let clonedInputs = {...inputs}
        clonedInputs[name].value = value;
        updateData(false, clonedInputs);
    }

    // Create fields
    const mapLinker = (field) => {
        const inputlinkerFields = {
            field,
            inputs,
            showErrors,
            textHandler: (value, id) => textChange(value, id),
            selectHandler: (value, id) => textChange(value, id),
            booleanHandler: (value, id) => textChange(value, id),
            dateHandler: (value, id) => textChange(value, id),
        }

        return linker(inputlinkerFields);
    }

    return (
        <Fragment>
            <Card className="ribbon-wrapper">
                <CardBody>
                    <div ref={linkedSystemRef} className="ribbon ribbon-clip ribbon-primary">{(linkedSystemId === 'create') ? t('facilities.linkedSystems.create.title') : t('facilities.linkedSystems.update.title')}</div>
                    {isNull(inputs) ?
                        <Spinner />
                        :
                        <Fragment>
                            <Form className="theme-form mb-5">
                                { ['security_system', 'check_price', 'system_checks_number', 'system_warranty', 'system_warranty_date', 'check_notes'].map(field => mapLinker(field)) }
                                {linkedSystemId !== 'create' && <SecuritySystemComponent />}
                                { [ 'security_system_inspections'].map(field => mapLinker(field)) }
                            </Form>
                        </Fragment>
                    }
                </CardBody>
                <CardFooter>
                    <div className='text-end'>
                        <Button color="primary" className="mt-2 me-2" onClick={() => updateData(true, inputs)}>{(linkedSystemId === 'create') ? t('add') : t('save')}</Button>
                    </div>
                </CardFooter>
            </Card>
        </Fragment>
    )
}

export default AddUpdateLinkedSystem