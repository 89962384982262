import React, {useState, useEffect} from 'react'
import {t} from 'i18next'
import { FormGroup, Label, Row, Col, Button } from 'reactstrap'
import { useRecoilState } from 'recoil'

import { isNull } from '../../../izUtils'
import { materialLoading } from '../../../recoil/recoil'
import Loader from '../../spinner/Loader'

const MaterialInput = ({data, onChange, onBlur, showErrors}) => {
    const { name, display, errors, title, disabled, mandatory} = data;

    const [loading, setLoading] = useRecoilState(materialLoading);
    const [values, setvalues] = useState([])

    useEffect(() => {
        if (isNull(data.value) || data.value === '' || data.value.length === 0) {
            setvalues([])
        } else {
            const dataValue = [];
            data.value.forEach(item => {
                dataValue.push(item);
            })

            setvalues(dataValue)
        }
    }, [JSON.stringify(data.value)]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (e, type, index) => {
        let clonedValue = [...values]
        clonedValue[index][type] = e.target.value
        setvalues(clonedValue)
    };

    const handleBlur = (e, type, index) => {
        if (!isNull(onBlur)) {
            setLoading(true)
            let clonedValues = [...values]
            if (type === 'quantity') {
                clonedValues[index][type] = Math.abs(e.target.value)
                setvalues(clonedValues)
            }
            onBlur(clonedValues, name)
        }
    };

    const addNew = () => {
        setLoading(true)
        let clonedValue = [...values]
        clonedValue.push({ name: null, quantity: null })
        setvalues(clonedValue)
        onBlur(clonedValue, name)
    }

    const remove = (index) => {
        setLoading(true)
        let clonedValue = [...values]
        let filteredValue = clonedValue.filter((item, i) => index !== i)
        setvalues(filteredValue)
        onBlur(filteredValue, name)
    }

    if (display) {
        return (
            <FormGroup style={{ position: 'relative' }}>
                {loading && <Loader />}
                {(!disabled || (disabled && values.length !== 0)) && <h5>{title + (mandatory ? " *" : "")}</h5>}
                {values.map((item, index) => (
                    <Row key={"material-"+index} className='mb-2'>
                        <Col sm="7">
                            <Label className={"material-input-" + (index === 0 ? "first" : "other")}>{t(`form.label.${name}.name`)}</Label>
                            <input 
                                className="form-control"
                                disabled={disabled || loading}
                                type="text"
                                name={name+'-name'}
                                placeholder={t(`form.placeholder.${name}.name`)}
                                onChange={(e) => handleChange(e, 'name', index)}
                                value={isNull(item.name) ? '' : item.name}
                                onBlur={(e) => handleBlur(e, 'name', index)}
                            />
                        </Col>
                        <Col sm="4">
                            <Label className={"material-input-" + (index === 0 ? "first" : "other")}>{t(`form.label.${name}.quantity`)}</Label>
                            <input 
                                className="form-control"
                                disabled={disabled || loading}
                                type="number"
                                min="0"
                                name={name+'-quantity'}
                                placeholder={t(`form.placeholder.${name}.quantity`)}
                                onChange={(e) => handleChange(e, 'quantity', index)}
                                value={isNull(item.quantity) ? '' : item.quantity}
                                onBlur={(e) => handleBlur(e, 'quantity', index)}
                            />
                        </Col>
                        <Col sm="1" className='d-flex align-items-end mt-2'>
                            {!disabled &&
                                <span onClick={() => remove(index)}>
                                    <i className="icon-trash bigger-icon"></i>
                                </span>
                            }
                        </Col>
                    </Row>
                ))}
                {!isNull(showErrors) && showErrors && !isNull(errors) && errors.length !== 0 && 
                    errors.map((error, index) => (
                        <div key={'material-error-'+index} style={{ color: "red", marginBottom: "10px" }}>{error}</div>
                    ))
                }
                {!disabled &&
                    <div className='text-start mt-3'>
                        <Button color="primary" onClick={addNew}>{t('form.materials.addNew')}</Button> 
                    </div>
                }
            </FormGroup>
        );
    } else {
        return null;
    }
}

export default MaterialInput