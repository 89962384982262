import React, { useEffect, useState, useMemo, useRef } from 'react'
// import { useNavigate, useParams } from 'react-router-dom'
import {t} from 'i18next'
import DataTable from 'react-data-table-component'
import { FormGroup } from 'reactstrap'
import moment from 'moment'

import { customStyles, displayDate, isNull } from '../../../../izUtils'

const ContractList = ({data, handleContract, tokenData}) => {

    const [showDropdown, setShowDropdown] = useState(false);
    const [dropdownPosition, setDropdownPosition] = useState({ x: 0, y: 0 });
    const [dropdownOptions, setDropdownOptions] = useState([]);

    const buttonRef = useRef([]);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            // Close the element if clicked outside of it
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                if (buttonRef.current[showDropdown] !== event.target) {
                    setShowDropdown(false);
                }
            }
        }

        document.addEventListener('click', handleClickOutside);
        return () => {
          document.removeEventListener('click', handleClickOutside);
        }
    }, [dropdownRef, showDropdown]);


    const handleDropdown = (option) => {
        handleContract(option.type, option.row)
        setShowDropdown(false);
    }

    const handleButtonClick = (event, row) => {
        // Get the position of the clicked button
        const rect = event.target.getBoundingClientRect();
        const buttonX = rect.left;
        const buttonY = rect.bottom;

        // Get the position of the parent element by id
        const parentElement = document.getElementById('contactListContainer');
        const parentRect = parentElement.getBoundingClientRect();
        const parentX = parentRect.left;
        const parentY = parentRect.top;

        // Calculate the position of the button relative to the parent element
        const relativeX = buttonX - parentX;
        const relativeY = buttonY - parentY;

        let options = [
            {type: 'single', row},
        ]

        if (tokenData.parsedToken.type === 'admin') {
            options.push(
                {type: 'remove', row},
                {type: row.valid ? 'setInvalid' : 'setValid', row}
            )
        }

        setDropdownOptions(options)
        setDropdownPosition({ x: relativeX, y: relativeY });
        setShowDropdown(row.id);
    }

    // This loops through rows and creates them
    const tableColumns = useMemo(() => {
        let columns = [
            {
                name: '',
                selector: row => row.valid,
                cell: row => {
                    return (
                        <button 
                            className='btn btn-secondary'
                            ref={el => buttonRef.current[row.id] = el}
                            onClick={(e) => handleButtonClick(e, row)}
                        ><i className="fa fa-ellipsis-v" style={{ pointerEvents: 'none' }}></i></button>
                    )
                }
            },
            { 
                name: t('table.contract_number'),
                selector: row => row.contract_number,
                sortable: true,
            },
            { 
                name: t('table.active_to'),
                selector: row => row.active_to,
                sortable: true,
                cell: row => isNull(row.active_to) ? t('form.note.indefinitely') : displayDate(moment.utc(row.active_to), 'date')
            },
            { 
                name: t('table.valid'),
                selector: row => row.valid,
                sortable: true,
                cell: row => !isNull(row.valid) && <div className={"contract-valid" + (row.valid ? " green" : " red") }>{t(`contracts.list.valid.${row.valid}`)}</div>
            },
        ]

        return columns
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    
    return (
        <div id="contactListContainer" className='no-cursor' style={{ position: 'relative' }}>
            <FormGroup>
                <DataTable
                    columns={tableColumns}
                    data={data}
                    customStyles={customStyles}
                    // onRowClicked={handleRowClick}
                />
            </FormGroup>
            {showDropdown && (
                <div
                    ref={dropdownRef}
                    style={{
                        position: 'absolute',
                        top: dropdownPosition.y,
                        left: dropdownPosition.x,
                        backgroundColor: '#fff',
                        zIndex: '200',
                        cursor: 'pointer',
                        boxShadow: '0 8px 16px 0 rgba(0,0,0,.2)',
                    }}
                >
                    {dropdownOptions.map(option => (
                        <div key={'dropdown-option-'+option.type} className="custom-dropdown-item" onClick={() => handleDropdown(option)}>{t(`contracts.list.${option.type}`)}</div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default ContractList