import React, { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {t} from 'i18next'
import DataTable from 'react-data-table-component'
import { FormGroup, Button } from 'reactstrap'
import moment from 'moment'

import { customStyles, displayDate } from '../../../izUtils'

const UserList = ({data, handleDelete}) => {
    const { exceptionId } = useParams()
    const Navigate = useNavigate()

    const handleRowClick = (state) => {
        Navigate('/schedule/exception/' + state.id)
    }

    const tableColumns = useMemo(() => {
        return [
            { 
                name: t('table.name'),
                selector: row => row.user.name,
                sortable: true,
                cell: row => {
                    if (exceptionId === row.id) return <div className="clickableRow" onClick={() => handleRowClick(row)}>{row.user.name}</div>
                    return row.user.name
                }
            },
            { 
                name: t('table.from'),
                selector: row => row.from,
                sortable: true,
                cell: row => {
                    if (exceptionId === row.id) return <div className="clickableRow" onClick={() => handleRowClick(row)}>{displayDate(moment.utc(row.exception_from), 'date')}</div>
                    return displayDate(moment.utc(row.exception_from), 'date')
                }
            },
            { 
                name: t('table.to'),
                selector: row => row.to,
                sortable: true,
                cell: row => {
                    if (exceptionId === row.id) return <div className="clickableRow" onClick={() => handleRowClick(row)}>{displayDate(moment.utc(row.exception_to), 'date')}</div>
                    return displayDate(moment.utc(row.exception_to), 'date')
                }
            },
            { 
                name: t('table.exception'),
                selector: row => row.to,
                sortable: true,
                cell: row => {
                    let title = row.exception.title;
                    if (row.exception.id === 'other') {
                        title = row.title;
                    }

                    if (exceptionId === row.id) return <div className="clickableRow" onClick={() => handleRowClick(row)}>{title}</div>
                    return title
                }
            },
            { 
                name: '',
                sortable: false,
                cell: row => {
                    return <Button color="danger" className="mt-2 me-2" onClick={() => handleDelete(row)}>{t('delete')}</Button> 
                }
            },
        ]
    }, [exceptionId]) // eslint-disable-line react-hooks/exhaustive-deps
    
    return (
        <FormGroup>
            <DataTable
                columns={tableColumns}
                data={data}
                customStyles={customStyles}
                onRowClicked={handleRowClick}
            />
        </FormGroup>
    )
}

export default UserList