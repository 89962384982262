import React, {useState, useEffect, useMemo} from 'react'
import { FormGroup, Label, Input } from 'reactstrap'

import { isNull } from '../../../izUtils'

const AutocompleteInput = ({data, onChange, showErrors}) => {
    const { type, name, display, errors, title, values, disabled, mandatory} = data

    const [value, setvalue] = useState("")
    const [activeSuggestion, setActiveSuggestion] = useState(0)
    const [showSuggestions, setShowSuggestions] = useState(false)

    useEffect(() => {
        if (isNull(data.value) || data.value ===  '') setvalue('')
    
    }, [data.value])
    
    useEffect(() => {
        setActiveSuggestion(0)

    }, [JSON.stringify(data.values)]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (e) => {
        setvalue(e.target.value)
        onChange(e.target.value, name, false)
        if (e.target.value.length !== 0) setShowSuggestions(true)
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            if (type === 'contract_search') {
                setvalue(values[activeSuggestion].contract_number);
            } else {
                setvalue(values[activeSuggestion].name);
            }
            onChange(values[activeSuggestion].id, name, true);
            setActiveSuggestion(0);
            setShowSuggestions(false);
        } else if (e.keyCode === 38) {
            if (activeSuggestion === 0) return;
            setActiveSuggestion(prev => prev - 1);
        } else if (e.keyCode === 40) {
            if (activeSuggestion + 1 === values.length) return;
            setActiveSuggestion(prev => prev + 1);
        }
    };

    const handleSelect = (value) => {
        if (type === 'contract_search') {
            setvalue(value.contract_number);
        } else {
            setvalue(value.name);
        }
        onChange(value.id, name, true);
        setActiveSuggestion(0);
        setShowSuggestions(false);
    }

    const options = useMemo(() => {
        if (type === 'contract_search') {
            return values.map((value, index) => (
                <li key={index} value={value.id} className={ activeSuggestion === index ? 'active' : '' } onClick={() => handleSelect(value)}>{value.contract_number}</li>
            ))
        } else {
            return values.map((value, index) => (
                <li key={index} value={value.id} className={ activeSuggestion === index ? 'active' : '' } onClick={() => handleSelect(value)}>{value.name}, {value.location}</li>
            ))
        }

    }, [values, activeSuggestion, type]) // eslint-disable-line react-hooks/exhaustive-deps

    if (display) {
        return (
            <div style={{ position: 'relative' }}>
                <FormGroup>
                    <Label>{title + (mandatory ? " *" : "")}</Label>
                    <Input 
                        className={"form-control digits"}
                        type="text"
                        autoComplete="off"
                        name={name}
                        value={value}
                        disabled={disabled}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                    />
                    {!isNull(showErrors) && showErrors && !isNull(errors) && errors.length !== 0 && <div style={{ color: "red", marginBottom: "10px" }}>{errors[0]}</div>}
                </FormGroup>
                {(showSuggestions && options.length !== 0) &&
                    <ul className='autocomplete-suggestion-container'>
                        {options}
                    </ul>
                }
            </div>

        )
    } else {
        return null
    }
}

export default AutocompleteInput