import React, { useEffect, useState } from 'react'
import { Input } from 'reactstrap'
import { isNull } from '../../izUtils'

const PaginationTop = ({filters, getData}) => {
    const [inputs, setInputs] = useState(null)

    useEffect(() => {
        setInputs(filters)
    
    }, [filters])

    const textChange = (value, name) => {
        let clonedInputs = {...inputs}
        clonedInputs[name].value = value;
        if (!isNull(clonedInputs['pagination_page'])) clonedInputs['pagination_page'].value = 1;
        getData(clonedInputs)
    }

    if (!isNull(inputs)) {
        return (
            <div className='d-flex align-items-center mb-3'>
                <div className='d-flex align-items-center'>
                    <div className='me-2'>{inputs['pagination_show_per_page'].title}</div>
                    <Input 
                        className='pagination-select'
                        type="select"
                        name={inputs['pagination_show_per_page'].name}
                        value={inputs['pagination_show_per_page'].value}
                        disabled={inputs['pagination_show_per_page'].disabled}
                        onChange={(e) => textChange(e.target.value, 'pagination_show_per_page')}
                    >
                        {inputs['pagination_show_per_page'].values.map((option, index) => (
                            <option key={index} value={option.id}>{option.title}</option>
                        ))}
                    </Input>
                </div>
            </div>
        )
    }
}

export default PaginationTop