import React, {useState, useEffect} from 'react'
import {t} from 'i18next'
import { FormGroup, Row, Col, Button } from 'reactstrap'
import Swal from 'sweetalert2'

import { isNull } from '../../../izUtils'
import DateInput from '../DateInput'
import TextInput from '../TextInput'
import NumberInput from '../NumberInput'

const SSInspectionInput = ({data, onChange, onBlur, showErrors}) => {
    const { name, display, errors, title, disabled, mandatory} = data;
    const [values, setvalues] = useState([])
    
    useEffect(() => {
        if (isNull(data.value) || data.value === '' || data.value.length === 0) {
            setvalues([])
        } else {
            setvalues(data.value)
        }

    }, [JSON.stringify(data.value)]) // eslint-disable-line react-hooks/exhaustive-deps
    
    const handleChange = (value, type, index) => {
        let clonedValue = [...values]
        clonedValue[index][type] = value
        setvalues(clonedValue)
    };

    const dateHandler = (value, type, index) => {
        let clonedValue = [...values]
        clonedValue[index][type] = value
        setvalues(clonedValue)
        onBlur(clonedValue, name)
    }

    const handleBlur = (value, type, index) => {
        let clonedValue = [...values]
        clonedValue[index][type] = value
        setvalues(clonedValue)

        if (!isNull(onBlur)) onBlur(values, name)
    };
    
    const addNew = () => {
        let clonedValue = [...values]
        clonedValue.push({ 
            operation_certification_price: null,
            certificate_number: null,
            inspector: null,
            inspection_year: null,
            inspection_date: null,
            inspection_valid_to: null,
         })
        setvalues(clonedValue)
    }
    
    const remove = (index) => {
        Swal.fire({
            text: t('facilities.linkedSystems.security_system_inspections.delete'),
            icon: 'question',
            confirmButtonColor: 'var(--theme-default)',
            confirmButtonText: t('ok'),
            showCancelButton: true,
            cancelButtonText: t('cancel'),
        }).then(val => {
            if (val.isConfirmed) {
                let clonedValue = [...values]
                let filteredValue = clonedValue.filter((item, i) => index !== i)
                setvalues(filteredValue)
                onBlur(filteredValue, name)
            }
        })
    }
    
    if (display) {
        const fields = [
            {
                name: 'operation_certification_price',
                type: 'number',
                title: t(`form.label.${name}.operation_certification_price`),
                display: true
            },
            {
                name: 'certificate_number',
                type: 'text',
                title: t(`form.label.${name}.certificate_number`),
                display: true
            },
            {
                name: 'inspector',
                type: 'text',
                title: t(`form.label.${name}.inspector`),
                display: true
            },
            {
                name: 'inspection_year',
                type: 'integer',
                title: t(`form.label.${name}.inspection_year`),
                display: true
            },
            {
                name: 'inspection_date',
                type: 'date',
                title: t(`form.label.${name}.inspection_date`),
                display: true
            },
            {
                name: 'inspection_valid_to',
                type: 'date',
                title: t(`form.label.${name}.inspection_valid_to`),
                display: true
            },
        ]

        return (
            <FormGroup style={{ position: 'relative' }} className="mt-5">
                {(!disabled || (disabled && values.length !== 0)) && <h5>{title + (mandatory ? " *" : "")}</h5>}
                {values.map((item, index) => (
                    <Row key={"ss-inspection-"+index} className='mb-2'>
                        {fields.map(field => {
                            if (field.type === 'date') {
                                return (
                                    <DateInput 
                                        key={'input-'+field.name}
                                        data={ {...field, value: isNull(item[field.name]) ? '' : item[field.name]} }
                                        onChange={(value, name) => dateHandler(value, name, index)} 
                                    />
                                )
                            } else if (field.type === 'text') {
                                return (
                                    <TextInput 
                                        key={'input-'+field.name}
                                        data={ {...field, value: isNull(item[field.name]) ? '' : item[field.name]} }
                                        onChange={(value, name) => handleChange(value, name, index)}
                                        onBlur={(value, name) => handleBlur(value, name, index)}
                                    />
                                    )
                                } else {
                                return (
                                    <NumberInput 
                                        key={'input-'+field.name}
                                        data={ {...field, value: isNull(item[field.name]) ? '' : item[field.name]} }
                                        onChange={(value, name) => handleChange(value, name, index)}
                                        onBlur={(value, name) => handleBlur(value, name, index)}
                                    />
                                )
                            }
                        })}
                        <Col sm="1" className='d-flex align-items-end mb-3'>
                            {!disabled &&
                                <span onClick={() => remove(index)}>
                                    <i className="icon-trash bigger-icon"></i>
                                </span>
                            }
                        </Col>
                        {index + 1 !== values.length && <hr/>}
                    </Row>
                ))}
                {!isNull(showErrors) && showErrors && !isNull(errors) && errors.length !== 0 && 
                    errors.map((error, index) => (
                        <div key={'material-error-'+index} style={{ color: "red", marginBottom: "10px" }}>{error}</div>
                    ))
                }
                {!disabled &&
                    <div className='text-start mt-3'>
                        <Button color="primary" onClick={addNew}>{t('form.security_system_inspections.addNew')}</Button> 
                    </div>
                }
            </FormGroup>
        );
    } else {
        return null;
    }
}

export default SSInspectionInput