import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Row, Col } from 'reactstrap'
import Breadcrumb from '../../layout/breadcrumb/Breadcrumb'
import Kanban from '../../components/kanban/Kanban'

const Desktop = () => {
    const {t} = useTranslation();

    return (
        <Fragment>
            <Breadcrumb parent="" title={t('breadcrumb.kanban')} />
            <Container fluid={true} className="jkanban-container">
                <Row>
                    <Col xs="12">
                        <Kanban />
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default Desktop