import React, {Fragment, useState, useRef} from 'react'
import {t} from 'i18next'
import { FormGroup, Button, Modal } from 'reactstrap'

import { isNull } from '../../../izUtils'
import AddClient from '../../users/modals/AddClient';

const ClientInput = ({data, onBlur, showErrors}) => {
    const { name, display, errors, title, disabled, mandatory} = data;
    const modalType = useRef(null)

    const [modalAddClient, setModalAddClient] = useState(false);
    const modaltoggle = () => setModalAddClient(val => !val)

    const addNew = () => {
        modalType.current = 'add'
        setModalAddClient(true)
    }

    const handleClient = (item, type) => {
        if (type === 'edit') {
            modalType.current = item.client.id
            setModalAddClient(true)
        } else {
            let clonedDataValue = [...data.value]
            let filteredDataValue = clonedDataValue.filter(el => el.client !== item.client.id)
            onBlur(filteredDataValue, name)
        }
    }

    if (display) {
        return (
            <Fragment>
                <FormGroup style={{ position: 'relative' }}>
                    {(!disabled || (disabled && data.values.length !== 0)) && <h5>{title + (mandatory ? " *" : "")}</h5>}
                    {data.values.map((item, index) => (
                        <div key={"client-"+item.client.id} className='d-flex justify-content-between align-items-center p-1 mt-2'>
                            <div>{item.client.title}</div>
                            {!disabled &&
                                <div>
                                    <span onClick={() => handleClient(item, 'edit')}><i className="icon-pencil bigger-icon me-2" style={{ color: 'var(--theme-default)' }}></i></span>
                                    <span onClick={() => handleClient(item, 'remove')}><i className="icon-trash bigger-icon" style={{ color: 'red' }}></i></span>
                                </div>
                            }
                        </div>
                    ))}
                    {!isNull(showErrors) && showErrors && !isNull(errors) && errors.length !== 0 && 
                        errors.map((error, index) => (
                            <div key={'material-error-'+index} style={{ color: "red", marginBottom: "10px" }}>{error}</div>
                        ))
                    }
                    {!disabled &&
                        <div className='text-start mt-3'>
                            <Button color="primary" onClick={addNew}>{t('form.client.addNew')}</Button> 
                        </div>
                    }
                </FormGroup>

                {/* ADD NEW CLIENT */}
                <Modal isOpen={modalAddClient} toggle={modaltoggle} centered size="xl">
                    <AddClient
                        clientdata = {data}
                        modalType={modalType.current}
                        modaltoggle={modaltoggle}
                        setClient={modalData => {
                            onBlur(modalData, name)
                            modaltoggle()
                        }}
                    />
                </Modal>
            </Fragment>
        )
    } else {
        return null;
    }
}

export default ClientInput