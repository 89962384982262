import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {t} from 'i18next'
import { Card, CardBody } from 'reactstrap'
import { useRecoilValue, useRecoilState } from 'recoil'
import axios from 'axios'
import Swal from 'sweetalert2'

import env from '../../env/src_config'
import { headersState, refreshException } from '../../recoil/recoil';
import Spinner from '../spinner/Spinner'
import PaginationTop from '../Pagination/PaginationTop'
import PaginationBottom from '../Pagination/PaginationBottom'
import { axiosError, errorStatus } from '../../helpers/response'
import { isNull } from '../../izUtils'
import { transformer } from '../../helpers/fields'
import FutureExceptionList from './lists/FutureExceptionList'

const FutureException = () => {
    const { technicianId } = useParams();
    const navigate = useNavigate();

    const headers = useRecoilValue(headersState);
    const [refreshEx, setRefreshEx] = useRecoilState(refreshException);
    const [exceptions, setExpections] = useState(null)
    const [filters, setFilters] = useState(null)

    useEffect(() => {
        getExceptions({});
    
    }, [technicianId]) // eslint-disable-line react-hooks/exhaustive-deps

    // To refresh list after exception is added
    useEffect(() => {
        if (refreshEx) {
            getExceptions({});
            setRefreshEx(false)
        }

    }, [refreshEx]) // eslint-disable-line react-hooks/exhaustive-deps

    const getExceptions = (passedFilters) => {
        const keys = Object.keys(passedFilters);
        let payload = { filters: {} };
        for (let i = 0; i < keys.length; i++) {
            if (!isNull(passedFilters[keys[i]].value) && passedFilters[keys[i]].value.length !== 0) {
                payload.filters[keys[i]] = passedFilters[keys[i]].value;
            } else {
                payload.filters[keys[i]] = "";
            }
        }

        axios.post(env.api + '/api/user/exception/list' + (!isNull(technicianId) ? '/'+technicianId : ''), payload, {headers}).then(response => {
            if (!isNull(response.data.data)) {
                setExpections(response.data.data);
                if (!isNull(response.data.filters)) setFilters(transformer(response.data.filters));
            }
        }).catch(error => {
            axiosError(error, navigate);
        });
    }

    const handleDelete = (row) => {
        axios.delete(env.api + '/api/user/exception/' + row.id, {headers}).then(response => {
            if (response.data.state === 'success') {
                getExceptions({});
                Swal.fire({
                    text: response.data.message,
                    icon: 'success',
                    confirmButtonColor: 'var(--theme-default)',
                    confirmButtonText: t('ok'),
                })
            } else {
                errorStatus(response.data, t);
            }
        }).catch(error => {
            axiosError(error, navigate);
        });
    }

    if (isNull(exceptions)) {
        return <Spinner />
    } else {
        return (
            <Card className="ribbon-wrapper">
                <CardBody>
                    <div className="ribbon ribbon-clip ribbon-primary">{t('schedule.futureException.title')}</div>
                    <div>
                        <PaginationTop filters={filters} getData={clonedFilters => getExceptions(clonedFilters, false)} />
                        <FutureExceptionList data={exceptions} handleDelete={handleDelete}  />
                        <PaginationBottom filters={filters} getData={clonedFilters => getExceptions(clonedFilters, false)} />
                    </div>
                </CardBody>
            </Card>
        )
    }
}

export default FutureException