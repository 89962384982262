import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { Container, Row, Col, Card, CardBody, Form, Button } from 'reactstrap'
import axios from 'axios';
import Swal from 'sweetalert2';

import env from '../../env/src_config';
import Breadcrumbs from '../../layout/breadcrumb/Breadcrumb'
import { isNull } from '../../izUtils';
import { transformer, linker } from '../../helpers/fields'
import { headersState } from '../../recoil/recoil';
import { errorStatus, axiosError } from '../../helpers/response';
import Spinner from '../../components/spinner/Spinner';

const CreateTaskReported = () => {
    const {t} = useTranslation();
    const Navigate = useNavigate();

    const headers = useRecoilValue(headersState);
    const [inputs, setInputs] = useState(null);
    const [showErrors, setShowErrors] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);

    useEffect(() => {
        updateData(false, null);

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const updateData = (save, passedInputs) => {
        if (save) setDisableBtn(true);
        let payload = { save };

        if (!isNull(passedInputs)) {
            const keys = Object.keys(passedInputs);
            payload.data = {};
            for (let i = 0; i < keys.length; i++) {
                if (!isNull(passedInputs[keys[i]].value) && passedInputs[keys[i]].value.length !== 0) {
                    payload.data[keys[i]] = passedInputs[keys[i]].value;
                } else {
                    payload.data[keys[i]] = "";
                }
            }
        }

        axios.post(env.api + '/api/task/create/reported', payload, {headers}).then(async response => {
            if (save) {
                if (!isNull(response.data.state) && response.data.state === 'success') {
                    Swal.fire({
                        title: t('saved'),
                        text: response.data.message,
                        icon: 'success',
                        confirmButtonColor: 'var(--theme-default)',
                        confirmButtonText: t('ok'),
                    }).then(() => {
                        Navigate('/tasks')
                    })
                } else {
                    errorStatus(response.data, t);
                    setShowErrors(true);
                }
                setDisableBtn(false);
            }

            if (!isNull(response.data.data)) {
                let transformedResponse = transformer(response.data.data)
                setInputs(transformedResponse);
            }
        }).catch(error => {
            axiosError(error, Navigate);
        });
    }

    const textChange = (value, name) => {
        let clonedInputs = {...inputs}
        clonedInputs[name].value = value;
        updateData(false, clonedInputs);
    }

    // Create fields
    const mapLinker = (field) => {
        const inputlinkerFields = {
            field,
            inputs,
            showErrors,
            textHandler: (value, id) => textChange(value, id),
            selectHandler: (value, id) => textChange(value, id),
        }

        return linker(inputlinkerFields);
    }

    if (isNull(inputs)) {
        return <Spinner />
    } else {
        return (
            <Fragment>
                <Breadcrumbs parent={t('breadcrumb.kanban')} title={t(`status.reported`)} />
                <Container fluid={true}>
                    <Row>
                        <Col sm="12"> 
                            <Card className="ribbon-wrapper">
                                <CardBody>
                                    <div className="ribbon ribbon-clip ribbon-primary">{t('task.create.title')}</div>
                                    <Form className="theme-form">
                                        <Row>
                                            <Col lg="6"> 
                                                { ['submitted_by', 'client', 'facility', 'security_system_report', 'client_contact_name_report', 'client_contact_phone_report', 'error_description'].map(field => mapLinker(field)) }
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <div className='d-flex flex-wrap justify-content-between mb-4'>
                        <div>
                            <Button color="danger" className="mt-2 me-2" onClick={() => Navigate('/')}>{t('back')}</Button> 
                        </div>
                        <div>
                            <Button color="primary" className="mt-2 me-2" disabled={disableBtn} onClick={() => updateData(true, inputs)}>{t('saveAndClose')}</Button>
                        </div>
                    </div>
                </Container>
            </Fragment>
        )
    }
}

export default CreateTaskReported