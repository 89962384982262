import React, { Fragment, useState, useEffect } from 'react'
import {t} from 'i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Container, Row, Col, Card, CardBody, Button, Collapse, CardFooter } from 'reactstrap'
import { useRecoilState } from 'recoil'
import axios from 'axios'
import { Accordion } from 'react-bootstrap';

import env from '../../env/src_config'
import { headersState } from '../../recoil/recoil';
import Breadcrumbs from '../../layout/breadcrumb/Breadcrumb';
import Spinner from '../../components/spinner/Spinner'
import SecuritySystemList from '../../components/securitySystems/lists/SecuritySystemList'
import PaginationTop from '../../components/Pagination/PaginationTop'
import PaginationBottom from '../../components/Pagination/PaginationBottom'
import { axiosError } from '../../helpers/response'
import { isNull } from '../../izUtils'
import { transformer } from '../../helpers/fields'
import AddUpdateSecuritySystem from '../../components/securitySystems/AddUpdateSecuritySystem'
import Manufacturers from '../../components/securitySystems/manufacturers/Manufacturers'
import Filter from '../../components/filters/Filter'

const SecuritySystems = () => {
    const Navigate = useNavigate();
    const [headers] = useRecoilState(headersState);
    const { securitySystemId } = useParams();

    const [isOpen, setIsOpen] = useState(null);
    const [system, setSystems] = useState(null)
    const [filters, setFilters] = useState(null)

    const lsFilterName = 'AKODA.securitySystemFilter';

    useEffect(() => {
        getSecuritySystems({});

        return () => {
            localStorage.removeItem(lsFilterName)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getSecuritySystems = (passedFilters) => {
        const keys = Object.keys(passedFilters);
        let payload = { filters: {} };
        for (let i = 0; i < keys.length; i++) {
            if (!isNull(passedFilters[keys[i]].value) && passedFilters[keys[i]].value.length !== 0) {
                payload.filters[keys[i]] = passedFilters[keys[i]].value;
            } else {
                payload.filters[keys[i]] = "";
            }
        }
        
        axios.post(env.api + '/api/security_system/list', payload, {headers}).then(response => {
            if (!isNull(response.data.data)) {
                setSystems(response.data.data);
                if (!isNull(response.data.filters)) {
                    setFilters(transformer(response.data.filters));
                    localStorage.setItem(lsFilterName, JSON.stringify(transformer(response.data.filters)))
                }
            }
        }).catch(error => {
            axiosError(error, Navigate);
        });
    }

    const clearData = () => {
        localStorage.removeItem(lsFilterName);
        getSecuritySystems({});
    }

    if (isNull(system)) {
        return <Spinner />
    } else {
        const filterFields = ['title']
        return (
            <Fragment>
                <Breadcrumbs parent="" title={t('breadcrumb.securitySystems')} />
                <Container fluid={true}>
                    <Row>
                        <Col md="6"> 
                            <Card className="ribbon-wrapper">
                                <CardBody>
                                    <div className="ribbon ribbon-clip ribbon-primary">{t('securitySystems.list.title')}</div>
                                    <div>
                                        <Accordion className='mb-3'>
                                            <div className="default-according style-1" id="systemfilter">
                                                <Button color="secondary" className="acc-btn d-flex justify-content-between align-items-center" data-toggle="collapse" onClick={() => setIsOpen(!isOpen)}>
                                                    {t('filters')}
                                                    <div className={'filter-icon' + (isOpen ? ' rotate-icon' : '')}>
                                                        <i className="fa fa-chevron-down"></i>
                                                    </div>
                                                </Button>
                                                <Collapse isOpen={isOpen}>
                                                    <div className='p-3'>
                                                        <Filter filterFields={filterFields} filters={filters} getData={(clonedFilters) => getSecuritySystems(clonedFilters)} lsFilterName={lsFilterName} clearData={clearData} />
                                                    </div>
                                                </Collapse>
                                            </div>
                                        </Accordion>
                                        <PaginationTop filters={filters} getData={clonedFilters => getSecuritySystems(clonedFilters)} />
                                        <SecuritySystemList data={system} />
                                        <PaginationBottom filters={filters} getData={clonedFilters => getSecuritySystems(clonedFilters)} />
                                    </div>
                                </CardBody>
                                <CardFooter>
                                    <Button color="primary" className="mt-2 me-2" onClick={() => Navigate('/security-systems/create')}>{t('securitySystems.list.addNew')}</Button>
                                </CardFooter>
                            </Card>
                        </Col>
                        {!isNull(securitySystemId) && 
                            <Col md="6">
                                <AddUpdateSecuritySystem getSecuritySystems={() => getSecuritySystems({})}/>
                            </Col>
                        }
                    </Row>
                    {(!isNull(securitySystemId) && securitySystemId !== 'create') && 
                        <Fragment>
                            <hr/>
                            <Manufacturers />
                        </Fragment>
                    }
                    <hr/>
                    <div className='d-flex flex-wrap justify-content-between mb-4'>
                        <div>
                            <Button color="danger" className="mt-2 me-2" onClick={() => Navigate('/')}>{t('back')}</Button> 
                        </div>
                    </div>
                </Container>
            </Fragment>
        )
    }
}

export default SecuritySystems