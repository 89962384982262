import React, { useMemo } from 'react'
import {t} from 'i18next'
import DataTable from 'react-data-table-component'
import { FormGroup, Row, Col, Button } from 'reactstrap'

import { customStyles, isNull } from '../../../izUtils'
import { generateQR } from '../../../helpers/generateQR'

const FacilitySecuritySystemList = ({data, headers}) => {
    // This loops through rows and creates them
    const tableColumns = useMemo(() => {
        return [
            {
                name: t('table.client'),
                selector: row => isNull(row.facility) ? '' : row.facility.client.client,
                sortable: true,
                cell: row => isNull(row.facility) ? '' : row.facility.client.client,
            },
            {
                name: t('table.facility'),
                selector: row => isNull(row.facility) ? '' : row.facility.name,
                sortable: true,
                cell: row => {
                    if (!isNull(row.facility)) {
                        return (
                            <div className='d-flex flex-wrap align-items-center'>
                                <div className='me-2 mt-1 mb-1'>{row.facility.name}</div>
                                <Button color="primary" className="mt-1 mb-1 py-1 px-2" onClick={() => generateQR(row.facility?.id, headers)}>QR</Button>
                            </div>
                        )
                    }
                }
            },
            { 
                name: t('table.billing_method'),
                selector: row => isNull(row.billing_method) ? '' : row.billing_method.title,
                sortable: true,
                cell: row => isNull(row.billing_method) ? '' : row.billing_method.title,
            },
            { 
                name: t('table.supervisors'),
                sortable: false,
                cell: row => {
                    if (!isNull(row.facility.supervisors) && row.facility.supervisors.length !== 0) {
                        return (
                            <div>
                                {row.facility.supervisors.map((supervisor, i) => (
                                    <div key={"supervisor-"+i} className={i !== 0 ? 'border-top py-1' : 'py-1'}>
                                        <div>{supervisor.name}</div>
                                        <div>{supervisor.email}</div>
                                        <div>{supervisor.phone}</div>
                                    </div>
                                ))}
                            </div>
                        )
                    } else {
                        return ""
                    }
                }
            },
            {
                name: t('table.security_systems'),
                sortable: false,
                grow: 4,
                cell: row => {
                    if (!isNull(row.security_systems) && row.security_systems.length !== 0) {
                        return (
                            <Col sm="12">
                                <Row className='d-none d-lg-flex m-0 py-1'>
                                    <Col lg="3" className="fw-bold">{t('table.security_system')}</Col>
                                    <Col lg="3" className="fw-bold">{t('table.manufacturer')}</Col>
                                    <Col lg="2" className="fw-bold">{t('table.system')}</Col>
                                    <Col lg="2" className="fw-bold">{t('table.central_station')}</Col>
                                    <Col lg="2" className="fw-bold">{t('table.component_notes')}</Col>
                                </Row>
                                <div className='contract-system-list'>
                                    {row.security_systems.map((system, i) => (
                                        (isNull(system.security_system_components) || system.security_system_components.length === 0) ?
                                            <Row key={"system-"+i} className="m-0 py-1">
                                                <Col lg="3">{!isNull(system.security_system) && system.security_system.title}</Col>
                                                <Col lg="3"></Col>
                                                <Col lg="2"></Col>
                                                <Col lg="2"></Col>
                                                <Col lg="2"></Col>
                                            </Row>
                                            :
                                            system.security_system_components.map(ssc => (
                                                <Row key={"ssc-"+ssc.id} className="m-0 py-1">
                                                    <Col lg="3"><span className="d-inline d-lg-none fw-bold">{t('table.security_system')}: </span>{!isNull(system.security_system) && system.security_system.title}</Col>
                                                    <Col lg="3"><span className="d-inline d-lg-none fw-bold">{t('table.manufacturer')}: </span>{(!isNull(ssc) && !isNull(ssc.manufacturer)) && ssc.manufacturer.title}</Col>
                                                    <Col lg="2"><span className="d-inline d-lg-none fw-bold">{t('table.system')}: </span>{(!isNull(ssc) && !isNull(ssc.system)) && ssc.system.title}</Col>
                                                    <Col lg="2"><span className="d-inline d-lg-none fw-bold">{t('table.central_station')}: </span>{(!isNull(ssc) && !isNull(ssc.central_station)) && ssc.central_station.title}</Col>
                                                    <Col lg="2"><span className="d-inline d-lg-none fw-bold">{t('table.component_notes')}: </span>{!isNull(ssc) && ssc.component_notes}</Col>
                                                </Row>
                                            ))
                                        
                                    ))}
                                </div>
                            </Col>
                        )
                    } else {
                        return ""
                    }
                }
            },
        ]
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    
    return (
        <FormGroup className='no-cursor'>
            <DataTable
                columns={tableColumns}
                data={data}
                customStyles={customStyles}
            />
        </FormGroup>
    )
}

export default FacilitySecuritySystemList