import React, { Fragment, useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next'
import { useRecoilState } from 'recoil'
import { Container, Row, Col, Form, Card, CardBody, CardFooter, Button } from 'reactstrap'
import Swal from 'sweetalert2'

import env from '../../env/src_config'
import { isNull } from '../../izUtils'
import Spinner from '../../components/spinner/Spinner'
import { transformer, linker } from '../../helpers/fields'
import { headersState } from '../../recoil/recoil'
import { axiosError, errorStatus } from '../../helpers/response'
import Breadcrumbs from '../../layout/breadcrumb/Breadcrumb'

const UserProfile = () => {
    const navigate = useNavigate();

    const [headers] = useRecoilState(headersState);
    const [inputs, setInputs] = useState(null)
    const [showErrors, setShowErrors] = useState(false)

    useEffect(() => {
        updateData(false, null);

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const updateData = (save, passedInputs) => {
        let payload = { save };
        if (!isNull(passedInputs)) {
            // Get data from inputs
            const keys = Object.keys(passedInputs);
            payload.data = {};
            for (let i = 0; i < keys.length; i++) {
                if (!isNull(passedInputs[keys[i]].value) && passedInputs[keys[i]].value.length !== 0) {
                    payload.data[keys[i]] = passedInputs[keys[i]].value;
                } else {
                    payload.data[keys[i]] = "";
                }
            }
        }

        axios.patch(env.api + '/api/user', payload, {headers}).then(response => {
            if (save) {
                if (!isNull(response.data.state) && response.data.state === 'success') {
                    Swal.fire({
                        title: t('saved'),
                        text: response.data.message,
                        icon: 'success',
                        confirmButtonColor: 'var(--theme-default)',
                        confirmButtonText: t('ok'),
                    })
                } else {
                    setShowErrors(true)
                    errorStatus(response.data, t);
                }
            }

            if (!isNull(response.data.data)) setInputs(transformer(response.data.data));
        }).catch(error => {
            axiosError(error, navigate);
        });
    }

    const textChange = (value, name) => {
        let clonedInputs = {...inputs}
        clonedInputs[name].value = value;
        updateData(false, clonedInputs);
    }

    // Create fields
    const mapLinker = (field) => {
        const inputlinkerFields = {
            field,
            inputs,
            showErrors,
            textHandler: (value, id) => textChange(value, id),
            selectHandler: (value, id) => textChange(value, id),
            booleanHandler: (value, id) => textChange(value, id),
        }

        return linker(inputlinkerFields);
    }

    return (
        <Fragment>
            <Breadcrumbs parent="" title={t('breadcrumb.userProfile')}/>
            <Container fluid={true}>
                <Row>
                    <Col lg="6">
                        <Card className="ribbon-wrapper">
                            <CardBody>
                                <div className="ribbon ribbon-clip ribbon-primary">{t('user.profile.title')}</div>
                                {isNull(inputs) ?
                                    <Spinner />
                                    :
                                    <Form className="theme-form">
                                        { ['email', 'name', 'phone', 'password', 'password_confirmation', 'signature', 'pushover_user_token', 'pushover_app_token'].map(field => mapLinker(field)) }
                                    </Form>
                                }
                            </CardBody>
                            <CardFooter>
                                <div className='text-end'>
                                    <Button color="primary" className="mt-2 me-2" onClick={() => updateData(true, inputs)}>{t('save')}</Button>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>

    )
}

export default UserProfile