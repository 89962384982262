import React, { Fragment } from 'react'
import {t} from 'i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Container, Row, Col, Button } from 'reactstrap'

import Breadcrumbs from '../../layout/breadcrumb/Breadcrumb';
import { isNull } from '../../izUtils'
import AddUpdateFacility from '../../components/facilities/AddUpdateFacility';
import LinkedSystemComponent from '../../components/facilities/linkedSystems/LinkedSystemComponent';


const Facilities = () => {
    const Navigate = useNavigate();
    const { clientId, facilityId } = useParams();

    return (
        <Fragment>
            <Breadcrumbs parent={t('breadcrumb.clients')} title={t('breadcrumb.facilities')} />
            <Container fluid={true}>
                <Row>
                    <Col md="12"> 
                        {!isNull(facilityId) && <AddUpdateFacility />}
                    </Col>
                    {facilityId !== 'create' && <LinkedSystemComponent />}
                </Row>
                <hr/>
                <div className='mb-4'>
                    <Button color="danger" className="mt-2 me-2" onClick={() => Navigate('/clients/' + clientId)}>{t('back')}</Button> 
                </div>
            </Container>
        </Fragment>
    )
}

export default Facilities