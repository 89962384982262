import React, { useState, useEffect, Fragment } from 'react'
import {t} from 'i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Row, Button, Collapse, Col, Card, CardBody, CardFooter } from 'reactstrap'
import { useRecoilState } from 'recoil'
import axios from 'axios'
import { Accordion } from 'react-bootstrap';

import env from '../../../env/src_config'
import { headersState } from '../../../recoil/recoil';
import Spinner from '../../spinner/Spinner'
import PaginationTop from '../../Pagination/PaginationTop'
import PaginationBottom from '../../Pagination/PaginationBottom'
import { axiosError } from '../../../helpers/response'
import { isNull } from '../../../izUtils'
import { transformer } from '../../../helpers/fields'
import CentralStationList from './lists/CentralStationList'
import AddUpdateCentralstation from './AddUpdateCentralstation'
import Filter from '../../filters/Filter'

const CentralStations = () => {
    const Navigate = useNavigate();
    const [headers] = useRecoilState(headersState);
    const { securitySystemId, manufacturerId, systemId, centralStationId } = useParams();

    const [isOpen, setIsOpen] = useState(null);
    const [centralStations, setCentralStations] = useState(null)
    const [filters, setFilters] = useState(null)

    const lsFilterName = 'AKODA.centralStationFilter';

    useEffect(() => {
        getCentralStations({ system: {value: systemId} });

        return () => {
            localStorage.removeItem(lsFilterName)
        }
    }, [systemId]) // eslint-disable-line react-hooks/exhaustive-deps

    const getCentralStations = (passedFilters) => {
        const keys = Object.keys(passedFilters);
        let payload = { filters: {} };
        for (let i = 0; i < keys.length; i++) {
            if (!isNull(passedFilters[keys[i]].value) && passedFilters[keys[i]].value.length !== 0) {
                payload.filters[keys[i]] = passedFilters[keys[i]].value;
            } else {
                payload.filters[keys[i]] = "";
            }
        }
        
        axios.post(env.api + '/api/security_system/manufacturer/system/central_station/list', payload, {headers}).then(response => {
            if (!isNull(response.data.data)) {
                setCentralStations(response.data.data);
                if (!isNull(response.data.filters)) {
                    setFilters(transformer(response.data.filters));
                    localStorage.setItem(lsFilterName, JSON.stringify(transformer(response.data.filters)))
                }
            }
        }).catch(error => {
            axiosError(error, Navigate);
        });
    }

    const clearData = () => {
        localStorage.removeItem(lsFilterName);
        getCentralStations({ system: {value: systemId} });
    }

    if (isNull(centralStations)) {
        return <Spinner />
    } else {
        const filterFields = [/*'system',*/ 'title']
        return (
            <Fragment>
                <Row>
                    <Col md="6">
                        <Card className="ribbon-wrapper">
                            <CardBody>
                                <div className="ribbon ribbon-clip ribbon-primary">{t('securitySystems.manufacturers.systems.centralStations.list.title')}</div>
                                <div>
                                    <Accordion className='mb-3'>
                                        <div className="default-according style-1" id="manufacturerFilter">
                                            <Button color="secondary" className="acc-btn d-flex justify-content-between align-items-center" data-toggle="collapse" onClick={() => setIsOpen(!isOpen)}>
                                                {t('filters')}
                                                <div className={'filter-icon' + (isOpen ? ' rotate-icon' : '')}>
                                                    <i className="fa fa-chevron-down"></i>
                                                </div>
                                            </Button>
                                            <Collapse isOpen={isOpen}>
                                                <div className='p-3'>
                                                    <Filter filterFields={filterFields} filters={filters} getData={(clonedFilters) => getCentralStations(clonedFilters)} lsFilterName={lsFilterName} clearData={clearData} />
                                                </div>
                                            </Collapse>
                                        </div>
                                    </Accordion>
                                    <PaginationTop filters={filters} getData={clonedFilters => getCentralStations(clonedFilters)} />
                                    <CentralStationList data={centralStations}/>
                                    <PaginationBottom filters={filters} getData={clonedFilters => getCentralStations(clonedFilters)} />
                                </div>
                            </CardBody>
                            <CardFooter>
                                <div className='text-start'>
                                    <Button color="primary" className="mt-2 me-2" onClick={() => Navigate('/security-systems/' + securitySystemId + '/manufacturers/' + manufacturerId + '/systems/' + systemId + '/central-stations/create')}>{t('securitySystems.manufacturers.systems.centralStations.list.addNew')}</Button>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                    {!isNull(centralStationId) && 
                        <Col md="6">
                            <AddUpdateCentralstation getCentralStations={ () => getCentralStations({ system: {value: systemId} }) }/>
                        </Col>
                    }
                </Row>
            </Fragment>
        )
    }
}

export default CentralStations