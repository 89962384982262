import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {t} from 'i18next'
import DataTable from 'react-data-table-component'
import { FormGroup } from 'reactstrap'
import moment from 'moment'

import { customStyles, displayDate, isNull } from '../../../izUtils'

const MailList = ({data}) => {
    const Navigate = useNavigate()

    const handleRowClick = (state) => {
        Navigate('/mail/' + state.id)
    }

    // This loops through rows and creates them
    const tableColumns = useMemo(() => {
        return [
            { 
                name: t('table.mailTo'),
                selector: row => row.to,
                sortable: true,
            },
            { 
                name: t('table.subject'),
                selector: row => row.subject,
                sortable: true,
            },
            { 
                name: t('table.inserted'),
                selector: row => !isNull(row.inserted) ? displayDate(moment.utc(row.inserted)) : '',
                sortable: true,
            },
            { 
                name: t('table.sent'),
                selector: row => !isNull(row.sent) ? displayDate(moment.utc(row.sent)) : '',
                sortable: true,
            },
            { 
                name: t('table.last_error'),
                selector: row => !isNull(row.last_error) ? row.last_error : '/',
                sortable: true,
            },
            { 
                name: t('table.last_error_description'),
                selector: row => !isNull(row.last_error_description) ? row.last_error_description : '/',
                sortable: true,
            },
            { 
                name: t('table.files'),
                cell: row => {
                    console.log(row.files)
                    if (isNull(row.files) || row.files.length === 0) {
                        return ""
                    }

                    return (
                        <div className="w-100 p-2" style={{ border: '1px solid var(--theme-secondary)', borderRadius: '8px' }} onClick={() => handleRowClick(row)}>
                            {row.files.map((file, index) => (
                                <div key={"mail-file-"+file.id+'-'+index} className={index === 0 ? '' : 'mt-2'}>{file.filename}</div>
                            ))}
                        </div>
                    )
                }
            },

        ]
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    
    return (
        <FormGroup>
            <DataTable
                columns={tableColumns}
                data={data}
                customStyles={customStyles}
                onRowClicked={handleRowClick}
            />
        </FormGroup>
    )
}

export default MailList