import axios from "axios";

import env from "../env/src_config";
import { axiosError } from "./response";
import { isNull } from "../izUtils";

// Use this function to create disabled fields
export async function getSingleItem(headers, id, endpoint, navigate) {
    const singleData = await axios.get(env.api + endpoint + id, {headers}).then(response => {
        if (!isNull(response.data) && !isNull(response.data.data)) {
            let fields = {};
            let responseData = response.data.data;

            for (let i = 0; i < responseData.length; i++) {
                responseData[i].disabled = true;

                if (responseData[i].type === "select" || responseData[i].type === "selectsearch") {
                    responseData[i].values = [responseData[i].value];
                    responseData[i].value = responseData[i].value.id;
                } else if (responseData[i].type === "multiselect" || responseData[i].type === "technicians") {
                    if (responseData[i].value === '') responseData[i].value = [];
                    responseData[i].values = responseData[i].value.map(val => val)
                    responseData[i].value = responseData[i].value.map(val => val.id)
                } else if (responseData[i].type === "files") {
                    responseData[i].values = responseData[i].value
                } else if (responseData[i].type === "client") {
                    responseData[i].values = responseData[i].value;
                }
           
                fields[responseData[i].name] = responseData[i];
            }

            return {fields, otherData: response.data};
        }
    }).catch(error => {
        axiosError(error, navigate);
        return error.response.data;
    });

    return singleData;
}