import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {t} from 'i18next'
import { useRecoilValue } from 'recoil';
import { Container, Row, Col, Card, CardBody, Button, Collapse } from 'reactstrap'
import axios from 'axios';
import { Accordion } from 'react-bootstrap';

import env from '../../env/src_config';
import Breadcrumb from '../../layout/breadcrumb/Breadcrumb'
import Calendar from '../../components/calendar/Calendar'
import { headersState, tokenState } from '../../recoil/recoil';
import { axiosError } from '../../helpers/response';
import Spinner from '../../components/spinner/Spinner';
import { isNull } from '../../izUtils';
import { transformer } from '../../helpers/fields';
import Filter from '../../components/filters/Filter';

const ScheduleUsers = () => {
    const Navigate = useNavigate()
    const {year, weekNumber} = useParams();
    
    const headers = useRecoilValue(headersState);
    const tokenData = useRecoilValue(tokenState);
    const [scheduleData, setScheduleData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [filters, setFilters] = useState(null)
    const [isFilterOpen, setIsFilterOpen] = useState(true);

    const lsFilterName = 'AKODA.scheduleFilter';
    const firstTime = useRef(true);

    useEffect(() => {
        const lsFilter = JSON.parse(localStorage.getItem(lsFilterName));
        let filters = null;
        if (tokenData.parsedToken.type === 'technician' && firstTime.current) {
            filters = { technician: { value: tokenData.parsedToken.id } };
        }

        getScheduleData(!isNull(filters) ? filters : lsFilter);
        if (firstTime.current) {firstTime.current = false;}

    }, [year, weekNumber, headers]) // eslint-disable-line react-hooks/exhaustive-deps

    const getScheduleData = (passedFilters) => {
        setLoading(true)

        let payload = { filters: {} };
        if (!isNull(passedFilters)) {
            const keys = Object.keys(passedFilters);
            for (let i = 0; i < keys.length; i++) {
                if (!isNull(passedFilters[keys[i]].value) && passedFilters[keys[i]].value.length !== 0) {
                    payload.filters[keys[i]] = passedFilters[keys[i]].value;
                } else {
                    payload.filters[keys[i]] = "";
                }
            }
        }

        let url = '/api/task/list/schedule'
        if (!isNull(year)) {
            url += '/' + year
            if (!isNull(weekNumber)) url += '/' + weekNumber
        }

        payload.filters.time_adjustment = (new Date().getTimezoneOffset() / 60)*-1

        axios.post(env.api + url, payload, {headers}).then(response => {
            setScheduleData(response.data);
            if (!isNull(response.data.filters)) {
                setFilters(transformer(response.data.filters));
                localStorage.setItem(lsFilterName, JSON.stringify(transformer(response.data.filters)))
            }
            setLoading(false)
        }).catch(error => {
            axiosError(error, Navigate);
            setLoading(false)
        });
    }

    const clearData = () => {
        localStorage.removeItem(lsFilterName);
        getScheduleData({});
    }

    const filterFields = ['technician']

    return (
        <Fragment>
            <Breadcrumb grandParent={t('breadcrumb.users')} parent={t('breadcrumb.schedule')} title={t('breadcrumb.scheduleUsers')} />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card className="ribbon-wrapper">
                            <CardBody>
                                {(tokenData.parsedToken.type === 'admin' || tokenData.parsedToken.type === 'moderator') &&
                                    <div className='d-flex flex-wrap justify-content-end mb-3' style={{ marginTop: '-25px' }}>
                                        <Button color="primary" className='mt-2 me-2' onClick={() => Navigate('/task/create')}>{t('kanban.addApproval')}</Button>
                                        <Button color="primary" className='mt-2 me-2' onClick={() => Navigate('/schedule/events/create')}>{t('schedule.events.add')}</Button>
                                        <Button color="primary" className='mt-2 me-2' onClick={() => Navigate('/schedule/exception')}>{t('schedule.exception.add')}</Button>
                                    </div>
                                }
                                <div className="ribbon ribbon-clip ribbon-primary">{t('schedule.titleUsers')}</div>
                                {/* <Button color="primary" className='mb-4 me-2' onClick={() => Navigate('/schedule')}>{t('schedule.users.calendar')}</Button> */}
                                {!isNull(filters) &&
                                    <Accordion className='mb-3'>
                                        <div className="default-according style-1" id="schedulefilter">
                                            <Button color="secondary" className="acc-btn d-flex justify-content-between align-items-center" data-toggle="collapse" onClick={() => setIsFilterOpen(!isFilterOpen)}>
                                                {t('filters')}
                                                <div className={'filter-icon' + (isFilterOpen ? ' rotate-icon' : '')}>
                                                    <i className="fa fa-chevron-down"></i>
                                                </div>
                                            </Button>
                                            <Collapse isOpen={isFilterOpen}>
                                                <div className='p-3'>
                                                    <Filter filterFields={filterFields} filters={filters} getData={(clonedFilters) => getScheduleData(clonedFilters)} lsFilterName={lsFilterName} clearData={clearData} />
                                                </div>
                                            </Collapse>
                                        </div>
                                    </Accordion>
                                }
                                {isNull(scheduleData) ? 
                                    <Spinner /> : <Calendar calendarData={scheduleData} view='agenda' loading={loading} lsFilterName={lsFilterName} />
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default ScheduleUsers