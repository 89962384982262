import React, { useState, useEffect, Fragment } from 'react'
import {t} from 'i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Row, Button, Collapse, Col, Card, CardBody, CardFooter } from 'reactstrap'
import { useRecoilState } from 'recoil'
import axios from 'axios'
import { Accordion } from 'react-bootstrap';

import env from '../../../env/src_config'
import { headersState } from '../../../recoil/recoil';
import Spinner from '../../spinner/Spinner'
import PaginationTop from '../../Pagination/PaginationTop'
import PaginationBottom from '../../Pagination/PaginationBottom'
import { axiosError } from '../../../helpers/response'
import { isNull } from '../../../izUtils'
import { transformer } from '../../../helpers/fields'
import SystemList from './lists/SystemList'
import AddUpdateSystem from './AddUpdateSystem'
import CentralStations from '../centralStations/CentralStations'
import Filter from '../../filters/Filter'

const Systems = () => {
    const Navigate = useNavigate();
    const [headers] = useRecoilState(headersState);
    const { securitySystemId, manufacturerId, systemId } = useParams();

    const [isOpen, setIsOpen] = useState(null);
    const [systems, setSystems] = useState(null)
    const [filters, setFilters] = useState(null)

    const lsFilterName = 'AKODA.systemFilter';

    useEffect(() => {
        getSystems({ manufacturer: {value: manufacturerId} });

        return () => {
            localStorage.removeItem(lsFilterName)
        }
    }, [manufacturerId]) // eslint-disable-line react-hooks/exhaustive-deps

    const getSystems = (passedFilters) => {
        const keys = Object.keys(passedFilters);
        let payload = { filters: {} };
        for (let i = 0; i < keys.length; i++) {
            if (!isNull(passedFilters[keys[i]].value) && passedFilters[keys[i]].value.length !== 0) {
                payload.filters[keys[i]] = passedFilters[keys[i]].value;
            } else {
                payload.filters[keys[i]] = "";
            }
        }
        
        axios.post(env.api + '/api/security_system/manufacturer/system/list', payload, {headers}).then(response => {
            if (!isNull(response.data.data)) {
                setSystems(response.data.data);
                if (!isNull(response.data.filters)) {
                    setFilters(transformer(response.data.filters));
                    localStorage.setItem(lsFilterName, JSON.stringify(transformer(response.data.filters)))
                }
            }
        }).catch(error => {
            axiosError(error, Navigate);
        });
    }

    const clearData = () => {
        localStorage.removeItem(lsFilterName);
        getSystems({ manufacturer: {value: manufacturerId} });
    }

    if (isNull(systems)) {
        return <Spinner />
    } else {
        const filterFields = [/*'manufacturer',*/ 'title']
        return (
            <Fragment>
                <Row>
                    <Col md="6">
                        <Card className="ribbon-wrapper">
                            <CardBody>
                                <div className="ribbon ribbon-clip ribbon-primary">{t('securitySystems.manufacturers.systems.list.title')}</div>
                                <div>
                                    <Accordion className='mb-3'>
                                        <div className="default-according style-1" id="manufacturerFilter">
                                            <Button color="secondary" className="acc-btn d-flex justify-content-between align-items-center" data-toggle="collapse" onClick={() => setIsOpen(!isOpen)}>
                                                {t('filters')}
                                                <div className={'filter-icon' + (isOpen ? ' rotate-icon' : '')}>
                                                    <i className="fa fa-chevron-down"></i>
                                                </div>
                                            </Button>
                                            <Collapse isOpen={isOpen}>
                                                <div className='p-3'>
                                                    <Filter filterFields={filterFields} filters={filters} getData={(clonedFilters) => getSystems(clonedFilters)} lsFilterName={lsFilterName} clearData={clearData} />
                                                </div>
                                            </Collapse>
                                        </div>
                                    </Accordion>
                                    <PaginationTop filters={filters} getData={clonedFilters => getSystems(clonedFilters)} />
                                    <SystemList data={systems}/>
                                    <PaginationBottom filters={filters} getData={clonedFilters => getSystems(clonedFilters)} />
                                </div>
                            </CardBody>
                            <CardFooter>
                                <div className='text-start'>
                                    <Button color="primary" className="mt-2 me-2" onClick={() => Navigate('/security-systems/' + securitySystemId + '/manufacturers/' + manufacturerId + '/systems/create')}>{t('securitySystems.manufacturers.systems.list.addNew')}</Button>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                    {!isNull(systemId) && 
                        <Col md="6">
                            <AddUpdateSystem getSystems={ () => getSystems({ manufacturer: {value: manufacturerId} }) }/>
                        </Col>
                    }
                </Row>
                {(!isNull(systemId) && systemId !== 'create') &&
                    <Fragment>
                        <hr/>
                        <CentralStations />
                    </Fragment>
                }
            </Fragment>
        )
    }
}

export default Systems