import React, { Fragment, useEffect, useState } from 'react'
import {t} from 'i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil'
import axios from 'axios';
import { Container, Card, CardBody, Button, Row, Col } from 'reactstrap'

import env from '../../env/src_config';
import { headersState } from '../../recoil/recoil';
import { isNull } from '../../izUtils'
import Spinner from '../../components/spinner/Spinner'
import Breadcrumbs from '../../layout/breadcrumb/Breadcrumb'
import { transformer } from '../../helpers/fields';
import { axiosError } from '../../helpers/response';
import PaginationBottom from '../../components/Pagination/PaginationBottom';
import PaginationTop from '../../components/Pagination/PaginationTop';
import MailList from '../../components/mail/list/MailList';

const Mail = () => {
    const Navigate = useNavigate();
    const headers = useRecoilValue(headersState);

    const [mail, setMail] = useState(null)
    const [filters, setFilters] = useState(null)

    const lsFilterName = 'AKODA.mailFilter';

    useEffect(() => {
        getMail({});

        return () => {
            localStorage.removeItem(lsFilterName)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getMail = (passedFilters) => {
        const keys = Object.keys(passedFilters);
        let payload = { filters: {} };
        for (let i = 0; i < keys.length; i++) {
            if (!isNull(passedFilters[keys[i]].value) && passedFilters[keys[i]].value.length !== 0) {
                payload.filters[keys[i]] = passedFilters[keys[i]].value;
            } else {
                payload.filters[keys[i]] = "";
            }
        }

        axios.post(env.api + '/api/mail/list', payload, {headers}).then(response => {
            if (!isNull(response.data.data)) {
                setMail(response.data.data);
                if (!isNull(response.data.filters)) {
                    setFilters(transformer(response.data.filters));
                    localStorage.setItem(lsFilterName, JSON.stringify(transformer(response.data.filters)))
                }
            }
        }).catch(error => {
            axiosError(error, Navigate);
        });
    }

    if (isNull(mail)) {
        return <Spinner />
    } else {
        return (
            <Fragment>
                <Breadcrumbs parent="" title={t('breadcrumb.mailList')} />
                <Container fluid={true}>
                    <Row>
                        <Col sm="12"> 
                            <Card className="ribbon-wrapper">
                                <CardBody>
                                    <div className="ribbon ribbon-clip ribbon-primary">{t('mail.list.title')}</div>
                                    <div>
                                        <PaginationTop filters={filters} getData={clonedFilters => getMail(clonedFilters)} />
                                        <MailList data={mail} />
                                        <PaginationBottom filters={filters} getData={clonedFilters => getMail(clonedFilters)} />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <hr/>
                    <div className='d-flex flex-wrap justify-content-between mb-4'>
                        <div>
                            <Button color="danger" className="mt-2 me-2" onClick={() => Navigate('/')}>{t('back')}</Button> 
                        </div>
                    </div>
                </Container>
            </Fragment>
        )
    }
}

export default Mail