import React, { Fragment } from 'react'
import {t} from 'i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Container, Nav, NavItem, NavLink, Card, CardBody, Button, TabContent, TabPane } from 'reactstrap'
import { useRecoilValue } from 'recoil'

import { tokenState } from '../../recoil/recoil'
import Breadcrumbs from '../../layout/breadcrumb/Breadcrumb';
import UsersComponent from '../../components/users/UsersComponent'

const Users = () => {
    const Navigate = useNavigate();
    const { confirmed } = useParams();

    const tokenData = useRecoilValue(tokenState);

    return (
        <Fragment>
            <Breadcrumbs parent="" title={t('breadcrumb.users')}/>
            <Container fluid={true}>
                {tokenData.parsedToken.type === 'admin' &&
                    <Card>
                        <CardBody className='p-3'>
                            <Nav className="nav-primary nav-pills">
                                <NavItem>
                                    <NavLink className={confirmed === 'true' ? 'active' : ''} onClick={() => Navigate('/users/true')}><i className="fa fa-users"></i>{t('users.list.title')}</NavLink>
                                </NavItem>
                                <NavItem >
                                    <NavLink className={confirmed !== 'true' ? 'active' : ''} onClick={() => Navigate('/users/false')}><i className="fa fa-edit"></i>{t('users.listNotConfirmed.title')}</NavLink>
                                </NavItem>
                            </Nav>
                        </CardBody>
                    </Card>
                }
                <TabContent activeTab={confirmed}>
                    <TabPane className="fade show" tabId={"true"}>
                        {confirmed === 'true' && <UsersComponent usersConfirmed={true} />}
                    </TabPane>
                    {tokenData.parsedToken.type === 'admin' ?
                        <TabPane tabId={"false"}>
                            {confirmed !== 'true' && <UsersComponent usersConfirmed={false} />}
                        </TabPane>
                        : null
                    }
                </TabContent>
                <hr/>
                <div className='d-flex flex-wrap justify-content-between mb-4'>
                    <div>
                        <Button color="danger" className="mt-2 me-2" onClick={() => Navigate('/')}>{t('back')}</Button> 
                    </div>
                </div>
            </Container>
        </Fragment>
    )
}

export default Users