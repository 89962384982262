import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import { useRecoilState } from 'recoil';
import axios from 'axios'

import env from '../env/src_config'
import { isNull } from '../izUtils'
import { axiosError } from '../helpers/response'
import { headersState } from '../recoil/recoil'

const Verify = () => {
    const {token} = useParams()
    const { t } = useTranslation()
    const [headers] = useRecoilState(headersState);

    const [verify, setVerify] = useState('')

    useEffect(() => {
        axios.post(env.api + '/api/email/verify/' + token, {}, {headers}).then(response => {
            if (!isNull(response.data.message)) {
                setVerify(response.data.message)
            } else if (!isNull(response.data.error)) {
                setVerify(response.data.error)
            }
        }).catch(error => axiosError(error));
    }, [headers, t, token])

    return (
        <div className='container'>
            <div className='mt-2'>
                {verify}
            </div>
        </div>
    )
}

export default Verify