import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil';
import {t} from 'i18next'
import axios from 'axios';
import { Card, CardBody } from 'reactstrap';

import env from '../../../env/src_config';
import { axiosError } from '../../../helpers/response';
import { headersState } from '../../../recoil/recoil';
import AutocompleteInput from '../../form/custom/AutocompleteInput'
import { isNull } from '../../../izUtils';
import { transformer } from '../../../helpers/fields';
import Spinner from '../../spinner/Spinner';
import SelectSearch from '../../form/custom/SelectSearch';

const ContractSearch = () => {
    const navigate = useNavigate();
    const { clientId, facilityId, linkedSystemId } = useParams();
    const headers = useRecoilValue(headersState);

    const [inputs, setInputs] = useState(null)

    useEffect(() => {
        getContractsSearch({});

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getContractsSearch = (passedData) => {
        const keys = Object.keys(passedData);
        let payload = { data: {} };
        for (let i = 0; i < keys.length; i++) {
            if (!isNull(passedData[keys[i]].value) && passedData[keys[i]].value.length !== 0) {
                payload.data[keys[i]] = passedData[keys[i]].value;
            } else {
                payload.data[keys[i]] = "";
            }
        }

        axios.post(env.api + '/api/contract/search', payload, {headers}).then(response => {
            if (!isNull(response.data.data)) setInputs(transformer(response.data.data));
        }).catch(error => {
            axiosError(error, navigate);
        });
    }

    const textChange = (value, name, save) => {
        let clonedInputs = {...inputs}
        clonedInputs[name].value = value;
        if (save) {
            if (clientId) {
                navigate('/clients/' + clientId + '/facilities/' + facilityId + '/linked-systems/' + linkedSystemId + '/contracts/' + value + '/draft')
            } else {
                navigate('/contracts/' + value + '/draft')
            }
        } else {
            getContractsSearch(clonedInputs);
        }
    }
    
    const selectChange = (value) => {
        if (clientId) {
            navigate('/clients/' + clientId + '/facilities/' + facilityId + '/linked-systems/' + linkedSystemId + '/contracts/' + value + '/draft')
        } else {
            navigate('/contracts/' + value + '/draft')
        }
    }

    if (isNull(inputs)) {
        return <Spinner />
    } else {
        return (
            <Card className="ribbon-wrapper" style={{ marginBottom: '0px' }}>
                <CardBody>
                    <div className="ribbon ribbon-clip ribbon-primary">{t('contracts.list.title')}</div>
                    <div>
                        <SelectSearch data={inputs['contract']} onChange={selectChange} />
                        <AutocompleteInput data={inputs['contract_number']} onChange={textChange} />
                    </div>
                </CardBody>
            </Card>
        )
    }
}

export default ContractSearch