import React, { useEffect, useState, useMemo } from 'react'
import { t } from 'i18next'
// import {Pagination, PaginationItem} from 'reactstrap'

import { isNull } from '../../izUtils'

const PaginationBottom = ({filters, getData}) => {
    const [inputs, setInputs] = useState(null)

    useEffect(() => {
        setInputs(filters)
    
    }, [filters])

    const textChange = (value, name) => {
        let clonedInputs = {...inputs}
        clonedInputs[name].value = value;
        getData(clonedInputs)
    }

    const paginationButtons = useMemo(() => {
        let buttons = []
        if (!isNull(inputs)) {
            const pages = Math.ceil(inputs['pagination_total'].value / inputs['pagination_show_per_page'].value);
            if (pages > 1) {
                if (inputs['pagination_page'].value >= 4) {
                    if (pages > 4) {
                        buttons.push(<button key={"page-1"} className="page-link" onClick={() => textChange(1, 'pagination_page')}>1</button>)
                        buttons.push(<button key={"page-first-..."} disabled className="page-link" onClick={() => {}}>...</button>)
    
                        if (inputs['pagination_page'].value > (pages - 3)) {
                            for (let i = pages - 3; i <= pages; i++ ) {
                                buttons.push(<button key={"page-"+i} className={"page-link" + (i === inputs['pagination_page'].value ? ' active' : '')} onClick={() => textChange(i, 'pagination_page')}>{i}</button>)
                            }
                        } else {
                            for (let i = inputs['pagination_page'].value - 1; i <= inputs['pagination_page'].value + 1; i++) {
                                buttons.push(<button key={"page-"+i} className={"page-link" + (i === inputs['pagination_page'].value ? ' active' : '')} onClick={() => textChange(i, 'pagination_page')}>{i}</button>)
                            }
    
                            buttons.push(<button key={"page-..."} disabled className="page-link" onClick={() => {}}>...</button>)
                            buttons.push(<button key={"page-last"} className="page-link" onClick={() => textChange(pages, 'pagination_page')}>{pages}</button>)
                        }
                    } else {
                        for (let i = 1; i <= pages; i++) {
                            buttons.push(<button key={"page-"+i} className={"page-link" + (i === inputs['pagination_page'].value ? ' active' : '')} onClick={() => textChange(i, 'pagination_page')}>{i}</button>)
                        }
                    }
                } else {
                    let show_buttons;
                    if (pages < 4) {
                        show_buttons = pages;
                    } else {
                        show_buttons = 4;
                    }
    
                    for (let i = 1; i <= show_buttons; i++) {
                        buttons.push(<button key={"page-"+i} className={"page-link" + (i === inputs['pagination_page'].value ? ' active' : '')} onClick={() => textChange(i, 'pagination_page')}>{i}</button>)
                    }
    
                    if (pages > 4) {
                        buttons.push(<button key={"page-last-..."} disabled className="page-link" onClick={() => {}}>...</button>)
                        buttons.push(<button key={"page-last"} className="page-link" onClick={() => textChange(pages, 'pagination_page')}>{pages}</button>)
                    }
                }
            }
        }

        return buttons;
    }, [inputs]) // eslint-disable-line react-hooks/exhaustive-deps

    if (!isNull(inputs)) {
        return (
            <div className='d-flex flex-wrap justify-content-between align-items-center'>
                <div>
                    {((inputs['pagination_page'].value - 1) * inputs['pagination_show_per_page'].value) + 1}-
                    {inputs['pagination_page'].value * inputs['pagination_show_per_page'].value}
                    &nbsp;{t('of')}&nbsp;{inputs['pagination_total'].value}
                </div>
                {/* <Pagination aria-label="Page navigation example" className="pagination-primary" >
                    <PaginationItem><button className='page-link'><span aria-hidden="true">«</span><span className="sr-only">{"Previous"}</span></button></PaginationItem> */}
                        <div className='pagination text-end'>
                            {paginationButtons}
                        </div>
                    {/* <PaginationItem><button className='page-link'><span aria-hidden="true">»</span><span className="sr-only">{"Next"}</span></button></PaginationItem>
                </Pagination> */}
            </div>
        )
    }
}

export default PaginationBottom