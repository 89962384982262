import React, { useEffect, useState, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import {t} from 'i18next'
import DataTable from 'react-data-table-component'
import { FormGroup } from 'reactstrap'
import moment from 'moment'

import { customStyles, displayDate, isNull } from '../../izUtils'

const AllContractList = ({data, filters, getData}) => {
    const Navigate = useNavigate();

    const [showDropdown, setShowDropdown] = useState(false);
    const [dropdownPosition, setDropdownPosition] = useState({ x: 0, y: 0 });
    const [dropdownOptions, setDropdownOptions] = useState([]);

    const buttonRef = useRef([]);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            // Close the element if clicked outside of it
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                if (buttonRef.current[showDropdown] !== event.target) {
                    setShowDropdown(false);
                }
            }
        }

        document.addEventListener('click', handleClickOutside);
        return () => {
          document.removeEventListener('click', handleClickOutside);
        }
    }, [dropdownRef, showDropdown]);

    // Table handlers
    const handleRowClick = (state) => {
        if (state.permissions['contract.get']) {
            Navigate('/contracts/' + state.id + '/get')
        } else if (state.permissions['contract.update']) {
            Navigate('/contracts/' + state.id + '/update')
        }
    }

    const handleSort = async (column, sortDirection) => {
        filters['order'].value = sortDirection.toUpperCase();
        filters['order_by'].value = column.sortField;
        getData(filters)
    };

    // Dropdown handlers
    const handleButtonClick = (event, row) => {
        // Get the position of the clicked button
        const rect = event.target.getBoundingClientRect();
        const buttonX = rect.left;
        const buttonY = rect.bottom;

        // Get the position of the parent element by id
        const parentElement = document.getElementById('allContractsListContainer');
        const parentRect = parentElement.getBoundingClientRect();
        const parentX = parentRect.left;
        const parentY = parentRect.top;

        // Calculate the position of the button relative to the parent element
        const relativeX = buttonX - parentX;
        const relativeY = buttonY - parentY;

        let options = [];
        if (row.permissions['contract.get']) {
            options.push({type: 'single', row})
        }
        if (row.permissions['contract.update']) {
            options.push(
                {type: 'draft', row},
                {type: 'update', row}
            )
        }
        
        setDropdownOptions(options)
        setDropdownPosition({ x: relativeX, y: relativeY });
        setShowDropdown(row.id);
    }

    const handleDropdown = (option) => {
        if (option.type === 'single')
            Navigate('/contracts/' + option.row.id + '/get')
        if (option.type === 'draft') {
            Navigate('/contracts/' + option.row.id + '/draft')
        } else if (option.type === 'update') {
            Navigate('/contracts/' + option.row.id + '/update')
        }
    }

    // This loops through rows and creates them
    const tableColumns = useMemo(() => {
        return [
            {
                name: '',
                sortable: false,
                cell: row => {
                    return (
                        <button 
                            className='btn btn-secondary'
                            ref={el => buttonRef.current[row.id] = el}
                            onClick={(e) => handleButtonClick(e, row)}
                        ><i className="fa fa-ellipsis-v" style={{ pointerEvents: 'none' }}></i></button>
                    )
                }
            },
            { 
                name: t('table.contract_number'),
                sortable: true,
                sortField: 'contract_number',
                cell: row => isNull(row.contract_number) ? '' : row.contract_number,
            },
            { 
                name: t('table.client'),
                sortable: true,
                sortField: 'client',
                cell: row => {
                    if (!isNull(row.facilities) && row.facilities.length !== 0) {
                        return <div className='d-flex flex-column' onClick={() => handleRowClick(row)}>
                            {row.facilities.map((facility, index) => {
                                if (!isNull(facility.facility) && !isNull(facility.facility.client)) {
                                    return <div key={"client-"+facility.facility.client.id+'-'+index}>{facility.facility.client.client + (index === (row.facilities.length -1) ? '' : ', ')}</div>
                                } else {
                                    return "";
                                }
                            })}
                        </div>
                    } else {
                        return "";
                    }
                }
            },
            { 
                name: t('table.facility'),
                sortable: true,
                sortField: 'facility',
                cell: row => {
                    if (!isNull(row.facilities) && row.facilities.length !== 0) {
                        return <div className='d-flex flex-column' onClick={() => handleRowClick(row)}>
                            {row.facilities.map((facility, index) => {
                                if (!isNull(facility.facility)) {
                                    return <div key={"facility-"+facility.facility.id+'-'+index}>{facility.facility.name + (index === (row.facilities.length -1) ? '' : ', ')}</div>
                                } else {
                                    return "";
                                }
                            })}
                        </div>
                    } else {
                        return "";
                    }
                }
            },
            { 
                name: t('table.active_to'),
                sortable: true,
                sortField: 'active_to',
                cell: row => isNull(row.active_to) ? t('form.note.indefinitely') : displayDate(moment.utc(row.active_to), 'date')
            },
        ]
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    
    return (
        <div id="allContractsListContainer" style={{ position: 'relative' }}>
            <FormGroup>
                <DataTable
                    columns={tableColumns}
                    data={data}
                    customStyles={customStyles}
                    onRowClicked={handleRowClick}
                    onSort={handleSort}
                    sortServer
                />
            </FormGroup>
            {showDropdown && (
                <div
                    ref={dropdownRef}
                    style={{
                        position: 'absolute',
                        top: dropdownPosition.y,
                        left: dropdownPosition.x,
                        backgroundColor: '#fff',
                        zIndex: '200',
                        cursor: 'pointer',
                        boxShadow: '0 8px 16px 0 rgba(0,0,0,.2)',
                    }}
                >
                    {dropdownOptions.map(option => (
                        <div key={'dropdown-option-'+option.type} className="custom-dropdown-item" onClick={() => handleDropdown(option)}>{t(`contracts.list.${option.type}`)}</div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default AllContractList