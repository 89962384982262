import React, { Fragment } from 'react';
import Header from '../layout/header/Header'
import Footer from '../layout/footer/Footer'
import { Outlet } from 'react-router-dom';

const AppLayout = ({ children, classNames, ...rest }) => {

  return (
    <Fragment>
        <div className="page-wrapper horizontal-wrapper enterprice-type" id="pageWrapper">
            <Header />
            <div className="page-body-wrapper">
                <div className="page-body">
                    <div><Outlet /></div>
                </div>
                <Footer />
            </div>
        </div>
    </Fragment>
  );
}

export default AppLayout;