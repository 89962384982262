import React from 'react'

const Spinner = () => {
    return (
        <div style={{ width: '100%' }} className="d-flex align-items-center justify-content-center mt-2">
            <div className="loader-box">
                <div className="loader-2"></div>
            </div>
        </div>
    )
}

export default Spinner