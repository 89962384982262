import React, {useState, useEffect, useMemo} from 'react'
import { t } from 'i18next'
import { FormGroup, Label } from 'reactstrap'
import { isNull, replaceSpecialChars } from '../../../izUtils'
import useComponentVisible from '../../../helpers/hooks/useComponentVisible'

const Select = ({data, onChange, showErrors, lsFilterName}) => {
    const { name, display, errors, title, values, disabled, mandatory} = data
    const isFilter = !isNull(lsFilterName)

    const [value, setvalue] = useState("")
    const [search, setSearch] = useState("");
    const [activeSuggestion, setActiveSuggestion] = useState(0)
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false, "select-search-"+name);

    useEffect(() => {
        setvalue(data.value === null ? '' : data.value)
    }, [data.value])

    useEffect(() => {
        if (search.length !== 0) {
            setSearch("")
        }
    }, [JSON.stringify(data.values)]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isComponentVisible) {
            document.getElementById("select-search-"+name)?.focus()
        }

    }, [isComponentVisible])

    const handleChange = (e) => {
        setvalue(e.target.value)

        // Save filter data to LS, so it can be used if user clicks "Confirm" button
        if (isFilter) {
            let lsFilter = JSON.parse(localStorage.getItem(lsFilterName))
            if (!isNull(lsFilter)) {
                for (const filterName in lsFilter) {
                    if (filterName === name) {
                        lsFilter[filterName].value = e.target.value;
                        localStorage.setItem(lsFilterName, JSON.stringify(lsFilter))

                        const callBEAfterChange = ['client', 'facility'] // BE needs to be called after changing one of these filters, because they are related to other filters
                        if (callBEAfterChange.indexOf(name) !== -1) {
                            onChange(e.target.value, name)
                        }
                        break;
                    }
                }
            }
        } else {
            onChange(e.target.value, name)
        }
    };

    const handleClick = (option, index) => {
        setActiveSuggestion(index);
        handleChange({ target: {value: option.id} })
    }

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) { // enter key
            handleChange({ target: {value: options[activeSuggestion].id} })
            setIsComponentVisible(false);
        } else if (e.keyCode === 38) { // up arrow key
            e.preventDefault();
            if (activeSuggestion === 0) return;
            setActiveSuggestion(prev => {
                const newSuggestion = prev - 1;
                scrollIntoView(newSuggestion);
                return newSuggestion;
            });
        } else if (e.keyCode === 40) { // down arrow key
            e.preventDefault();
            if (activeSuggestion + 1 === options.length) return;
            setActiveSuggestion(prev => {
                const newSuggestion = prev + 1;
                scrollIntoView(newSuggestion);
                return newSuggestion;
            });
        } else if ((e.keyCode !== 37) && (e.keyCode !== 39)) { // left and right arrow keys
            setActiveSuggestion(0);
            scrollIntoView(0);
        }
    };

    // Function to ensure the active item is in view
    const scrollIntoView = (index) => {
        const activeItem = document.querySelector(`#select-search-content-${name} li:nth-child(${index + 2})`);
        const dropdownContainer = document.querySelector(`#select-search-content-${name}`);

        if (activeItem && dropdownContainer) {
            const containerRect = dropdownContainer.getBoundingClientRect();
            const activeItemRect = activeItem.getBoundingClientRect();

            // Check if the active item is out of view and adjust scroll position
            if (activeItemRect.top < containerRect.top) {
                activeItem.scrollIntoView({ block: "start" });
            } else if (activeItemRect.bottom > containerRect.bottom) {
                activeItem.scrollIntoView({ block: "end" });
            }
        }
    };

    const options = useMemo(() => {
        if (!isNull(values)) {
            let filteredValues = [];
            for (let i = 0; i < values.length; i++) {
                if ( (search.length === 0) || (!isNull(values[i].title) && replaceSpecialChars(values[i].title.toLowerCase()).includes( replaceSpecialChars( search.toLowerCase()) )) ) {
                    filteredValues.push( values[i] );
                }
            }
            return filteredValues;
        } else {
            return [];
        }
        // eslint-disable-next-line
    }, [values, search])

    const displayedValue = useMemo(() => {
        let displayedName = t('defaultSelect');
        if (!isNull(values)) {
            values.forEach(option => {
                if (option.id === value) displayedName = option.title;
            });
        }
        return displayedName;
    }, [value, values])

    let showMoreInfo = useMemo(() => {
        if ((name === 'facility') && !isNull(value) && (value.length !== 0)) {
            let findValue = '';
            if (!isNull(values)) findValue = values.find(option => option.id === value)
            if (!isNull(findValue)) {
                return (
                    <div>
                        {(!isNull(values) && values.length !== 0) &&
                            <ul className='mt-1'>
                                {!isNull(findValue.google_location) &&
                                    <li>
                                        {findValue.google_location}
                                        <a href={'https://www.google.com/maps/place/' + findValue.google_location} target="_blank" rel="noreferrer" className='ms-2'><i className="fa fa-map-marker"></i></a>
                                    </li>
                                }
                                {!isNull(findValue.facility_notes) && <li><b>{t('form.label.notes') + ': '}</b>{findValue.facility_notes}</li>}
                                {!isNull(findValue.technician_expertise) && <li><b>{t('form.label.technician_expertise') + ': '}</b>{findValue.technician_expertise}</li>}
                                {!isNull(findValue.salesperson_expertise) && <li><b>{t('form.label.salesperson_expertise') + ': '}</b>{findValue.salesperson_expertise}</li>}
                            </ul>
                        }
                    </div>
                )
            }
        } else {
            return null;
        }
    }, [value, values, name])

    if (display) {
        return (
            <FormGroup>
                <Label>{title + (mandatory ? " *" : "")}</Label>
                <div ref={ref} style={{ width: '100%', position: 'relative' }}>
                    <div className='form-control digits form-select' name={name} style={ disabled ? { zIndex: '200', background: '#ededed', cursor: 'not-allowed' } : { zIndex: '200' } }>
                        {displayedValue}
                    </div>
                    {/* DROPDOWN */}
                    {!disabled &&
                        <div className="select-search-dropdown" style={{ display: isComponentVisible ? 'block' : 'none' }}>
                            <input className="form-control" id={"select-search-"+name} value={search} onChange={(e) => setSearch(e.target.value)} disabled={disabled} onKeyDown={handleKeyDown} autoComplete="off" />
                            <ul id={"select-search-content-"+name} className="select-search-options">
                                <li key={'default-option'} onClick={() => handleChange({ target: {value: ""} })}>{t('defaultSelect')}</li>
                                {options.map((option, i) => <li key={name + '-' + i} className={activeSuggestion === i ? 'active' : '' } onClick={() => handleClick(option, i)}>{option.title}</li>)}
                            </ul>
                        </div>
                    }
                </div>
                {!isNull(showErrors) && showErrors && !isNull(errors) && errors.length !== 0 && <div style={{ color: "red", marginBottom: "10px" }}>{errors[0]}</div>}
                {!isNull(showMoreInfo) && showMoreInfo}
            </FormGroup>

        )
    } else {
        return null
    }
}

export default Select