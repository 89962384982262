import React from 'react'
import { Link } from 'react-router-dom'
import {t} from 'i18next'
import { ListGroup, ListGroupItem } from 'reactstrap'

import { isNull } from '../../izUtils'

const RelatedTasks = ({relatedTasks}) => {
    if (!isNull(relatedTasks) && relatedTasks.length !== 0) {
        return (
            <div>
                <h6>{t('error')}</h6>
                <div>{t('Uporabnik je trenutno vezan še na projekte:')}</div>
                <div></div>
                <ListGroup className="list-group-flush">
                    {relatedTasks.map(task => (
                        <ListGroupItem key={"related-task-"+task.id}>
                            <Link to={"/task/" + task.id}>{task.client.client}</Link>
                        </ListGroupItem>
                    ))}
                </ListGroup>
            </div>
        )
    }
}

export default RelatedTasks