import React, { Fragment, useEffect, useState } from 'react'
import {t} from 'i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil'
import axios from 'axios';
import { Accordion } from 'react-bootstrap';
import { Container, Card, CardBody, Button, Row, Col, Collapse, CardFooter } from 'reactstrap'
import Modal from 'react-modal';

import env from '../../env/src_config';
import { headersState, tokenState } from '../../recoil/recoil';
import { isNull, modalStyles } from '../../izUtils';
import Spinner from '../../components/spinner/Spinner';
import Breadcrumbs from '../../layout/breadcrumb/Breadcrumb';
import { transformer } from '../../helpers/fields';
import { axiosError } from '../../helpers/response';
import PaginationTop from '../../components/Pagination/PaginationTop';
import PaginationBottom from '../../components/Pagination/PaginationBottom';
import AllContractList from '../../components/contracts/AllContractList';
import Filter from '../../components/filters/Filter';
import Loader from '../../components/spinner/Loader';
import ContractSearch from '../../components/contracts/modals/ContractSearch';

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

const AllContracts = () => {
    const Navigate = useNavigate();
    const headers = useRecoilValue(headersState);
    const tokenData = useRecoilValue(tokenState);

    const [contracts, setContracts] = useState(null)
    const [tableLoading, setTableLoading] = useState(false)
    const [filters, setFilters] = useState(null)
    const [isFilterOpen, setIsFilterOpen] = useState(true);
    const [modalContractSearch, setModalContractSearch] = useState(false);

    const lsFilterName = 'AKODA.contractFilter';

    useEffect(() => {
        const lsFilter = JSON.parse(localStorage.getItem(lsFilterName));
        getContracts(isNull(lsFilter) ? {} : lsFilter);

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getContracts = (passedFilters) => {
        setTableLoading(true);

        const keys = Object.keys(passedFilters);
        let payload = { filters: {} };
        for (let i = 0; i < keys.length; i++) {
            if (!isNull(passedFilters[keys[i]].value) && passedFilters[keys[i]].value.length !== 0) {
                payload.filters[keys[i]] = passedFilters[keys[i]].value;
            } else {
                payload.filters[keys[i]] = "";
            }
        }

        axios.post(env.api + '/api/contract/list', payload, {headers}).then(response => {
            if (!isNull(response.data.data)) {
                setContracts(response.data.data);
                if (!isNull(response.data.filters)) {
                    setFilters(transformer(response.data.filters));
                    localStorage.setItem(lsFilterName, JSON.stringify(transformer(response.data.filters)))
                }
            }
            setTableLoading(false);
        }).catch(error => {
            setTableLoading(false);
            axiosError(error, Navigate);
        });
    }

    const clearData = () => {
        localStorage.removeItem(lsFilterName);
        getContracts({});
    }

    if (isNull(contracts)) {
        return <Spinner />
    } else {
        const filterFields = ['client', 'facility', 'contract_number', 'active_to', 'security_system', 'manufacturer', 'group_contract_number', 'price_matching']
        return (
            <Fragment>
                <Breadcrumbs title={t('breadcrumb.contracts')} />
                <Container fluid={true}>
                    <Row>
                        <Col sm="12"> 
                            <Card className="ribbon-wrapper">
                                <CardBody>
                                    <div className="ribbon ribbon-clip ribbon-primary">{t('contracts.all.title')}</div>
                                    <div>
                                        <Accordion className='mb-3'>
                                            <div className="default-according style-1" id="contractfilter">
                                                <Button color="secondary" className="acc-btn d-flex justify-content-between align-items-center" data-toggle="collapse" onClick={() => setIsFilterOpen(!isFilterOpen)}>
                                                    {t('filters')}
                                                    <div className={'filter-icon' + (isFilterOpen ? ' rotate-icon' : '')}>
                                                        <i className="fa fa-chevron-down"></i>
                                                    </div>
                                                </Button>
                                                <Collapse isOpen={isFilterOpen}>
                                                    <div className='p-3'>
                                                        <Filter filterFields={filterFields} filters={filters} getData={(clonedFilters) => getContracts(clonedFilters)} lsFilterName={lsFilterName} clearData={clearData} />
                                                    </div>
                                                </Collapse>
                                            </div>
                                        </Accordion>
                                        <div style={{ position: 'relative' }}>
                                            {tableLoading && <Loader />}
                                            <PaginationTop filters={filters} getData={clonedFilters => getContracts(clonedFilters)} />
                                            <AllContractList data={contracts} filters={filters} getData={clonedFilters => getContracts(clonedFilters)} />
                                            <PaginationBottom filters={filters} getData={clonedFilters => getContracts(clonedFilters)} />
                                        </div>
                                    </div>
                                </CardBody>
                                {tokenData.parsedToken.type === 'admin' &&
                                    <CardFooter>
                                        <div className='text-end'>
                                            <Button color="primary" className="mt-2 me-2" onClick={() => setModalContractSearch(true)}>{t('contracts.list.searchDraft')}</Button>
                                            <Button color="primary" className="mt-2 me-2" onClick={() => Navigate('/contracts/create')}>{t('contracts.list.create')}</Button>
                                        </div>
                                    </CardFooter>
                                }
                            </Card>
                        </Col>
                    </Row>
                    <hr/>
                    <div className='d-flex flex-wrap justify-content-between mb-4'>
                        <Button color="danger" className="mt-2 me-2" onClick={() => Navigate(-1)}>{t('back')}</Button> 
                    </div>
                </Container>

                {/* SEARCH CONTRACTS */}
                <Modal
                    isOpen={modalContractSearch}
                    onRequestClose={() => setModalContractSearch(false)}
                    style={modalStyles}
                    contentLabel="Search Contracts Modal"
                >
                    <ContractSearch />
                </Modal>
            </Fragment>
        )
    }
}

export default AllContracts