import React, {useEffect, useState, Fragment} from 'react'
import {t} from 'i18next'
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Swal from 'sweetalert2';
import { Row, Col, Form, FormGroup, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import env from '../../../env/src_config';
import { headersState } from '../../../recoil/recoil';
import { errorStatus, axiosError} from '../../../helpers/response';
import { linker, transformer } from '../../../helpers/fields';
import { isNull } from '../../../izUtils';
import Spinner from '../../spinner/Spinner';
import FileUpload from '../../fileUpload/FileUpload';

const ModalCreateInventoryReceipt = ({modaltoggle, addInventoryReceiptId, taskData}) => {
    const {id} = useParams();
    const navigate = useNavigate();

    const headers = useRecoilValue(headersState);
    const [inputs, setInputs] = useState(null);
    const [showErrors, setShowErrors] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);

    const lsInventoryReceiptInputs = 'AKODA.inventoryReceiptInputs';
    const lsInventoryReceiptFiles = 'AKODA.inventoryReceiptFiles';

    useEffect(() => {
        getData(false, null);
    
        return () => {
            localStorage.removeItem(lsInventoryReceiptInputs)
            localStorage.removeItem(lsInventoryReceiptFiles)
        }
    }, []) // eslint-disable-line

    const getData = (save, passedInputs) => {
        if (save) setDisableBtn(true);
        let payload = { save };

        if (!isNull(passedInputs)) {
            // Get data from inputs
            const keys = Object.keys(passedInputs);
            payload.data = {};
            for (let i = 0; i < keys.length; i++) {
                if (!isNull(passedInputs[keys[i]].value) && passedInputs[keys[i]].value.length !== 0) {
                    payload.data[keys[i]] = passedInputs[keys[i]].value;
                } else {
                    payload.data[keys[i]] = "";
                }
            }
    
            // Get data from localStorage
            let inventoryReceiptFiles = JSON.parse(localStorage.getItem(lsInventoryReceiptFiles))
            if (!isNull(inventoryReceiptFiles)) {
                if (isNull(payload.data)) payload.data = {};
                payload.data.files = inventoryReceiptFiles.map(file => file.id);
            }
        }

        axios.post(env.api + '/api/inventory_receipt/' + id, payload, {headers}).then(response => {
            if (save) {
                if (!isNull(response.data.state) && response.data.state === 'success') {
                    Swal.fire({
                        title:  t('saved'),
                        text:  response.data.message,
                        icon: 'success',
                        confirmButtonColor: 'var(--theme-default)',
                        confirmButtonText: t('ok'),
                    }).then(() => {
                        addInventoryReceiptId(response.data);
                        modaltoggle();
                    });
                } else {
                    errorStatus(response.data, t);
                    setShowErrors(true);
                }

                setDisableBtn(false);
            }
            
            if (!isNull(response.data.data)) {
                let transformedResponse = transformer(response.data.data)
                setInputs(transformedResponse)
                localStorage.setItem(lsInventoryReceiptInputs, JSON.stringify(transformedResponse));
            }
        }).catch(error => {
            axiosError(error, navigate);
        });
    }

    const textChange = (value, name) => {
        let clonedInputs = {...inputs};
        clonedInputs[name].value = value;
        getData(false, clonedInputs);
    }

    // Create fields
    const mapLinker = (field) => {
        const inputlinkerFields = {
            field,
            inputs,
            showErrors,
            textHandler: (value, id) => textChange(value, id),
        }

        return linker(inputlinkerFields);
    }

    if (isNull(inputs)) {
        return <Spinner />
    } else {
        return (
            <Fragment>
                <ModalHeader toggle={modaltoggle}>
                    {t('inventoryReceipt.modal.title')}
                </ModalHeader>
                <ModalBody>
                    <Form className="theme-form">
                        <Row>
                            <Col sm="12">
                                <FormGroup>
                                    <Row>
                                        <Col md="6"> 
                                            { ['number'].map(field => mapLinker(field)) }
                                        </Col>
                                        <Col md="6"> 
                                            <FileUpload data={inputs.files} saveToLS={lsInventoryReceiptFiles} apiUrl="/api/inventory_receipt/file/" inputType="createInventoryReceipt" taskData={taskData} />
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <div className='w-100 d-flex justify-content-between'>
                        <Button color="secondary" className='me-2' disabled={disableBtn} onClick={modaltoggle}>{t('inventoryReceipt.modal.cancel')}</Button>
                        <Button color="primary" className='me-2' disabled={disableBtn} onClick={() => getData(true, inputs)}>{t('inventoryReceipt.modal.create')}</Button>
                    </div>
                </ModalFooter>
            </Fragment>
        )
    }
}

export default ModalCreateInventoryReceipt