import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios'
import { t } from 'i18next'
import { useRecoilState } from 'recoil'
import { Container, Row, Col, Form, Card, CardBody, Button } from 'reactstrap'

import env from '../../env/src_config'
import { isNull } from '../../izUtils'
import Spinner from '../../components/spinner/Spinner'
import { transformer } from '../../helpers/fields'
import { headersState } from '../../recoil/recoil'
import { axiosError } from '../../helpers/response'
import Breadcrumbs from '../../layout/breadcrumb/Breadcrumb'
import DisplayFields from '../../helpers/displayFields';

const MailSingle = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    
    const [headers] = useRecoilState(headersState);
    const [inputs, setInputs] = useState(null)

    useEffect(() => {
        updateData();

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const updateData = () => {
        axios.get(env.api + '/api/mail/' + id, {headers}).then(response => {
            if (!isNull(response.data.data)) setInputs(transformer(response.data.data));
        }).catch(error => {
            axiosError(error, navigate);
        });
    }

    // Create fields
    const mapLinker = (field) => {
        return <DisplayFields key={'display-'+inputs[field].name} data={inputs[field]} />
    }

    return (
        <Fragment>
            <Breadcrumbs parent={t('breadcrumb.mailList')} title={t('breadcrumb.mail')}/>
            <Container fluid={true}>
                <Row>
                    <Col lg="12">
                        <Card className="ribbon-wrapper">
                            <CardBody>
                                <div className="ribbon ribbon-clip ribbon-primary">{t('mail.single.title')}</div>
                                {isNull(inputs) ?
                                    <Spinner />
                                    :
                                    <Form className="theme-form">
                                         <Row>
                                            <Col md="6">
                                                { ['to', 'subject', 'inserted', 'sent', 'last_error', 'last_error_description', 'files'].map(field => mapLinker(field)) }
                                            </Col>
                                         </Row>
                                    </Form>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <div className='text-start'>
                    <Button color="danger" className="mt-2 me-2" onClick={() => navigate(-1)}>{t('back')}</Button>
                </div>
            </Container>
        </Fragment>

    )
}

export default MailSingle