import React, { Fragment, } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { routes } from './Routes';
import AppLayout from '../components/layout';

const LayoutRoutes = () => {
  return (
    <Fragment>
        <Routes>
          {routes.map(({ path, Component }, i) => (
            <Route element={<AppLayout />} key={i}>
              <Route path={path} element={Component} />
            </Route>
          ))}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    </Fragment >
  );
};

export default LayoutRoutes;