const holidays = [
    "2000-01-01",
    "2000-01-02",
    "2000-02-08",
    "2000-04-23",
    "2000-04-24",
    "2000-04-27",
    "2000-05-01",
    "2000-05-02",
    "2000-06-11",
    "2000-06-25",
    "2000-08-15",
    "2000-10-31",
    "2000-11-01",
    "2000-12-25",
    "2000-12-26",
    "2001-01-01",
    "2001-01-02",
    "2001-02-08",
    "2001-04-15",
    "2001-04-16",
    "2001-04-27",
    "2001-05-01",
    "2001-05-02",
    "2001-06-03",
    "2001-06-25",
    "2001-08-15",
    "2001-10-31",
    "2001-11-01",
    "2001-12-25",
    "2001-12-26",
    "2002-01-01",
    "2002-01-02",
    "2002-02-08",
    "2002-03-31",
    "2002-04-01",
    "2002-04-27",
    "2002-05-01",
    "2002-05-02",
    "2002-05-19",
    "2002-06-25",
    "2002-08-15",
    "2002-10-31",
    "2002-11-01",
    "2002-12-25",
    "2002-12-26",
    "2003-01-01",
    "2003-01-02",
    "2003-02-08",
    "2003-04-20",
    "2003-04-21",
    "2003-04-27",
    "2003-05-01",
    "2003-05-02",
    "2003-06-08",
    "2003-06-25",
    "2003-08-15",
    "2003-10-31",
    "2003-11-01",
    "2003-12-25",
    "2003-12-26",
    "2004-01-01",
    "2004-01-02",
    "2004-02-08",
    "2004-04-11",
    "2004-04-12",
    "2004-04-27",
    "2004-05-01",
    "2004-05-02",
    "2004-05-30",
    "2004-06-25",
    "2004-08-15",
    "2004-10-31",
    "2004-11-01",
    "2004-12-25",
    "2004-12-26",
    "2005-01-01",
    "2005-01-02",
    "2005-02-08",
    "2005-03-27",
    "2005-03-28",
    "2005-04-27",
    "2005-05-01",
    "2005-05-02",
    "2005-05-15",
    "2005-06-25",
    "2005-08-15",
    "2005-10-31",
    "2005-11-01",
    "2005-12-25",
    "2005-12-26",
    "2006-01-01",
    "2006-01-02",
    "2006-02-08",
    "2006-04-16",
    "2006-04-17",
    "2006-04-27",
    "2006-05-01",
    "2006-05-02",
    "2006-06-04",
    "2006-06-25",
    "2006-08-15",
    "2006-10-31",
    "2006-11-01",
    "2006-12-25",
    "2006-12-26",
    "2007-01-01",
    "2007-01-02",
    "2007-02-08",
    "2007-04-08",
    "2007-04-09",
    "2007-04-27",
    "2007-05-01",
    "2007-05-02",
    "2007-05-27",
    "2007-06-25",
    "2007-08-15",
    "2007-10-31",
    "2007-11-01",
    "2007-12-25",
    "2007-12-26",
    "2008-01-01",
    "2008-01-02",
    "2008-02-08",
    "2008-03-23",
    "2008-03-24",
    "2008-04-27",
    "2008-05-01",
    "2008-05-02",
    "2008-05-11",
    "2008-06-25",
    "2008-08-15",
    "2008-10-31",
    "2008-11-01",
    "2008-12-25",
    "2008-12-26",
    "2009-01-01",
    "2009-01-02",
    "2009-02-08",
    "2009-04-12",
    "2009-04-13",
    "2009-04-27",
    "2009-05-01",
    "2009-05-02",
    "2009-05-31",
    "2009-06-25",
    "2009-08-15",
    "2009-10-31",
    "2009-11-01",
    "2009-12-25",
    "2009-12-26",
    "2010-01-01",
    "2010-01-02",
    "2010-02-08",
    "2010-04-04",
    "2010-04-05",
    "2010-04-27",
    "2010-05-01",
    "2010-05-02",
    "2010-05-23",
    "2010-06-25",
    "2010-08-15",
    "2010-10-31",
    "2010-11-01",
    "2010-12-25",
    "2010-12-26",
    "2011-01-01",
    "2011-01-02",
    "2011-02-08",
    "2011-04-24",
    "2011-04-25",
    "2011-04-27",
    "2011-05-01",
    "2011-05-02",
    "2011-06-12",
    "2011-06-25",
    "2011-08-15",
    "2011-10-31",
    "2011-11-01",
    "2011-12-25",
    "2011-12-26",
    "2012-01-01",
    "2012-01-02",
    "2012-02-08",
    "2012-04-08",
    "2012-04-09",
    "2012-04-27",
    "2012-05-01",
    "2012-05-02",
    "2012-05-27",
    "2012-06-25",
    "2012-08-15",
    "2012-10-31",
    "2012-11-01",
    "2012-12-25",
    "2012-12-26",
    "2013-01-01",
    "2013-02-08",
    "2013-03-31",
    "2013-04-01",
    "2013-04-27",
    "2013-05-01",
    "2013-05-02",
    "2013-05-19",
    "2013-06-25",
    "2013-08-15",
    "2013-10-31",
    "2013-11-01",
    "2013-12-25",
    "2013-12-26",
    "2014-01-01",
    "2014-02-08",
    "2014-04-20",
    "2014-04-21",
    "2014-04-27",
    "2014-05-01",
    "2014-05-02",
    "2014-06-08",
    "2014-06-25",
    "2014-08-15",
    "2014-10-31",
    "2014-11-01",
    "2014-12-25",
    "2014-12-26",
    "2015-01-01",
    "2015-02-08",
    "2015-04-05",
    "2015-04-06",
    "2015-04-27",
    "2015-05-01",
    "2015-05-02",
    "2015-05-24",
    "2015-06-25",
    "2015-08-15",
    "2015-10-31",
    "2015-11-01",
    "2015-12-25",
    "2015-12-26",
    "2016-01-01",
    "2016-02-08",
    "2016-03-27",
    "2016-03-28",
    "2016-04-27",
    "2016-05-01",
    "2016-05-02",
    "2016-05-15",
    "2016-06-25",
    "2016-08-15",
    "2016-10-31",
    "2016-11-01",
    "2016-12-25",
    "2016-12-26",
    "2017-01-01",
    "2017-01-02",
    "2017-02-08",
    "2017-04-16",
    "2017-04-17",
    "2017-04-27",
    "2017-05-01",
    "2017-05-02",
    "2017-06-04",
    "2017-06-25",
    "2017-08-15",
    "2017-10-31",
    "2017-11-01",
    "2017-12-25",
    "2017-12-26",
    "2018-01-01",
    "2018-01-02",
    "2018-02-08",
    "2018-04-01",
    "2018-04-02",
    "2018-04-27",
    "2018-05-01",
    "2018-05-02",
    "2018-05-20",
    "2018-06-25",
    "2018-08-15",
    "2018-10-31",
    "2018-11-01",
    "2018-12-25",
    "2018-12-26",
    "2019-01-01",
    "2019-01-02",
    "2019-02-08",
    "2019-04-21",
    "2019-04-22",
    "2019-04-27",
    "2019-05-01",
    "2019-05-02",
    "2019-06-09",
    "2019-06-25",
    "2019-08-15",
    "2019-10-31",
    "2019-11-01",
    "2019-12-25",
    "2019-12-26",
    "2020-01-01",
    "2020-01-02",
    "2020-02-08",
    "2020-04-12",
    "2020-04-13",
    "2020-04-27",
    "2020-05-01",
    "2020-05-02",
    "2020-05-31",
    "2020-06-25",
    "2020-08-15",
    "2020-10-31",
    "2020-11-01",
    "2020-12-25",
    "2020-12-26",
    "2021-01-01",
    "2021-01-02",
    "2021-02-08",
    "2021-04-04",
    "2021-04-05",
    "2021-04-27",
    "2021-05-01",
    "2021-05-02",
    "2021-05-23",
    "2021-06-25",
    "2021-08-15",
    "2021-10-31",
    "2021-11-01",
    "2021-12-25",
    "2021-12-26",
    "2022-01-01",
    "2022-01-02",
    "2022-02-08",
    "2022-04-17",
    "2022-04-18",
    "2022-04-27",
    "2022-05-01",
    "2022-05-02",
    "2022-06-05",
    "2022-06-25",
    "2022-08-15",
    "2022-10-31",
    "2022-11-01",
    "2022-12-25",
    "2022-12-26",
    "2023-01-01",
    "2023-01-02",
    "2023-02-08",
    "2023-04-09",
    "2023-04-10",
    "2023-04-27",
    "2023-05-01",
    "2023-05-02",
    "2023-05-28",
    "2023-06-25",
    "2023-08-15",
    "2023-10-31",
    "2023-11-01",
    "2023-12-25",
    "2023-12-26",
    "2024-01-01",
    "2024-01-02",
    "2024-02-08",
    "2024-03-31",
    "2024-04-01",
    "2024-04-27",
    "2024-05-01",
    "2024-05-02",
    "2024-05-19",
    "2024-06-25",
    "2024-08-15",
    "2024-10-31",
    "2024-11-01",
    "2024-12-25",
    "2024-12-26",
    "2025-01-01",
    "2025-01-02",
    "2025-02-08",
    "2025-04-20",
    "2025-04-21",
    "2025-04-27",
    "2025-05-01",
    "2025-05-02",
    "2025-06-08",
    "2025-06-25",
    "2025-08-15",
    "2025-10-31",
    "2025-11-01",
    "2025-12-25",
    "2025-12-26",
    "2026-01-01",
    "2026-01-02",
    "2026-02-08",
    "2026-04-05",
    "2026-04-06",
    "2026-04-27",
    "2026-05-01",
    "2026-05-02",
    "2026-05-24",
    "2026-06-25",
    "2026-08-15",
    "2026-10-31",
    "2026-11-01",
    "2026-12-25",
    "2026-12-26",
    "2027-01-01",
    "2027-01-02",
    "2027-02-08",
    "2027-03-28",
    "2027-03-29",
    "2027-04-27",
    "2027-05-01",
    "2027-05-02",
    "2027-05-16",
    "2027-06-25",
    "2027-08-15",
    "2027-10-31",
    "2027-11-01",
    "2027-12-25",
    "2027-12-26",
    "2028-01-01",
    "2028-01-02",
    "2028-02-08",
    "2028-04-16",
    "2028-04-17",
    "2028-04-27",
    "2028-05-01",
    "2028-05-02",
    "2028-06-04",
    "2028-06-25",
    "2028-08-15",
    "2028-10-31",
    "2028-11-01",
    "2028-12-25",
    "2028-12-26",
    "2029-01-01",
    "2029-01-02",
    "2029-02-08",
    "2029-04-01",
    "2029-04-02",
    "2029-04-27",
    "2029-05-01",
    "2029-05-02",
    "2029-05-20",
    "2029-06-25",
    "2029-08-15",
    "2029-10-31",
    "2029-11-01",
    "2029-12-25",
    "2029-12-26",
    "2030-01-01",
    "2030-01-02",
    "2030-02-08",
    "2030-04-21",
    "2030-04-22",
    "2030-04-27",
    "2030-05-01",
    "2030-05-02",
    "2030-06-09",
    "2030-06-25",
    "2030-08-15",
    "2030-10-31",
    "2030-11-01",
    "2030-12-25",
    "2030-12-26"
];

export default holidays;