import React from 'react'
import { isNull } from '../../izUtils'

const Loader = ({position}) => {
    return (
        <div style={{ position: (!isNull(position) ? position : 'absolute'), inset: '0px', zIndex: '500', backgroundColor: 'rgba(0, 0, 0, 0.2)', borderRadius: '5px' }}>
            <div className="loader-box" style={{ height: '100%' }}>
                <div className="loader-2"></div>
            </div>
        </div>
    )
}

export default Loader