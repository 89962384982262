import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {t} from 'i18next'
import DataTable from 'react-data-table-component'
import { FormGroup, Button } from 'reactstrap'
import moment from 'moment'

import { customStyles, displayDate } from '../../../izUtils'

const PriceList = ({data, tokenData}) => {
    const Navigate = useNavigate();

    const handleRowClick = (state) => {
        Navigate('/prices/' + state.id + '/get')
    }

    const handleNavigate = (row, type) => {
        Navigate('/prices/' + row.id + '/' + type)
    }

    // This loops through rows and creates them
    const tableColumns = useMemo(() => {
        let columns = [{ 
            name: t('table.active_from'),
            selector: row => displayDate(moment.utc(row.active_from), 'date'),
            sortable: true,
        }]
        
        if (tokenData.parsedToken.type === 'admin') {
            columns.push({
                name: '',
                sortable: false,
                cell: row => <Button color='secondary' onClick={() => handleNavigate(row, 'update')}>{/*<i className= "icon-pencil me-2"></i>*/}{t('edit')}</Button>
            })
        }

        return columns;
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    
    return (
        <FormGroup>
            <DataTable
                columns={tableColumns}
                data={data}
                customStyles={customStyles}
                onRowClicked={handleRowClick}
            />
        </FormGroup>
    )
}

export default PriceList