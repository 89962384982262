import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { t } from 'i18next'
import { useRecoilValue } from 'recoil'
import { Button, Form, Card, CardBody, CardFooter } from 'reactstrap'
import Swal from 'sweetalert2'
import moment from 'moment'

import env from '../../env/src_config'
import { headersState, tokenState } from '../../recoil/recoil'
import { axiosError } from '../../helpers/response'
import { isNull, displayDate } from '../../izUtils'
import { transformer, linker } from '../../helpers/fields'
import Spinner from '../spinner/Spinner'
import { errorStatus } from '../../helpers/response'
import ClientInput from '../form/custom/ClientInput'
import { getSingleItem } from '../../helpers/getSingleItem'
import DisplayFields from '../../helpers/displayFields'

const AddUpdateUser = ({ getUsers, usersConfirmed, users }) => {
    const Navigate = useNavigate();
    const { userId, confirmed } = useParams()

    const headers = useRecoilValue(headersState);
    const tokenData = useRecoilValue(tokenState);
    const [inputs, setInputs] = useState(null)
    const [userRequests, setUserRequests] = useState(null)
    const [showErrors, setShowErrors] = useState(false)

    useEffect(() => {
        if (!isNull(inputs)) setInputs(null)
        setShowErrors(false);

        // Admin can edit users
        if (tokenData.parsedToken.type === 'admin') {
            updateData(false, null);
        } else {
            getSingleItem(headers, userId, '/api/user/', Navigate).then(data => {
                if (!isNull(data)) {
                    if (data.message === 'Dostop zavrnjen') {
                        Navigate('/')
                    } else  {
                        setInputs(data.fields);
                    }
                }
            })
        }

    }, [userId]) // eslint-disable-line react-hooks/exhaustive-deps


    // Get user requests
    useEffect(() => {
        if (tokenData.parsedToken.type === 'admin') {
            let theUser = users.find(user => user.id === userId);
            if (!isNull(theUser) && theUser.list_requests) {
                getUserRequests(false, null);
            }
        }
    
    }, [userId, users]) // eslint-disable-line
    
    const updateData = (save, passedInputs) => {
        let payload = { save };
        if (!isNull(passedInputs)) {
            // Get data from inputs
            const keys = Object.keys(passedInputs);
            payload.data = {};
            for (let i = 0; i < keys.length; i++) {
                if (!isNull(passedInputs[keys[i]].value) && passedInputs[keys[i]].value.length !== 0) {
                    payload.data[keys[i]] = passedInputs[keys[i]].value;
                } else {
                    payload.data[keys[i]] = "";
                }
            }
        }

        let method = "patch";
        if (userId === 'create') method = "post";
        axios[method](env.api + '/api/user/' + userId, payload, {headers}).then(response => {
            if (save) {
                if (!isNull(response.data.state) && response.data.state === 'success') {
                    let title = t('users.update.success.title');
                    let text = t('users.update.success.text');
                    if (userId === 'create') {
                        title = t('users.create.success.title');
                        text = t('users.create.success.text');
                    }

                    if (usersConfirmed) {
                        getUsers({ confirmed: {value: true} }); // Refresh user list
                    } else {
                        title = t('users.listNotConfirmed.success.title');
                        text = t('users.listNotConfirmed.success.text');
                        let filter = { 
                            type: {value: "client"},
                            confirmed: {value: false}
                        }
                        getUsers(filter); // Refresh user list
                    }
                    
                    Swal.fire({
                        title, text,
                        icon: 'success',
                        confirmButtonColor: 'var(--theme-default)',
                        confirmButtonText: t('ok'),
                    }).then(() => {
                        Navigate('/users/' + confirmed)
                    })
                } else {
                    setShowErrors(true)
                    errorStatus(response.data, t);
                } 
            }

            if (!isNull(response.data.data)) setInputs(transformer(response.data.data));
        }).catch(error => {
            axiosError(error, Navigate);
        });
    }

    const getUserRequests = () => {
        let payload = {};
        axios.post(env.api + '/api/user/request/' + userId, payload, {headers}).then(response => {
            setUserRequests(response.data.data);
        }).catch(error => {
            axiosError(error, Navigate);
        });
    }

    const textChange = (value, name) => {
        let clonedInputs = {...inputs}
        clonedInputs[name].value = value;
        updateData(false, clonedInputs);
    }

    // Create fields
    const mapLinker = (field) => {
        const inputlinkerFields = {
            field,
            inputs,
            showErrors,
            textHandler: (value, id) => textChange(value, id),
            selectHandler: (value, id) => textChange(value, id),
            booleanHandler: (value, id) => textChange(value, id),
        }

        if (tokenData.parsedToken.type === 'admin') {
            return linker(inputlinkerFields);
        } else {
            if (!isNull(inputs[field])) return <DisplayFields key={'display-'+inputs[field].name} data={inputs[field]} />
        }
    }

    return (
        <Card className="ribbon-wrapper">
            <CardBody>
                <div className="ribbon ribbon-clip ribbon-primary">{usersConfirmed ? (userId === 'create' ? t('users.create.title') : t('users.update.title')) : t('users.listNotConfirmed.finish')}</div>
                {isNull(inputs) ?
                    <Spinner />
                    :
                    <Form className="theme-form">
                        { ['email', 'name', 'type', 'phone', 'registration_notes', 'password', 'password_confirmation', 'active', 'signature', 'demanding_work'].map(field => mapLinker(field)) }
                        {!isNull(inputs['client']) && <ClientInput data={inputs['client']} onBlur={textChange} showErrors={showErrors} />}
                    </Form>
                }
                {(!isNull(userRequests) && userRequests.length !== 0) &&
                    <div className='mt-5'>
                        <h5>{t('users.create.requests.title')}</h5>
                        <div className='user-facility-request mb-3'>
                            {userRequests.map((request, index) => (
                                <div style={ isNull(request.viewed_at) ? {color: 'red'} : {} }>
                                    {index !== 0 && <hr/>}
                                    {!isNull(request.created_at) && <div className='text-underline'><b>{displayDate(moment.utc(request.created_at), null, true)}</b></div>}
                                    <div className='mb-2'>{request.message}</div>
                                    {!isNull(request.viewed_at) && <div><small>{t('read')}: {displayDate(moment.utc(request.viewed_at), null, true)}</small></div>}
                                </div>
                            ))}
                        </div>
                    </div>
                }
            </CardBody>
            {(tokenData.parsedToken.type === 'admin') &&
                <CardFooter>
                    <div className='text-end'>
                        <Button color="primary" className="mt-2 me-2" onClick={() => updateData(true, inputs)}>{usersConfirmed ? ((userId === 'create') ? t('add') : t('save')) : t('users.listNotConfirmed.finish')}</Button>
                    </div>
                </CardFooter>
            }
        </Card>
    )
}

export default AddUpdateUser