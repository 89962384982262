import { useState, useEffect, useRef } from 'react';

export default function useComponentVisible(initialIsVisible, selectInputId = '') {
    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
    const ref = useRef(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsComponentVisible(false);
        } else {
            // Don't close dropdown when text input is clicked (inside select field)
            if (event.target.id !== selectInputId) setIsComponentVisible((prev) => !prev);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return { ref, isComponentVisible, setIsComponentVisible };
}