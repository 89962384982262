import React, { Fragment, useMemo, useCallback, useRef } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { useNavigate, useParams } from 'react-router-dom';
import {t} from 'i18next'
import { useRecoilValue } from 'recoil';
import moment from 'moment'
import {Dropdown, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import 'moment/locale/sl'

import { getNavigateNumbers, rangeFunc, displayDate, isNull, isInRange } from '../../izUtils';
import { AgendaView } from './view/AgendaView';
import { ReversedAgendaView } from './view/ReversedAgendaView';
import Loader from '../spinner/Loader';
import { handleEvent } from './handleEvent';
import { tokenState } from '../../recoil/recoil';

const localizer = momentLocalizer(moment)

const Calender = ({calendarData, view, loading, lsFilterName}) => {
    const Navigate = useNavigate()
    const {year/*, weekNumber*/} = useParams();
    const token = useRecoilValue(tokenState);

    const calendarRef = useRef(null)

    let events = useMemo(() => {
        let users = [];

        calendarData.data.forEach(user => {
            if (users[user.id] === undefined) {
                user.calendarEvents = [];
                user.tasks.forEach(task => {
                    user.calendarEvents.push({
                        key: user.name + "_task_" + task.id,
                        id: task.id,
                        userId: user.id,
                        title: user.name,
                        client: task.client,
                        facility: task.facility,
                        security_systems: task.security_systems,
                        start: moment.utc(task.service_from).toDate(),
                        end: moment.utc(task.service_to).toDate(),
                        serviceType: task.service_type,
                        assignees: task.assignees,
                        clientContact: {
                            name: task.client_contact_name_report,
                            phone: task.client_contact_phone_report
                        },
                        type: "task",
                    }) 
                })
    
                user.excpetions.forEach(exception => {
                    user.calendarEvents.push({
                        key: user.name + "_exceptions_" + exception.id,
                        id: exception.id,
                        userId: user.id,
                        exception: exception.exception,
                        exception_name: exception.name,
                        title: user.name,
                        start: moment.utc(exception.from).toDate(),
                        end: moment.utc(exception.to).toDate(),
                        type: "exception"
                    }) 
                })

                users[user.id] = user;
            }
        });

        calendarData.events.forEach(event => {
            users[event.technician[0].id].calendarEvents.push({
                key: event.technician[0].title + "_event_" + event.id,
                id: event.id,
                userId: event.technician[0].id,
                title: event.technician[0].title,
                start: moment.utc(event.from).toDate(),
                end: moment.utc(event.to).toDate(),
                project_number: event.project_number,
                facility: event.facility[0],
                type: 'event'
            })
        });

        return users;

    }, [calendarData.data, calendarData.events])

    const onNavigate = (date, view/*, action*/) => {
        let navigateNumbers = getNavigateNumbers(date);
        if (view === 'agenda') {
            Navigate('/schedule/users/' + navigateNumbers.year + '/' + navigateNumbers.week)
        } else {
            Navigate('/schedule/' + navigateNumbers.year + '/' + navigateNumbers.week)
        }
    }

    const handleSelectEvent = useCallback(event => handleEvent(event, t, Navigate, token), []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleSelectWeek = (week) => {
        calendarRef.current.handleNavigate('CUSTOM-WEEK', week.start)
    }

    const handleSelectYear = (year) => {
        calendarRef.current.handleNavigate('CUSTOM-YEAR', moment().isoWeekYear(year).isoWeek(1).startOf('week').toDate())
    }

    const messages = {
        next: t('calendar.next'),
        previous: t('calendar.previous'),
        today: t('calendar.today'),
        month: t('calendar.month'),
        week: t('calendar.week'),
        day: t('calendar.day'),
        agenda: t('calendar.agenda'),
    }

    const eventStyleGetter = (event) => {
        let newStyle = {};
        if (event.type === 'task'){
            newStyle.backgroundColor = "lightgreen"
            newStyle.color = "#000"
        }

        return {style: newStyle};
    }

    let views = {'week': true}
    if (view === 'agenda') views = {'agenda': AgendaView}
    let lsFilterData = JSON.parse(localStorage.getItem(lsFilterName));
    if (lsFilterData?.technician?.value && lsFilterData?.technician?.value.length !== 0) {
        views = {'agenda': ReversedAgendaView}
    }

    // Weeks dropdown
    const rangeWeeks = useMemo(() => {
        let firstDay = null;
        let lastDay = null;
        if (isNull(year)) {
            firstDay = moment.utc().startOf('year').toDate();
            lastDay = moment.utc().endOf('year').toDate();
        } else {
            firstDay = moment.utc().year(year).startOf('year').toDate()
            lastDay = moment.utc().year(year).endOf('year').toDate();
        }

        return rangeFunc(moment.utc(firstDay).startOf('week').toDate(), moment.utc(lastDay).endOf('week').toDate(), 'week');
    }, [year])

    let weeks = useMemo(() => {
        if (!isNull(rangeWeeks)) {
            return rangeWeeks.map(startOfTheWeek => {
                return {
                    number: moment(startOfTheWeek).isoWeek(),
                    start: startOfTheWeek,
                    end: moment(startOfTheWeek).endOf('week').toDate()
                }
             });
        } else {
            return []
        }
    }, [rangeWeeks])
    // End weeks dropdown
    
    // Years dropdown
    let yearsDropdown = useMemo(() => {
        let yearsDropdownArray = [];
        for (let i = parseInt(calendarData.first_task); i <= parseInt(calendarData.last_task); i++) {
            yearsDropdownArray.push(i+'')
        }

        return yearsDropdownArray;
    }, [calendarData.first_task, calendarData.last_task])
    // End years dropdown
    
    return (
        <Fragment>
            {loading && <Loader />}

            <div className='d-flex flex-wrap mb-3'>
                <div className="dropdown-basic mb-1 me-3">
                    <Dropdown className="dropdown" isOpen={true} toggle={() => {}}>
                        <Button color="primary" className="dropbtn">{t('calendar.byYear')} <span><i className="icofont icofont-arrow-down"></i></span></Button>
                        <DropdownMenu className="dropdown-content">
                            {yearsDropdown.map((yearDropdown, index) => {
                                let selectedYear = false;
                                if (isNull(year)) {
                                    if (yearDropdown === moment().year()+'') selectedYear = true;
                                } else {
                                    if (yearDropdown === year) selectedYear = true;
                                }
                                return (
                                    <DropdownItem 
                                        key={index+"-year-"+yearDropdown}
                                        style={selectedYear  ? { backgroundColor:  'var(--theme-default)', color: '#fff' } : {} }
                                        onClick={() => handleSelectYear(yearDropdown)}
                                    >
                                        {yearDropdown}
                                    </DropdownItem>
                                )}
                            )}
                        </DropdownMenu>
                    </Dropdown>
                </div>

                <div className="dropdown-basic mb-1 me-3">
                    <Dropdown className="dropdown" isOpen={true} toggle={() => {}}>
                        <Button color="primary" className="dropbtn">{t('calendar.byWeek')} <span><i className="icofont icofont-arrow-down"></i></span></Button>
                        <DropdownMenu className="dropdown-content custom-date-dropdown-content">
                            {weeks.map((week, index) => {
                                const selectedWeek = isInRange(moment.utc(calendarData.from).toDate(), week.start, week.end, 'day')
                                return (
                                    <DropdownItem 
                                        key={index+"-week-"+week.number}
                                        style={selectedWeek  ? { backgroundColor:  'var(--theme-default)', color: '#fff' } : {} }
                                        onClick={() => handleSelectWeek(week)}
                                    >
                                        <b>{t('calendar.week') + ' ' + week.number}:</b>&nbsp;{displayDate(week.start, 'date')}-{displayDate(week.end, 'date')}
                                    </DropdownItem>
                                )}
                            )}
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </div>

            <Calendar
                ref={calendarRef}
                localizer={localizer}
                defaultDate={moment.utc(calendarData.from).toDate()}
                onSelectEvent={handleSelectEvent}
                // onRangeChange={onRangeChange}
                onNavigate={onNavigate}
                events={events}
                defaultView={view}
                views={views}
                step={30}  
                startAccessor="start"
                endAccessor="end"
                messages={messages}
                eventPropGetter={eventStyleGetter}
            />
        </Fragment>
    );

};

export default Calender;