import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil';
import {t} from 'i18next'
import axios from 'axios';
import { Card, CardBody, FormGroup, CardFooter, Button } from 'reactstrap';
import Swal from 'sweetalert2';

import env from '../../../../env/src_config';
import { axiosError, errorStatus } from '../../../../helpers/response';
import { headersState } from '../../../../recoil/recoil';
import { isNull } from '../../../../izUtils';
import { transformer, linker } from '../../../../helpers/fields';
import Spinner from '../../../spinner/Spinner';

const ContractAdd = ({afterSave}) => {
    const navigate = useNavigate();
    const { linkedSystemId } = useParams();
    const headers = useRecoilValue(headersState);

    const [inputs, setInputs] = useState(null)
    const [showErrors, setShowErrors] = useState(false)

    useEffect(() => {
        updateData(false, {});

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const updateData = (save, passedData) => {
        let payload = { save };

        const keys = Object.keys(passedData);
        payload.data = {};
        for (let i = 0; i < keys.length; i++) {
            if (!isNull(passedData[keys[i]].value) && passedData[keys[i]].value.length !== 0) {
                payload.data[keys[i]] = passedData[keys[i]].value;
            } else {
                payload.data[keys[i]] = "";
            }
        }

        axios.post(env.api + '/api/client/facility/system/contract/add/' + linkedSystemId, payload, {headers}).then(response => {
            if (save) {
                if (!isNull(response.data.state) && response.data.state === 'success') {
                    Swal.fire({
                        title: t('saved'), 
                        text: response.data.message,
                        icon: 'success',
                        confirmButtonColor: 'var(--theme-default)',
                        confirmButtonText: t('ok'),
                    }).then(() => afterSave())
                } else {
                    setShowErrors(true)
                    errorStatus(response.data, t);
                } 
            }
            
            if (!isNull(response.data.data)) setInputs(transformer(response.data.data));
        }).catch(error => {
            axiosError(error, navigate);
        });
    }

    const textChange = (value, name) => {
        let clonedInputs = {...inputs}
        clonedInputs[name].value = value;
        updateData(false, clonedInputs);
    }

    // Create fields
    const mapLinker = (field) => {
        const inputlinkerFields = {
            field,
            inputs,
            showErrors,
            textHandler: (value, id) => textChange(value, id),
            selectHandler: (value, id) => textChange(value, id),
            booleanHandler: (value, id) => textChange(value, id),
        }

        return linker(inputlinkerFields);
    }

    if (isNull(inputs)) {
        return <Spinner />
    } else {
        return (
            <Card className="ribbon-wrapper" style={{ marginBottom: '0px' }}>
                <CardBody>
                    <div className="ribbon ribbon-clip ribbon-primary">{t('contracts.list.title')}</div>
                    <div>
                        <FormGroup>
                            {['contract', 'valid'].map(field => mapLinker(field)) }
                        </FormGroup>
                    </div>
                </CardBody>
                <CardFooter>
                    <div className='text-end'>
                        <Button color="primary" className="mt-2 me-2" onClick={() => updateData(true, inputs)}>{t('save')}</Button>
                    </div>
                </CardFooter>
            </Card>
        )
    }
}

export default ContractAdd