import axios from "axios";

import env from "../env/src_config";
import { isNull } from "../izUtils";
import { axiosError } from "./response";

export const saveInvoiceReceipt = async (headers, id, index) => {
    const lsInventoryReceiptInputs = 'AKODA.inventoryReceiptInputs-'+index;
    const lsInventoryReceiptFiles = 'AKODA.inventoryReceiptFiles-'+index;
    
    let payload = { save: true };

    const invoiceReceipInputs = JSON.parse(localStorage.getItem(lsInventoryReceiptInputs)); // Get inputs from task prices form

    if (!isNull(invoiceReceipInputs)) {
        // Get data from inputs
        const keys = Object.keys(invoiceReceipInputs);
        payload.data = {};
        for (let i = 0; i < keys.length; i++) {
            if (!isNull(invoiceReceipInputs[keys[i]].value) && invoiceReceipInputs[keys[i]].value.length !== 0) {
                payload.data[keys[i]] = invoiceReceipInputs[keys[i]].value;
            } else {
                payload.data[keys[i]] = "";
            }
        }

        // Get data from localStorage
        let inventoryReceiptFiles = JSON.parse(localStorage.getItem(lsInventoryReceiptFiles))
        if (!isNull(inventoryReceiptFiles)) {
            if (isNull(payload.data)) payload.data = {};
            payload.data.files = inventoryReceiptFiles.map(file => file.id);
        }
    }

    const invoiceReceiptResponse = await axios.patch(env.api + '/api/inventory_receipt/' + id, payload, {headers}).then(response => {
        return response.data
    }).catch(error => {
        axiosError(error);
    });

    return invoiceReceiptResponse;
}