import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { t } from 'i18next'
import { FormGroup, Col, Label, ListGroup, ListGroupItem } from 'reactstrap'

import { number_format } from '../../../izUtils'

const TaskPriceItem = ({index, item, handleItem, permissions}) => {
    const {method} = useParams();

    const [inputs, setInputs] = useState({
        name: item.name,
        quantity: item.quantity,
        price_per_unit: item.price_per_unit,
        discount: item.discount,
        price_total: item.price_total,
    })

    useEffect(() => {
        setInputs({
            name: item.name,
            quantity: item.quantity,
            price_per_unit: item.price_per_unit,
            discount: item.discount,
            price_total: item.price_total,
        })
    }, [item.name, item.quantity, item.price_per_unit, item.discount, item.price_total])

    const handleChange = (e) => {
        let clonedInputs = {...inputs};
        clonedInputs[e.target.name] = e.target.value;
        setInputs(clonedInputs)
    }

    const handleBlur = (e) => {
        let clonedInputs = {...inputs};
        const toInt = ['quantity', 'discount'];
        const toFloat = ['price_per_unit'];

        let val = e.target.value.replace(/\./g, '');
        if (toFloat.indexOf(e.target.name) !== -1) {
            val = number_format(val.replace(',', '.'), 2, ',', '.');
            clonedInputs[e.target.name] = val
        } else if (toInt.indexOf(e.target.name) !== -1) {
            val = val.split(',');
            clonedInputs[e.target.name] = isNaN(parseInt(val[0])) ? 0 : parseInt(val[0])
        } else {
            clonedInputs[e.target.name] = e.target.value
        }

        setInputs(clonedInputs)
        handleItem(clonedInputs, index)
    }

    if ((method !== 'get') && (permissions['prices.update'])) {
        return (
            <FormGroup>
                <div className='d-flex flex-wrap align-items-end'>
                    <Col xs="12" sm="5" md="3" className='me-2'>
                        <Label className={"d-block" + (index !== 0 ? " d-md-none" : "")}>{t('form.label.prices.name')}</Label>
                        <input className="form-control" type="text" name={"name"} value={inputs.name} onChange={handleChange} onBlur={handleBlur} />
                    </Col>
                    <Col xs="12" sm="5" md="2" className='me-2'>
                        <Label className={"d-block" + (index !== 0 ? " d-md-none" : "")}>{t('form.label.prices.quantity')}</Label>
                        <input className="form-control" name={"quantity"} value={inputs.quantity} onChange={handleChange} onBlur={handleBlur} />
                    </Col>
                    <Col xs="12" sm="5" md="2" className='me-2'>
                        <Label className={"d-block" + (index !== 0 ? " d-md-none" : "")}>{t('form.label.prices.price_per_unit')}</Label>
                        <input className="form-control" name={"price_per_unit"} value={inputs.price_per_unit} onChange={handleChange} onBlur={handleBlur} />
                    </Col>
                    <Col xs="12" sm="5" md="2" className='me-2'>
                        <Label className={"d-block" + (index !== 0 ? " d-md-none" : "")}>{t('form.label.prices.discount')}</Label>
                        <input className="form-control" name={"discount"} value={inputs.discount} onChange={handleChange} onBlur={handleBlur} />
                    </Col>
                    <Col xs="12"sm="5" md="2" className='me-2'>
                        <Label className={"d-block" + (index !== 0 ? " d-md-none" : "")}>{t('form.label.prices.price_total')}</Label>
                        <div className='d-flex flex-column flex-sm-row align-items-start align-items-sm-end'>
                            <input className="form-control" name={"price_total"} value={number_format(inputs.price_total, 2, ',', '.')} onChange={handleChange} onBlur={handleBlur} disabled/>
                            <i className="icon-trash bigger-icon ms-0 mt-2 ms-sm-2" onClick={() => handleItem(null, index)}></i>
                        </div>
                    </Col>
                </div>
            </FormGroup>
        )
    } else {
        return (
            <ListGroup className="list-group-flush">
                <ListGroupItem>
                    <div className='d-flex flex-wrap align-items-end'>
                        <Col xs="12" sm="5" md="3" className='me-2'>
                            <Label className={"display-label d-block" + (index !== 0 ? " d-md-none" : "")}>{t('form.label.prices.name')}</Label>
                            <div className="display-input">{inputs.name}</div>
                        </Col>
                        <Col xs="12" sm="5" md="2" className='me-2'>
                            <Label className={"d-block" + (index !== 0 ? " d-md-none" : "")}>{t('form.label.prices.quantity')}</Label>
                            <div className="display-input">{inputs.quantity}</div>
                        </Col>
                        <Col xs="12" sm="5" md="2" className='me-2'>
                            <Label className={"d-block" + (index !== 0 ? " d-md-none" : "")}>{t('form.label.prices.price_per_unit')}</Label>
                            <div className="display-input">{inputs.price_per_unit}</div>
                        </Col>
                        <Col xs="12" sm="5" md="2" className='me-2'>
                            <Label className={"d-block" + (index !== 0 ? " d-md-none" : "")}>{t('form.label.prices.discount')}</Label>
                            <div className="display-input">{inputs.discount}</div>
                        </Col>
                        <Col xs="12"sm="5" md="2" className='me-2'>
                            <Label className={"d-block" + (index !== 0 ? " d-md-none" : "")}>{t('form.label.prices.price_total')}</Label>
                            <div className="display-input">{number_format(inputs.price_total, 2, ',', '.')}</div>
                        </Col>
                    </div>
                </ListGroupItem>
            </ListGroup>
        )
    }

}

export default TaskPriceItem