import React, {useState, useEffect, Fragment} from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import { Button, Form, Row, Col, FormGroup, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import env from '../../../env/src_config'
import { headersState, multiSelectLoading } from '../../../recoil/recoil'
import { isNull } from '../../../izUtils'
import { transformer, linker } from '../../../helpers/fields'
import { axiosError, errorStatus } from '../../../helpers/response'
import Spinner from '../../spinner/Spinner'

const AddClient = ({clientdata, modalType, modaltoggle, setClient}) => {
    const navigate = useNavigate();
    
    const headers = useRecoilValue(headersState);
    const setMultiLoading = useSetRecoilState(multiSelectLoading);
    const [inputs, setInputs] = useState(null)
    const [showErrors, setShowErrors] = useState(false)

    useEffect(() => {
        updateData(false, null)
  
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const updateData = (save, passedInputs) => {
        let payload = { save };
        if (!isNull(passedInputs)) {
            // Get data from inputs
            const keys = Object.keys(passedInputs);
            payload.data = {};
            for (let i = 0; i < keys.length; i++) {
                if (!isNull(passedInputs[keys[i]].value) && passedInputs[keys[i]].value.length !== 0) {
                    payload.data[keys[i]] = passedInputs[keys[i]].value;
                } else {
                    payload.data[keys[i]] = "";
                }
            }
        } else { // first time
            if (modalType === 'add') {
                payload.data = { current_data: clientdata.value }
            } else {
                payload.data = { 
                    current_data: clientdata.value,
                    client: modalType,
                    facilities: [],
                }
                clientdata.value.forEach(val => {
                    if (val.client === modalType) {
                        payload.data.facilities = val.facilities;
                    }
                })
            }
        }

        axios.post(env.api + '/api/user/clients/list', payload, {headers}).then(response => {
            if (save) {
                if (!isNull(response.data.valid) && response.data.valid) {
                    let clonedCurrentData = (!isNull(passedInputs.current_data.value) && passedInputs.current_data.value !== '') ? passedInputs.current_data.value : [];
                    if (modalType === 'add') {
                        clonedCurrentData.push({ client: passedInputs.client.value, facilities: passedInputs.facilities.value })
                    } else {
                        clonedCurrentData.forEach(item => {
                            if (item.client === modalType) item.facilities = passedInputs.facilities.value;
                            return item;
                        })
                    }

                    setClient(clonedCurrentData)
                } else {
                    setShowErrors(true)
                    errorStatus(response.data, t);
                } 
            }

            let transformedData = transformer(response.data.data);
            if (modalType !== 'add') transformedData.client.disabled = true; // Disable client field on edit (update) form
            if (!isNull(transformedData)) setInputs(transformedData);
            setMultiLoading(false) // Set loading for field MultiSelect to false
        }).catch(error => {
            axiosError(error, navigate);
        });
    }

    const textChange = (value, name) => {
        let clonedInputs = {...inputs}
        clonedInputs[name].value = value;
        updateData(false, clonedInputs);
    }

    const multiChange = (value, name, component) => {
        setMultiLoading(name + '-' + component?.id)
        let clonedInputs = {...inputs};
        if (clonedInputs[name].value.length === 0) {
            clonedInputs[name].value.push(value)
        } else {
            if (clonedInputs[name].value.indexOf(value) === -1) {
                clonedInputs[name].value.push(value);
            } else {
                const filteredValues = clonedInputs[name].value.filter(el => el !== value);
                clonedInputs[name].value = filteredValues;
            }
        }
        updateData(false, clonedInputs);
    }

    // Create fields
    const mapLinker = (field) => {
        const inputlinkerFields = {
            field,
            inputs,
            showErrors,
            component: {id: 'addClient'},
            textHandler: (value, id) => textChange(value, id),
            selectHandler: (value, id) => textChange(value, id),
            multiHandler: (value, id, component) => multiChange(value, id, component),
            booleanHandler: (value, id) => textChange(value, id),
        }

        return linker(inputlinkerFields);
    }

    if (isNull(inputs)) {
        return <Spinner />
    } else {
        return (
            <Fragment>
                <ModalHeader toggle={modaltoggle}>
                    {t('form.client.addNew')}
                </ModalHeader>
                <ModalBody>
                    <Form className="theme-form">
                        <Row>
                            <Col sm="12">
                                <FormGroup>
                                    { ['current_data', 'client', 'facilities'].map(field => mapLinker(field)) }
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <div className='w-100 d-flex justify-content-between'>
                        <Button color="primary" className="me-2" onClick={() => updateData(true, inputs)}>{t('save')}</Button> 
                        <Button color="danger" className='me-2' onClick={modaltoggle}>{t('close')}</Button> 
                    </div>
                </ModalFooter>
            </Fragment>
        )
    }
}

export default AddClient