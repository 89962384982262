import React, { Fragment, useEffect, useState } from 'react'
import {t} from 'i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil'
import axios from 'axios';
import { Container, Card, CardBody, Button, Row, Col } from 'reactstrap'

import env from '../../env/src_config';
import { headersState, tokenState } from '../../recoil/recoil';
import { isNull } from '../../izUtils';
import Spinner from '../../components/spinner/Spinner';
import Breadcrumbs from '../../layout/breadcrumb/Breadcrumb';
import { transformer } from '../../helpers/fields';
import { axiosError } from '../../helpers/response';
import PaginationTop from '../../components/Pagination/PaginationTop';
import PaginationBottom from '../../components/Pagination/PaginationBottom';
import PriceList from '../../components/prices/list/PriceList';

const DefaultPrices = () => {
    const Navigate = useNavigate();
    const headers = useRecoilValue(headersState);
    const tokenData = useRecoilValue(tokenState);

    const [pricesContract, setPricesContract] = useState(null)
    const [pricesNonContract, setPricesNonContract] = useState(null)
    const [filtersContract, setFiltersContract] = useState({ pricelist_type: {value: 'contract'} })
    const [filtersNonContract, setFiltersNonContrat] = useState({ pricelist_type: {value: 'non_contract'} })


    useEffect(() => {
        getPrices(filtersContract);
        getPrices(filtersNonContract);

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getPrices = (passedFilters) => {
        const keys = Object.keys(passedFilters);
        let payload = { filters: {} };
        for (let i = 0; i < keys.length; i++) {
            if (!isNull(passedFilters[keys[i]].value) && passedFilters[keys[i]].value.length !== 0) {
                payload.filters[keys[i]] = passedFilters[keys[i]].value;
            } else {
                payload.filters[keys[i]] = "";
            }
        }

        axios.post(env.api + '/api/prices/list', payload, {headers}).then(response => {
            if (!isNull(response.data.data)) {
                if (passedFilters.pricelist_type.value === 'non_contract') {
                    setPricesNonContract(response.data.data);
                    if (!isNull(response.data.filters)) setFiltersNonContrat(transformer(response.data.filters));
                } else {
                    setPricesContract(response.data.data);
                    if (!isNull(response.data.filters)) setFiltersContract(transformer(response.data.filters));
                }
            }
        }).catch(error => {
            axiosError(error, Navigate);
        });
    }

    if (isNull(pricesContract)) {
        return <Spinner />
    } else {
        return (
            <Fragment>
                <Breadcrumbs title={t('breadcrumb.priceList')} />
                <Container fluid={true}>
                    <Row>
                        <Col md="6"> 
                            <Card className="ribbon-wrapper">
                                <CardBody>
                                    <div className="ribbon ribbon-clip ribbon-primary">{t('prices.list.titleContract')}</div>
                                    <div>
                                        <PaginationTop filters={filtersContract} getData={clonedFilters => getPrices(clonedFilters)} />
                                        <PriceList data={pricesContract} tokenData={tokenData} />
                                        <PaginationBottom filters={filtersContract} getData={clonedFilters => getPrices(clonedFilters)} />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="6">
                            <Card className="ribbon-wrapper">
                                <CardBody>
                                    <div className="ribbon ribbon-clip ribbon-primary">{t('prices.list.titleNonContract')}</div>
                                        {isNull(pricesNonContract) ?
                                            <Spinner />
                                            :
                                            <div>
                                                <PaginationTop filters={filtersNonContract} getData={clonedFilters => getPrices(clonedFilters)} />
                                                <PriceList data={pricesNonContract} tokenData={tokenData} />
                                                <PaginationBottom filters={filtersNonContract} getData={clonedFilters => getPrices(clonedFilters)} />
                                            </div>
                                        }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <hr />
                    <div className='d-flex flex-wrap justify-content-between mb-4'>
                        <Button color="danger" className="mt-2 me-2" onClick={() => Navigate(-1)}>{t('back')}</Button> 
                        {tokenData.parsedToken.type === 'admin' &&
                            <Button color="primary" className="mt-2 me-2" onClick={() => Navigate('/prices/create')}>{t('prices.list.addNew')}</Button> 
                        }
                    </div>
                </Container>
            </Fragment>
        )
    }
}

export default DefaultPrices