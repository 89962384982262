import React from "react";
import { Button, Input } from 'reactstrap'

const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <div className="d-flex">
        <Input
            id="search"
            type="text"
            placeholder="Ime tehnika..."
            value={filterText}
            onChange={onFilter}
        />
        <Button color="primary" className="ps-3 pe-3 ms-2" onClick={onClear}>{"X"}</Button>
    </div>
);

export default FilterComponent;