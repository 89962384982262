import React, { Fragment } from 'react';
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap'
import { Home } from 'react-feather';
import { Link } from 'react-router-dom'

const Breadcrumbs = ({title, parent, grandParent}) => {
  return (
    <Fragment>
      <Container fluid={true}>
        <div className="page-title">
          <Row>
            <Col sm="6">
              <h3>{title}</h3>
            </Col>
            <Col sm="6">
              <Breadcrumb>
                <BreadcrumbItem><Link to={`${process.env.PUBLIC_URL}/`}><Home /></Link></BreadcrumbItem>
                {grandParent && <BreadcrumbItem>{grandParent}</BreadcrumbItem>}
                {parent && <BreadcrumbItem>{parent}</BreadcrumbItem>}
                <BreadcrumbItem active>{title}</BreadcrumbItem>
              </Breadcrumb>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default Breadcrumbs;