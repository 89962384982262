import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { t } from 'i18next'
import { useRecoilState } from 'recoil'
import { Button, Form, Card, CardBody, CardFooter } from 'reactstrap'
import Swal from 'sweetalert2'

import env from '../../env/src_config'
import { headersState } from '../../recoil/recoil'
import { axiosError } from '../../helpers/response'
import { isNull } from '../../izUtils'
import { transformer, linker } from '../../helpers/fields'
import Spinner from '../spinner/Spinner'
import { errorStatus } from '../../helpers/response'


const AddUpdateSecuritySystem = ({getSecuritySystems}) => {
    const Navigate = useNavigate();
    const [headers] = useRecoilState(headersState);
    const { securitySystemId } = useParams()

    const [inputs, setInputs] = useState(null)
    const [showErrors, setShowErrors] = useState(false)

    useEffect(() => {
        if (!isNull(inputs)) setInputs(null)
        setShowErrors(false);
        updateData(false, null);

    }, [securitySystemId]) // eslint-disable-line react-hooks/exhaustive-deps

    const updateData = (save, passedInputs) => {
        let payload = { save };
        if (!isNull(passedInputs)) {
            // Get data from inputs
            const keys = Object.keys(passedInputs);
            payload.data = {};
            for (let i = 0; i < keys.length; i++) {
                if (!isNull(passedInputs[keys[i]].value) && passedInputs[keys[i]].value.length !== 0) {
                    payload.data[keys[i]] = passedInputs[keys[i]].value;
                } else {
                    payload.data[keys[i]] = "";
                }
            }
        }

        let method = "patch";
        if (securitySystemId === 'create') method = "post";
        axios[method](env.api + '/api/security_system/' + securitySystemId, payload, {headers}).then(response => {
            if (save) {
                if (!isNull(response.data.state) && response.data.state === 'success') {
                    getSecuritySystems() // Refresh security system list

                    let title = t('securitySystems.update.success.title');
                    let text = t('securitySystems.update.success.text');
                    if (securitySystemId === 'create') {
                        title = t('securitySystems.create.success.title');
                        text = t('securitySystems.create.success.text');
                    }

                    Swal.fire({
                        title, text,
                        icon: 'success',
                        confirmButtonColor: 'var(--theme-default)',
                        confirmButtonText: t('ok'),
                    }).then(() => Navigate('/security-systems/' + response.data.id))
                } else {
                    setShowErrors(true)
                    errorStatus(response.data, t);
                } 
            }

            if (!isNull(response.data.data)) setInputs(transformer(response.data.data));
        }).catch(error => {
            axiosError(error, Navigate);
        });
    }

    const textChange = (value, name) => {
        let clonedInputs = {...inputs}
        clonedInputs[name].value = value;
        updateData(false, clonedInputs);
    }

    // Create fields
    const mapLinker = (field) => {
        const inputlinkerFields = {
            field,
            inputs,
            showErrors,
            textHandler: (value, id) => textChange(value, id),
            booleanHandler: (value, id) => textChange(value, id),
        }

        return linker(inputlinkerFields);
    }

    return (
        <Card className="ribbon-wrapper">
            <CardBody>
                <div className="ribbon ribbon-clip ribbon-primary">{(securitySystemId === 'create') ? t('securitySystems.create.title') : t('securitySystems.update.title')}</div>
                {isNull(inputs) ?
                    <Spinner />
                    :
                    <Form className="theme-form mb-5">
                        { ['title', 'system_inspection'].map(field => mapLinker(field)) }
             
                    </Form>
                }
            </CardBody>
            <CardFooter>
                <div className='text-end'>
                    <Button color="primary" className="mt-2 me-2" onClick={() => updateData(true, inputs)}>{(securitySystemId === 'create') ? t('add') : t('securitySystems.update.button')}</Button>
                </div>
            </CardFooter>
        </Card>
    )
}

export default AddUpdateSecuritySystem