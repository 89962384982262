import React, { useState, useEffect } from 'react'
import { FormGroup, Label, Input } from 'reactstrap'
import { isNull } from '../../izUtils';

const Checkbox = ({data, onChange, showErrors, lsFilterName, component}) => {
    const { name, display, errors, title, disabled, mandatory} = data;
    const isFilter = !isNull(lsFilterName)

    const [value, setValue] = useState("")

    useEffect(() => {
        setValue(isNull(data.value) ? false : data.value)
    }, [data.value])

    const handleChange = () => {
        setValue(!value);

        // Save filter data to LS, so it can be used if user clicks "Confirm" button
        if (isFilter) {
            let lsFilter = JSON.parse(localStorage.getItem(lsFilterName))
            if (!isNull(lsFilter)) {
                for (const filterName in lsFilter) {
                    if (filterName === name) {
                        lsFilter[filterName].value = !value;
                        localStorage.setItem(lsFilterName, JSON.stringify(lsFilter))
                    }
                }
            }
        } else {
            onChange(!value, name);
        }
    }

    if (display) {
        return (
            <FormGroup className={isFilter ? 'mt-4 mb-0' : ''}>
                <div className="custom-control custom-checkbox mb-3 form-check">
                    <div className="form-checkbox-group mb-1">
                        <Input
                            className={"custom-control-input"}
                            id={name + (isNull(component) ? '' : '-' + component.id)}
                            type="checkbox"
                            onChange={handleChange}
                            checked={value}
                            disabled={disabled}
                        />
                        <Label className="checkmark" htmlFor={name + (isNull(component) ? '' : '-' + component.id)}></Label>
                        <Label className="custom-control-label" htmlFor={name + (isNull(component) ? '' : '-' + component.id)} style={{ cursor: 'pointer' }} >{title + (mandatory ? " *" : "")}</Label>
                        <div className="invalid-feedback">{"Example invalid feedback text"}</div>
                    </div>
                </div>
                {!isNull(showErrors) && showErrors && !isNull(errors) && errors.length !== 0 && <div style={{ color: "red", marginBottom: "10px" }}>{errors[0]}</div>}
            </FormGroup>
        )
    } else {
        return null;
    }
}

export default Checkbox