import React, { Fragment, useState, useEffect } from 'react'
import {t} from 'i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Card, CardBody, CardFooter } from 'reactstrap'
import { useRecoilState, useRecoilValue } from 'recoil'
import axios from 'axios'
import Modal from 'react-modal';
import Swal from 'sweetalert2'

import env from '../../../env/src_config';
import { headersState, saveContractToLinkedSystem, tokenState } from '../../../recoil/recoil';
import { isNull, modalStyles } from '../../../izUtils'
import { transformer } from '../../../helpers/fields'
import { axiosError, errorStatus } from '../../../helpers/response';
import PaginationTop from '../../Pagination/PaginationTop';
import Spinner from '../../spinner/Spinner';
import PaginationBottom from '../../Pagination/PaginationBottom';
import ContractList from './lists/ContractList';
import ContractAdd from './modals/ContractAdd';
import ContractSearch from '../../contracts/modals/ContractSearch'

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

const ContractComponent = () => {
    const Navigate = useNavigate();
    const { clientId, facilityId, linkedSystemId } = useParams();
    
    const headers = useRecoilValue(headersState);
    const tokenData = useRecoilValue(tokenState);
    const [contractToLinkedSystem, setContractToLinkedSystem] = useRecoilState(saveContractToLinkedSystem);
    const [contracts, setContracts] = useState(null)
    const [filters, setFilters] = useState(null)
    const [modalContractSearch, setModalContractSearch] = useState(false);
    const [modalContractAdd, setModalContractAdd] = useState(false);

    useEffect(() => {
        if (linkedSystemId === 'create') {
            setContracts([]);
            setFilters([]);
        } else {
            getContracts({});
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [linkedSystemId])

    useEffect(() => {
        if (!isNull(contractToLinkedSystem)) {
            try {
                document.getElementById('linkedSystemContracts').scrollIntoView();
            } catch (error) {
                console.log(error)
            }

            addContractToLinkedSystem(contractToLinkedSystem)
            setContractToLinkedSystem(null);
        }
    
    }, [contractToLinkedSystem]) // eslint-disable-line
    

    const getContracts = (passedFilters) => {
        const keys = Object.keys(passedFilters);
        let payload = { filters: {} };
        for (let i = 0; i < keys.length; i++) {
            if (!isNull(passedFilters[keys[i]].value) && passedFilters[keys[i]].value.length !== 0) {
                payload.filters[keys[i]] = passedFilters[keys[i]].value;
            } else {
                payload.filters[keys[i]] = "";
            }
        }
        
        axios.post(env.api + '/api/client/facility/system/contract/list/' + linkedSystemId, payload, {headers}).then(response => {
            if (!isNull(response.data.data)) {
                setContracts(response.data.data);
                if (!isNull(response.data.filters)) setFilters(transformer(response.data.filters));
            }
        }).catch(error => {
            axiosError(error, Navigate);
        });
    }

    const handleContract = (type, row) => {
        if (type === 'single') {
            Navigate('/clients/'+clientId+'/facilities/'+facilityId+'/linked-systems/'+linkedSystemId+'/contracts/'+row.id+'/get')
        } else {
            Swal.fire({
                title: t(`contracts.${type}.title`), 
                text: t(`contracts.${type}.text`),
                icon: 'question',
                confirmButtonColor: 'var(--theme-default)',
                confirmButtonText: t('ok'),
                showCancelButton: true,
                cancelButtonText: t('cancel'),
            }).then(data => {
                if (data.isConfirmed) {
                    let url = '';
                    if (type === 'remove') {
                        url = '/api/client/facility/system/contract/remove/';
                    } else if (type === 'setValid') {
                        url = '/api/client/facility/system/contract/set_valid/';
                    } else if (type === 'setInvalid') {
                        url = '/api/client/facility/system/contract/set_invalid/';
                    }
    
                    axios.post(env.api + url + linkedSystemId + '/' + row.id, {}, {headers}).then(response => {
                        if (response.data.state === 'success') {
                            getContracts({});
                            Swal.fire({
                                text: response.data.message,
                                icon: 'success',
                                confirmButtonColor: 'var(--theme-default)',
                                confirmButtonText: t('ok'),
                            })
                        } else {
                            errorStatus(response.data, t);
                        }
                    }).catch(error => {
                        axiosError(error, Navigate);
                    });
                }
            })
        }
    }


    const addContractToLinkedSystem = (id) => {
        try {
            let payload = {
                save: true,
                data: {
                    contract: id,
                    valid: true
                }
            }
    
            axios.post(env.api + '/api/client/facility/system/contract/add/' + linkedSystemId, payload, {headers}).then(response => {
                if (!isNull(response.data.state) && response.data.state === 'success') {
                    getContracts({});
                }
            }).catch(error => {
                axiosError(error, Navigate);
            });
        } catch (error) {
            console.log(error);
        }
    }

    let style = modalStyles
    style.content.padding = '0px'
    style.content.overflow = 'initial'

    if (isNull(contracts)) {
        return <Spinner />
    } else {
        return (
            <Fragment>
                <Card className="ribbon-wrapper">
                    <CardBody>
                        <div className="ribbon ribbon-clip ribbon-primary">{t('contracts.list.title')}</div>
                        <div>
                            <PaginationTop filters={filters} getData={clonedFilters => getContracts(clonedFilters, false)} />
                            <ContractList data={contracts} handleContract={handleContract} tokenData={tokenData} />
                            <PaginationBottom filters={filters} getData={clonedFilters => getContracts(clonedFilters, false)} />
                        </div>
                    </CardBody>
                    {tokenData.parsedToken.type === 'admin' &&
                        <CardFooter>
                            <div className='text-end'>
                                <Button color="primary" className="mt-2 me-2" onClick={() => setModalContractSearch(true)}>{t('contracts.list.searchDraft')}</Button>
                                <Button color="primary" className="mt-2 me-2" onClick={() => Navigate('/clients/'+clientId+'/facilities/'+facilityId+'/linked-systems/'+linkedSystemId+'/contracts/create?')}>{t('contracts.list.create')}</Button>
                                <Button color="primary" className="mt-2 me-2" onClick={() => setModalContractAdd(true)}>{t('contracts.list.addNew')}</Button>
                            </div>
                        </CardFooter>
                    }
                </Card>

                {/* SEARCH CONTRACTS */}
                <Modal
                    isOpen={modalContractSearch}
                    onRequestClose={() => setModalContractSearch(false)}
                    style={modalStyles}
                    contentLabel="Search Contracts Modal"
                >
                    <ContractSearch />
                </Modal>

                {/* ADD CONTRACTS */}
                <Modal
                    isOpen={modalContractAdd}
                    onRequestClose={() => setModalContractAdd(false)}
                    style={modalStyles}
                    contentLabel="Add Contracts Modal"
                >
                    <ContractAdd afterSave={() => {
                        setModalContractAdd(false);
                        getContracts({});
                    }} />
                </Modal>
            </Fragment>
        )
    }
}

export default ContractComponent