import React, { Fragment } from 'react';
import { Row } from 'reactstrap';
import LeftHeader from './Leftbar';
import Rightbar from './Rightbar';

const Header = () => {
    return (
        <Fragment>
            <div className="page-header">
                <Row className="header-wrapper m-0">
                    <LeftHeader />
                    <Rightbar />
                </Row>
            </div>
        </Fragment>
    );
}

export default Header;