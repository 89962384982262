import React, { Fragment } from 'react'
import {t} from 'i18next'
import { useNavigate } from 'react-router-dom'
import { Container, Row, Col, Button } from 'reactstrap'

import Breadcrumbs from '../../layout/breadcrumb/Breadcrumb';
import AddUpdateContract from '../../components/contracts/AddUpdateContract';

const Contracts = () => {
    const Navigate = useNavigate();

    return (
        <Fragment>
            <Breadcrumbs grandParent={t('breadcrumb.clients')} parent={t('breadcrumb.facilities')} title={t('breadcrumb.contracts')} />
            <Container fluid={true}>
                <Row>
                    <Col sm="12"> 
                        <AddUpdateContract />
                    </Col>
                </Row>
                <div className='d-flex flex-wrap justify-content-between mb-4'>
                    <Button color="danger" className="mt-2 me-2" onClick={() => Navigate(-1)}>{t('back')}</Button> 
                </div>
            </Container>
        </Fragment>
    )
}

export default Contracts