import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {t} from 'i18next'
import { useRecoilState } from 'recoil';
import axios from 'axios'
import { Container, Row, Col, Form, Button, Card, CardBody } from 'reactstrap'
import Swal from 'sweetalert2';

import env from '../env/src_config'
import { headersState } from '../recoil/recoil'
import { isNull } from '../izUtils'
import { axiosError, errorStatus } from '../helpers/response'
import DisplayFields from '../helpers/displayFields';
import Spinner from '../components/spinner/Spinner';
import ListService from '../components/service/ListService';
import { transformerForPublic } from '../helpers/fields';

const TaskApprove = () => {
    const {token}  = useParams();
    const navigate = useNavigate();

    const [headers] = useRecoilState(headersState);
    const [inputs, setInputs] = useState(null)
    const [services, setServices] = useState(null)
    const [showResponse, setShowResponse] = useState(null)

    const lsTaskClientFiles = 'AKODA.taskClientFiles';
    const lsTaskQuotations = 'AKODA.taskQuotations';

    useEffect(() => {
        getData(null)
    
        return () => {
            localStorage.removeItem(lsTaskClientFiles)
            localStorage.removeItem(lsTaskQuotations)
        }
    }, [token]) // eslint-disable-line react-hooks/exhaustive-deps
    
    const getData = async (confirmValue) => {
        let proceed = true;
        let payload = { data: {} }
        payload.save = !isNull(confirmValue)
        payload.data.task_confirm = confirmValue

        if (confirmValue === false) {
            proceed = false;
            await Swal.fire({
                title: t('task.approve.reject_message'),
                input: 'text',
                inputPlaceholder: t('form.placeholder.reject_message'),
                icon: 'question',
                confirmButtonColor: '#var(--theme-default)',
                confirmButtonText: t('ok'),
                showCancelButton: true,
                cancelButtonText: t('cancel'),
            }).then(data => {
                if (data.isConfirmed) {
                    payload.data.reject_message = data.value;
                    proceed = true;
                }
            })
        }

        if (proceed) {
            axios.post(env.api + '/api/task/approve/' + token, payload, { headers }).then(response => {
                if (!isNull(confirmValue)) {
                    if (response.data.state === 'success') {
                        Swal.fire({
                            title: t('saved'),
                            text: response.data.message,
                            icon: 'success',
                            confirmButtonColor: '#var(--theme-default)',
                            confirmButtonText: t('ok'),
                        })
                        setShowResponse(confirmValue)
                    } else {
                        errorStatus(response.data, t);
                    }
                } else { // First time
                    if (!isNull(response.data) && response.data.state === 'error')  {
                        errorStatus(response.data, t);
                    } else {
                        setInputs(transformerForPublic(response.data.task));
                        setServices(response.data.services);
                    }
                }
            }).catch(error => {
                axiosError(error, navigate);
            });
        }
    }

    const mapLinker = (field) => {
        if (!isNull(inputs[field])) return <DisplayFields key={'display-'+inputs[field].name} data={inputs[field]} />
    }

    if (isNull(showResponse)) {
        if (isNull(inputs)) {
            return <Spinner />
        } else {
            return (
                <div className="page-wrapper horizontal-wrapper enterprice-type" id="pageWrapper">
                    <div className="page-body-wrapper" style={{ backgroundColor: '#fff' }}>
                        <div className="page-body mt-0">
                            <Container fluid={true} className="projectlist mt-4">
                                <Row>
                                    <Col sm="12"> 
                                        <Card className="ribbon-wrapper">
                                            <CardBody>
                                                <div className="ribbon ribbon-clip ribbon-primary">{t('task.approve.title')}</div>
                                                <Form className="theme-form">
                                                    <Row>
                                                        <Col md="6"> 
                                                            { ['client', 'facility', 'facility_additional_notes', 'contract_number', 'response_rate'].map(field => mapLinker(field)) }
                                                        </Col>
                                                        <Col md="6"> 
                                                            { ['service_type', 'project_number', 'client_notes', 'invoice_number', 'reject_message'].map(field => mapLinker(field)) }
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                {/* SERVICES */}
                                <Row>
                                    <Col sm="12">
                                        <ListService services={services} type="public" />
                                    </Col>
                                </Row>
                                <div className='d-flex flex-wrap justify-content-between mb-4'>
                                    <Button color="danger" className="mt-2" onClick={() => getData(false)}>{t('approve.button.false')}</Button>
                                    <Button color="primary" className="mt-2 me-2" onClick={() => getData(true)}>{t('approve.button.true')}</Button>
                                </div>
                            </Container>
                        </div>
                    </div>
                </div>
            )
        }
    } else {
        return (
            <div className="page-wrapper horizontal-wrapper enterprice-type" id="pageWrapper">
                <div className="page-body-wrapper" style={{ backgroundColor: '#fff' }}>
                    <div className="page-body mt-0">
                        <Container fluid={true} className="projectlist mt-4">
                            <Row>
                                <Col sm="12">
                                    <Card className="ribbon-wrapper">
                                        <CardBody>
                                            <div className="ribbon ribbon-clip ribbon-primary">{t('task.approve.title')}</div>
                                            {showResponse
                                                ? <h5>{t('task.approve.accept')}</h5>
                                                : <h5>{t('task.approve.decline')}</h5>
                                            }
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        )
    }
}

export default TaskApprove