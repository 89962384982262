import React, { /*useCallback, useState,*/ useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {t} from 'i18next'
import DataTable from 'react-data-table-component'
import { FormGroup } from 'reactstrap'
import moment from 'moment'

import { customStyles, displayDate, isNull } from '../../../izUtils'

const UserList = ({data, usersConfirmed}) => {
    const { userId, confirmed } = useParams()
    const Navigate = useNavigate()

    const handleRowClick = (state) => {
        Navigate('/users/' + confirmed + '/' + state.id)
    }

    // This loops through rows and creates them
    const tableColumns = useMemo(() => {
        let columns = [
            { 
                name: t('table.email'),
                selector: row => row.email,
                sortable: true,
                cell: row => {
                    if (userId === row.id) return <div className="clickableRow" onClick={() => handleRowClick(row)}>{row.email}</div>
                    return row.email
                }
            },
            { 
                name: t('table.type'),
                selector: row => row.type,
                sortable: true,
                cell: row => {
                    if (userId === row.id) return <div className="clickableRow" onClick={() => handleRowClick(row)}>{row.type}</div>
                    return row.type
                }
            },
        ]

        if (!usersConfirmed) {
            columns.push(
                { 
                    name: t('table.email_verified_at'),
                    selector: row => row.email_verified_at,
                    sortable: true,
                    cell: row => {
                        if (userId === row.id) return <div className="clickableRow" onClick={() => handleRowClick(row)}>{!isNull(row.email_verified_at) ? displayDate(moment.utc(row.email_verified_at)) : ''}</div>
                        return !isNull(row.email_verified_at) ? displayDate(moment.utc(row.email_verified_at)) : '';
                    }
                },
            )
        } else {
            columns.push(
                { 
                    name: t('table.unseen_requests'),
                    selector: row => row.unseen_requests,
                    sortable: true,
                    cell: row => {
                        if (userId === row.id) return <div className="clickableRow" onClick={() => handleRowClick(row)}>{row.unseen_requests !== 0 ? row.unseen_requests : ''}</div>
                        return row.unseen_requests !== 0 ? row.unseen_requests : '';
                    }
                },
            )
        }
        
        return columns;
    }, [userId, usersConfirmed]) // eslint-disable-line react-hooks/exhaustive-deps
    
    return (
        <FormGroup>
            <DataTable
                columns={tableColumns}
                data={data}
                customStyles={customStyles}
                onRowClicked={handleRowClick}
            />
        </FormGroup>
    )
}

export default UserList