import React, { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {t} from 'i18next'
import DataTable from 'react-data-table-component'
import { FormGroup } from 'reactstrap'

import { customStyles } from '../../../../izUtils'

const CentralStationList = ({data}) => {
    const { securitySystemId, manufacturerId, systemId, centralStationId } = useParams()
    const Navigate = useNavigate()

    const handleRowClick = (state) => {
        Navigate('/security-systems/' + securitySystemId + '/manufacturers/' + manufacturerId + '/systems/' + systemId + '/central-stations/' + state.id)
    }

    // This loops through rows and creates them
    const tableColumns = useMemo(() => {
        return [
            { 
                name: t('table.title'),
                selector: row => row.title,
                sortable: true,
                cell: row => {
                    if (centralStationId === row.id) return <div className="clickableRow">{row.title}</div>
                    return row.title
                }
            },
        ]
    }, [centralStationId]) // eslint-disable-line react-hooks/exhaustive-deps
    
    return (
        <FormGroup>
            <DataTable
                columns={tableColumns}
                data={data}
                customStyles={customStyles}
                onRowClicked={handleRowClick}
            />
        </FormGroup>
    )
}

export default CentralStationList