import React, {Fragment, useMemo, useState} from 'react'
import {t} from 'i18next'
import { useLocation } from 'react-router-dom';
import { FormGroup, Label, Row, Col, ListGroup, ListGroupItem, Button, Card, CardBody, CardFooter } from 'reactstrap'
import moment from 'moment/moment';
import Modal from 'react-modal';

import { isNull, displayDate, number_format, fieldsWithCheckboxes, modalStyles } from '../izUtils';
import Signature from '../components/form/custom/Signature';

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

const DisplayFields = ({data}) => {
    const {title, name, value, values, type, display} = data;
    let location = useLocation();

    const [modalSSC, setModalSSC] = useState(false);
    const [selectedSecuritySystem, setSelectedSecuritySystem] = useState(null);

    const handleModalButton = (type, option) => {
        if (type === 'ssc') {
            if (!isNull(option)) {
                setSelectedSecuritySystem(option.security_system_components)
                setModalSSC(true)
            } else {
                setSelectedSecuritySystem(null)
                setModalSSC(false)
            }
        }
    }

    let displayValue = useMemo(() => {
        if (type === 'text') {
            if (name === 'response_rate') {
                return ( isNull(value) ? '' : (number_format(value, 2, ',', '.') +'h') )
            } else if (name === 'client_contact_phone_report' || name === 'phone') {
                return ( isNull(value) ? '' : <a href={"tel:"+value}>{value}</a> )
            } else {
                return (isNull(value) ? '' : value )
            }
        } else if (type === 'number') {
            return ((!isNull(value) && value.length !== 0) ? number_format(value, 2, ',', '.') : '')
        } else if (type === 'integer') {
            return (!isNull(value) ? value : '')
        } else if (type === 'date' || type === 'datetime') {
            if (!isNull(value) && value.length !== 0) return displayDate(moment.utc(value), type)
        } else if (type === 'textarea') {
            if (!isNull(value) && value.length !== 0) return <div dangerouslySetInnerHTML={{__html: value.replace(/\n/g, "<br />")}} className={ name === 'technician_notes' ? 'red-alert' : ''} />
        } else if (type === 'multiselect') {
            return values.map(val => {
                if (value.indexOf(val.id) !== -1) {
                    let lsInputs = JSON.parse(localStorage.getItem('AKODA.taskInputs'));
                    if ((name === 'security_systems' && !location.pathname.includes('task/approve')/*Hide buttons for task approve*/)) {
                        return (
                            <div key={val.id} className='d-flex flex-wrap flex-lg-nowrap justify-content-between py-1'>
                                <div className='me-4'>{val.title}</div>
                                <div className='d-flex flex-wrap flex-lg-nowrap justify-content-between'>
                                    <div className='me-2'>
                                        {val.active_contract ?
                                            <span className="badge bg-success">{t("isContract")}</span>
                                            :
                                            <span className="badge bg-danger">{t("noContract")}</span>
                                        }
                                    </div>
                                    {(!isNull(lsInputs) && !isNull(lsInputs.service_type) && lsInputs.service_type.value === 'flat_rate') &&
                                        <Fragment>
                                            {!isNull(val.security_system_inspections) &&
                                                <div className='me-3'>
                                                    <b>{val.security_system_inspections.total_inspections + "/" + val.security_system_inspections.system_checks_number}</b>
                                                </div>
                                            }
                                            {(!isNull(val.check_notes) && val.check_notes.length !== 0) &&
                                                <div className='me-3' style={{ color: 'red' }}>
                                                    {t('form.label.checkNotes')}:&nbsp;<b>{val.check_notes}</b>
                                                </div>
                                            }
                                        </Fragment>
                                    }
                                    {(!isNull(val.security_system_components) && val.security_system_components.length !== 0) &&
                                        <div>
                                            <Button color="primary" className="btn-outline-primary btn-sm py-1 px-2" onClick={() => handleModalButton('ssc', val)}>{t('showSystems')}</Button>
                                        </div>
                                    }
                                </div>
                            </div>
                        )
                    } else {
                        return val.title;
                    }
                } else {
                    return null;
                }
            })
        } else if (type === 'select' || type === 'selectsearch') {
            let findValue = '';
            if (!isNull(values)) findValue = values.find(option => option.id === value)
            if (!isNull(findValue)) {
                if (name === 'facility') {
                    return (
                        <div style={{ position: 'relative' }}>
                            <div>{findValue.title}</div>
                            <small>
                                <ul className='mt-1'>
                                    {!isNull(findValue.google_location) &&
                                        <li>
                                            {findValue.google_location}
                                            <a href={'https://www.google.com/maps/place/' + findValue.google_location} target="_blank" rel="noreferrer" className='ms-2'><i className="fa fa-map-marker"></i></a>
                                        </li>
                                    }
                                    {!isNull(findValue.facility_notes) && <li><b>{t('form.label.notes') + ': '}</b>{findValue.facility_notes}</li>}
                                    {!isNull(findValue.technician_expertise) && <li><b>{t('form.label.technician_expertise') + ': '}</b>{findValue.technician_expertise}</li>}
                                    {!isNull(findValue.salesperson_expertise) && <li><b>{t('form.label.salesperson_expertise') + ': '}</b>{findValue.salesperson_expertise}</li>}
                                </ul>
                            </small>
                        </div>
                    );
                } else if (name === 'client') {
                    return (
                        <Fragment>
                            <div>{findValue.title}</div>
                            {!isNull(findValue.invoice_recipient) && <small>{t('table.invoice_recipient') + ': ' + findValue.invoice_recipient} </small>}
                        </Fragment>
                    )
                } else {
                    return findValue.title;
                }
            }
        } else if (type === 'checkbox' || type === 'bool') {
            return value ? <i className="fa fa-check"></i> : <i className="fa fa-ban"></i>
        } else if (type === 'signature') {
            return <Signature data={data} />
        } else if (type === 'material') {
            if (!isNull(data.value) && data.value.length !== 0) {
                return (
                    <ListGroup className="list-group-flush">
                        <ListGroupItem>
                            <Row>
                                <Col sm="8">
                                    <Label className={"material-input-first"}>{t(`form.label.${data.name}.name`)}</Label>
                                </Col>
                                <Col sm="4">
                                    <Label className={"material-input-first"}>{t(`form.label.${data.name}.quantity`)}</Label>
                                </Col>
                            </Row>
                        </ListGroupItem>
                        {data.value.map((item, index) => (
                            <ListGroupItem key={"material-"+index}>
                                <Row>
                                    <Col sm="8">
                                        <div>{item.name}</div>
                                    </Col>
                                    <Col sm="4">
                                        <div>{item.quantity}</div>
                                    </Col>
                                </Row>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                )
            }
        } else if (type === 'supervisors') {
            if (!isNull(data.value) && data.value.length !== 0) {
                return (
                    <ListGroup className="list-group-flush">
                        <ListGroupItem className='d-none d-sm-block'>
                            <Row>
                                <Col sm="3">
                                    <Label className={"material-input-first"}>{t(`form.label.${data.name}.name`)}</Label>
                                </Col>
                                <Col sm="4">
                                    <Label className={"material-input-first"}>{t(`form.label.${data.name}.phone`)}</Label>
                                </Col>
                                <Col sm="3">
                                    <Label className={"material-input-first"}>{t(`form.label.${data.name}.email`)}</Label>
                                </Col>
                                <Col sm="2">
                                    <Label className={"material-input-first"}>{t(`form.label.${data.name}.main_supervisor`)}</Label>
                                </Col>
                            </Row>
                        </ListGroupItem>
                        {data.value.map((item, index) => (
                            <ListGroupItem key={"supervisors-"+index}>
                                <Row>
                                    <Col sm="3">
                                        <div>{item.name}</div>
                                    </Col>
                                    <Col sm="4">
                                        <div>{item.phone}</div>
                                    </Col>
                                    <Col sm="3">
                                        <div>{item.email}</div>
                                    </Col>
                                    <Col sm="2">
                                        {item.main_supervisor ? <i className="fa fa-check"></i> : <i className="fa fa-ban"></i>}
                                    </Col>
                                </Row>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                )
            }
        } else if (type === 'mail_files') {
            return (
                <div>
                    {data.value.map((file, index) => (
                        <div key={"mail-file-"+file.id+'-'+index} className={index === 0 ? '' : 'mt-1'}>{file.filename}</div>
                    ))}
                </div>
            )
        } else {
            return "Add new field type to 'DisplayFields'"
        }
    }, [type, value, values, modalSSC]) // eslint-disable-line react-hooks/exhaustive-deps

    if (fieldsWithCheckboxes.indexOf(name) !== -1) {
        if (isNull(displayValue) || displayValue.length === 0) {
            if (type === 'date') {
                displayValue = t('form.note.indefinitely')
            } else if (type === 'number') {
                displayValue = t('form.note.priceList')
            }
        }
    }

    if (display && !isNull(displayValue) && displayValue.length !== 0) {
        return (
            <FormGroup name={data.name} >
                {type !== 'signature' && <Label className='display-label'>{title}</Label>}
                <div className={"display-input " + (data.name === 'security_systems' ? "display-input-security-systems"  : "")}>{displayValue}</div>
                {((name === 'security_systems') && (!isNull(selectedSecuritySystem))) &&
                    <Modal
                        isOpen={modalSSC}
                        onRequestClose={() => handleModalButton('ssc', null)}
                        style={modalStyles}
                        contentLabel="Security System Component Modal"
                    >
                        <Card className="ribbon-wrapper" style={{ marginBottom: '0px' }}>
                            <CardBody>
                                <div className="ribbon ribbon-clip ribbon-primary">{t('form.label.securitySystemComponents')}</div>
                                <ul>
                                    {selectedSecuritySystem.map(system => (
                                        <li key={"security_system_components_"+system.id}>
                                            {
                                                (!isNull(system.manufacturer) ? system.manufacturer.title : '') +
                                                (!isNull(system.system) ? ' - ' + system.system.title : '') +
                                                (!isNull(system.central_station) ? ' - ' + system.central_station.title : '') +
                                                ((!isNull(system.component_notes) && system.component_notes.length !== 0) ? ' (' + system.component_notes + ' ) ': '')
                                            }
                                        </li>
                                    ))}
                                </ul>
                            </CardBody>
                            <CardFooter>
                                <div className='text-start'>
                                    <Button className='primary' onClick={() => handleModalButton('ssc', null)}>{t('close')}</Button>
                                </div>
                            </CardFooter>
                        </Card>
                    </Modal>
                }
            </FormGroup>
        )
    }
}

export default DisplayFields