import axios from "axios";

import env from "../env/src_config";
import { axiosError } from "./response";
import { isNull } from "../izUtils";

export async function generateQR(id, headers) {
    const qrCode = await axios.get(env.api + '/api/client/facility/qr/' + id, {headers, responseType: 'blob'}).then(response => {
        if (!isNull(response.data)) {
            return response.data;
        }
    }).catch(error => {
        axiosError(error);
        return error.response.data;
    });

    let qrCodePreview =  URL.createObjectURL(qrCode)
    
    const printWindow = window.open();
    printWindow.document.open();
    printWindow.document.write(`
        <html>
            <head>
                <title>Print</title>
                <style>
                    @media print {
                        /* Remove the date and title from the header */
                        @page {
                            size: auto; /* You can set this to the desired paper size like 'letter' or 'A4' */
                            margin: 0; /* Remove default margins */
                        }

                        /* Remove the page number from the footer */
                        @page :first {
                            margin: 0; /* Remove default margins on the first page */
                        }

                        @page :left {
                            margin-left: 0; /* Remove left margin on all pages */
                        }
                        @page :right {
                            margin-right: 0; /* Remove right margin on all pages */
                        }

                        img {
                            display: block !important; 
                        }
                    }
                </style>
            </head>
            <body>
    `);

    printWindow.document.write(`
        <div style="width: 100%; display: flex; justify-content: center;">
    `);

    printWindow.document.write(`
        <div style="width: 25%; display: flex; flex-direction: column; align-items: center; justify-content: flex-start;">    

            <div style="width: 100%;">
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 695.02 226.06">
                    <defs>
                        <style>
                            .cls-1{fill:none;}
                            .cls-2{clip-path:url(#clip-path);}
                            .cls-3{fill:#000;}
                        </style>
                        <clipPath id="clip-path" transform="translate(0 -0.02)"><rect class="cls-1" x="-43.85" y="-43.56" width="782.36" height="311.81"/></clipPath>
                    </defs>
                    <g class="cls-2">
                        <path class="cls-3" d="M76.27,90.87Q85,85.1,110.92,75.17V67q0-9.82-1.89-13.68-3.21-6.45-13.31-6.46a18.18,18.18,0,0,0-9.16,2.51c-2.9,1.73-4.34,4.12-4.34,7.12a26.56,26.56,0,0,0,.47,3.95,26.15,26.15,0,0,1,.47,3.47c0,3.6-1.17,6.11-3.49,7.52A8.64,8.64,0,0,1,75,72.65a7.9,7.9,0,0,1-6.52-2.79,10.08,10.08,0,0,1-2.26-6.36q0-6.73,8.12-14.07t24-7.42q18.32,0,24.83,12.14c2.33,4.43,3.49,10.9,3.49,19.37v38.64q0,5.58.75,7.7a5,5,0,0,0,5.1,3.76,8.28,8.28,0,0,0,3.58-.68,35.9,35.9,0,0,0,4.91-3.28v5a30.58,30.58,0,0,1-6.51,6.17,18.66,18.66,0,0,1-10.76,3.67q-6.42,0-9.35-4.25a19.49,19.49,0,0,1-3.12-10.11,88.27,88.27,0,0,1-12.27,9.44q-8.6,5.21-16.33,5.2A19.5,19.5,0,0,1,68.52,129a20,20,0,0,1-5.94-14.84q0-13.94,13.69-23.3M110.92,80A85.76,85.76,0,0,0,93,88.08q-13.59,8.58-13.59,19.46,0,8.77,5.66,12.91a13.59,13.59,0,0,0,8.22,2.7,21.89,21.89,0,0,0,11.9-3.57c3.84-2.38,5.75-5.4,5.76-9.06V80Z" transform="translate(0 -0.02)"/>
                    </g>
                    <g class="cls-2">
                        <path class="cls-3" d="M206.08,220.39q9.06-.66,11.89-3.08t2.93-10.4V110.37c0-4.57-.76-7.45-2.27-8.68a10.2,10.2,0,0,0-6.51-1.83c-1.2,0-2.23,0-3.11.1s-1.87.17-2.93.3v-2.7l8.49-2.31c6.49-1.74,12-3.36,16.53-4.92a28.76,28.76,0,0,1,4.82-1.45c.38,0,.59.1.65.3s.19.48.19,1.06v81l26.15-23.51a22.48,22.48,0,0,0,3.78-4.15,5,5,0,0,0,1-2.7,2.63,2.63,0,0,0-2.17-2.78,40.18,40.18,0,0,0-7.56-.87v-2.7h39.37v2.7q-10.58.66-17,4.33T255.74,162.2l-5.57,5.21,17.46,23.13q13.41,17.73,20.2,23.6a25.61,25.61,0,0,0,14.45,6.46v2.51h-42V220.4l3.31-.19a8.34,8.34,0,0,0,2.93-.68,2,2,0,0,0,1.32-1.93,4,4,0,0,0-.47-1.92,14.44,14.44,0,0,0-1.32-2.12l-29.27-39.3V209.9q0,6.65,4.81,8.86c1.89.9,5.14,1.39,9.72,1.45v2.9H206.08v-2.72Z" transform="translate(0 -0.02)"/>
                    </g>
                    <g class="cls-2">
                        <path d="M338.24,143.51q-9.44,8.78-9.44,30.26a69.51,69.51,0,0,0,7.55,32q7.65,14.83,21.15,14.84,10.58,0,16.24-9.93t5.76-26a70.08,70.08,0,0,0-7.27-31.41q-7.28-14.73-21-14.73a18.48,18.48,0,0,0-12.95,5M323,146q12-13.31,30.78-13.3t31,12.53q12.26,12.52,12.26,33.34a50.23,50.23,0,0,1-11.9,33.34q-11.9,14.18-30.77,14.16-18.12,0-30.68-13.57t-12.56-34.13q0-19.15,11.89-32.37" transform="translate(0 -0.02)"/>
                    </g>
                    <g class="cls-2">
                        <path class="cls-3" d="M473.75,210.09c3-3,4.53-5.74,4.53-8.38V160.38q0-12.52-6.61-17.63t-12.74-5.21q-11.88,0-18.5,10.79t-6.61,26.41a54.4,54.4,0,0,0,7,27.65q7.08,12.13,21.05,12.14c4.9,0,8.88-1.48,11.89-4.44m-2.74-75a29.94,29.94,0,0,1,7.27,5.59V110.07c0-3.91-.44-6.6-1.32-8.09s-2.83-2.22-6-2.22a18.88,18.88,0,0,0-2,.09l-3.78.4V97.06l7.75-2q4.23-1.16,8.49-2.41c2.83-.83,5.31-1.63,7.47-2.41,1-.32,2.66-.93,5-1.83l.58.19-.2,10.12c-.06,3.65-.12,7.46-.19,11.36s-.1,7.69-.1,11.47l-.18,78.72c0,4.18.49,7.1,1.5,8.77s3.68,2.49,8,2.49h2.09c.68,0,1.37-.16,2.07-.28v3.17q-.57.19-13.68,4.82l-14.83,5.68-.66-.87V212.17a49.83,49.83,0,0,1-9.15,8.39,29.37,29.37,0,0,1-16,4.34,31.73,31.73,0,0,1-26.06-12.62,46.47,46.47,0,0,1-9.91-29.39q0-20.89,11.9-35.84t29.27-15A26.66,26.66,0,0,1,471,135.13" transform="translate(0 -0.02)"/>
                    </g>
                    <g class="cls-2">
                        <path class="cls-3" d="M539.68,180.6q8.69-5.78,34.65-15.7v-8.19q0-9.83-1.89-13.68-3.21-6.45-13.31-6.45a18.05,18.05,0,0,0-9.16,2.51c-2.9,1.73-4.34,4.11-4.34,7.13a27.93,27.93,0,0,0,.47,3.94,28.15,28.15,0,0,1,.47,3.48q0,5.4-3.49,7.51a8.6,8.6,0,0,1-4.72,1.26,7.89,7.89,0,0,1-6.51-2.79,10.08,10.08,0,0,1-2.26-6.36q0-6.73,8.12-14.07t24-7.41q18.31,0,24.83,12.14c2.33,4.43,3.48,10.89,3.49,19.37v38.63q0,5.6.76,7.71a4.93,4.93,0,0,0,5.1,3.75,8.22,8.22,0,0,0,3.59-.67,35.18,35.18,0,0,0,4.91-3.27v5a30.82,30.82,0,0,1-6.51,6.17,18.67,18.67,0,0,1-10.76,3.66q-6.42,0-9.35-4.24a19.54,19.54,0,0,1-3.11-10.12,88.87,88.87,0,0,1-12.27,9.44q-8.6,5.2-16.33,5.2A19.5,19.5,0,0,1,532,218.78a19.92,19.92,0,0,1-6-14.84q0-14,13.66-23.34m34.65-10.89a86.28,86.28,0,0,0-17.94,8.09q-13.59,8.58-13.59,19.47,0,8.78,5.66,12.91a13.52,13.52,0,0,0,8.21,2.7,22,22,0,0,0,11.9-3.57q5.76-3.56,5.76-9.05Z" transform="translate(0 -0.02)"/>
                    </g>
                    <g class="cls-2">
                        <path class="cls-3" d="M19.63,25A97.43,97.43,0,0,1,48.14,0l1.79,3.17q-15.19,12.35-21.71,24Q16.79,47.53,16.8,81.34q0,25.05,4.35,41.32,7.63,28.51,28.79,42.69l-2.45,3.18q-11.61-5.68-25.12-22.26Q0,118.82,0,83.84,0,50.32,19.63,25" transform="translate(0 -0.02)"/>
                    </g>
                    <g class="cls-2">
                        <path class="cls-3" d="M192.67,112A93.91,93.91,0,0,1,162,158.8q-8.22,6.93-13.79,9.73l-1.79-3.18q14.55-11.28,21.25-23,11.9-20.81,11.89-55,0-27.64-5.47-45.18-7.65-24.75-27.66-38.93L148.88,0q14.91,8.49,27.76,25.63a96.46,96.46,0,0,1,19.73,59.16,98.08,98.08,0,0,1-3.7,27.16" transform="translate(0 -0.02)"/>
                    </g>
                    <g class="cls-2">
                        <path class="cls-3" d="M660.93,167.05h0ZM695,175.33a2,2,0,0,0-1.9-2l-21.91-2.59a3.3,3.3,0,0,1-2.28-2.3L666,144.75a2,2,0,0,0-2-1.94h-5.38a2,2,0,0,0-1.95,1.94l-2.88,23.67a3.32,3.32,0,0,1-2.28,2.31l-22,2.62a2,2,0,0,0-1.9,2v5.49a2,2,0,0,0,1.9,2l22,2.6a3.28,3.28,0,0,1,2.28,2.3l2.91,23.81a2,2,0,0,0,1.95,1.94H664a2,2,0,0,0,2-1.94l3-23.84a3.3,3.3,0,0,1,2.29-2.29l21.86-2.58a2,2,0,0,0,1.9-2v-5.51Z" transform="translate(0 -0.02)"/>
                    </g>
                </svg>
            </div>

            <div style="text-align: center; font-size: 13px; font-weight: bold; margin-top: 20px;">PRIJAVA SERVISA IN NAPAKE</div>
    `);

    printWindow.document.write(`
        </div>
    `);

    printWindow.document.write(`
        <img src="${qrCodePreview}" alt="QR Code" style="width: 29%;" />
    `);

    printWindow.document.write(`
        </div>
    `);

    printWindow.document.write('</body></html>');
    printWindow.document.close();

    setTimeout(() => {
        printWindow.print();
    }, 100);
    // printWindow.close();
}