import React, { /*useCallback, useState,*/ useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {t} from 'i18next'
import DataTable from 'react-data-table-component'
import { FormGroup } from 'reactstrap'

import { customStyles } from '../../../izUtils'

const UserList = ({data}) => {
    const { technicianId } = useParams()
    const Navigate = useNavigate()

    const handleRowClick = (state) => {
        Navigate('/schedule/exception/create/' + state.id)
    }

    const tableColumns = useMemo(() => {
        return [
            { 
                name: t('table.email'),
                selector: row => row.email,
                sortable: true,
                cell: row => {
                    if (technicianId === row.id) return <div className="clickableRow" onClick={() => handleRowClick(row)}>{row.email}</div>
                    return row.email
                }
            },
            { 
                name: t('table.name'),
                selector: row => row.name,
                sortable: true,
                cell: row => {
                    if (technicianId === row.id) return <div className="clickableRow" onClick={() => handleRowClick(row)}>{row.name}</div>
                    return row.name
                }
            },
        ]
    }, [technicianId]) // eslint-disable-line react-hooks/exhaustive-deps
    
    return (
        <FormGroup>
            <DataTable
                columns={tableColumns}
                data={data}
                customStyles={customStyles}
                onRowClicked={handleRowClick}
            />
        </FormGroup>
    )
}

export default UserList