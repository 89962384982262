import axios from "axios";

import env from "../../env/src_config";
import { axiosError } from "../../helpers/response";
import { isNull } from "../../izUtils";

export const getFilePreview = (file, headers, apiUrl) => {
    let url = '/api/public/file/';
    if (!isNull(apiUrl)) {
        url = apiUrl;
    } else if (!isNull(headers.Authorization) && headers.Authorization !== '') {
        url = '/api/private/file/';
    }

    const response = axios.get(env.api + url + file.id, {headers, responseType: 'blob'}).then(response => {
        return response.data;
    }).catch(error => {
        axiosError(error);
    });

    return response;
}