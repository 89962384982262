import React, { Fragment, useEffect, useState, useRef } from 'react'
import {t} from 'i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil'
import axios from 'axios';
import { Accordion } from 'react-bootstrap';
import { Container, Card, CardBody, Button, Row, Col, Collapse } from 'reactstrap'
import Chart from 'chart.js/auto';
import Swal from 'sweetalert2';

import env from '../../env/src_config';
import { headersState } from '../../recoil/recoil';
import { isNull, getRandomInt, displayDate } from '../../izUtils'
import Spinner from '../../components/spinner/Spinner';
import Breadcrumbs from '../../layout/breadcrumb/Breadcrumb';
import { transformer } from '../../helpers/fields';
import { axiosError } from '../../helpers/response';
import Filter from '../../components/filters/Filter';
import Loader from '../../components/spinner/Loader';

const Statistic = () => {
    const navigate = useNavigate();
    
    const headers = useRecoilValue(headersState);
    const [stats, setStats] = useState(null)
    const [filters, setFilters] = useState(null)
    const [isFilterOpen, setIsFilterOpen] = useState(true);
    const [loading, setLoading] = useState(false);

    const lsFilterName = 'AKODA.statisticFilter';

    const canvasChart = useRef(null);
    const myChart = useRef(null);
    
    useEffect(() => {
        const lsFilter = JSON.parse(localStorage.getItem(lsFilterName));
        updateData(isNull(lsFilter) ? null : lsFilter, false);

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!isNull(myChart.current)) {
            myChart.current.destroy(); 
        }

        if (stats?.datasets.length > 0) {
            myChart.current = new Chart(canvasChart.current, {
                type: 'line',
                data: stats,
                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                          position: 'top',
                        },
                        title: {
                          display: true,
                          text: filters.statistics.values.find(stat => stat.id === filters.statistics.value).title
                        }
                    },
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                }
            });
        }

        // eslint-disable-next-line
    }, [stats])

    const updateData = (passedFilters, save) => {
        setLoading(true);
        let payload = { filters: {} };

        if (!isNull(passedFilters)) {
            const keys = Object.keys(passedFilters);
            for (let i = 0; i < keys.length; i++) {
                if (!isNull(passedFilters[keys[i]].value) && passedFilters[keys[i]].value.length !== 0) {
                    payload.filters[keys[i]] = passedFilters[keys[i]].value;
                } else {
                    payload.filters[keys[i]] = "";
                }
            }
        }

        axios.post(env.api + '/api/statistics', payload, {headers}).then(response => {
            if (!isNull(response.data?.filters)) {                
                const transformedFilters = transformer(response.data.filters)
                setFilters(transformedFilters);
                localStorage.setItem(lsFilterName, JSON.stringify(transformedFilters));
                if (save) checkFilters(transformedFilters);
            }

            if (!isNull(response.data?.data)) {
                let labels = [];
                let datasets = [];

                response.data.data.forEach(stat => {
                    labels.push(displayDate(stat.date, 'date'));
                    stat.technicians.forEach((tech, index) => {
                        if (datasets[index] === undefined) {
                            const randomColor = `rgba(${getRandomInt(255)}, ${getRandomInt(255)}, ${getRandomInt(255)}, 1)`;
                            datasets[index] = {
                                label: tech.name,
                                data: [tech.data],
                                borderWidth: 2,
                                borderColor: randomColor,
                                backgroundColor: randomColor,
                            };
                        } else {
                            datasets[index].data.push(tech.data);
                        }
                    });
                })

                const data = {
                    labels: labels,
                    datasets: datasets
                };

                setStats(data);
            }

            setLoading(false);
        }).catch(error => {
            axiosError(error, navigate);
            setLoading(false);
        });
    }

    const checkFilters = (passedFilters) => {
        let mandatory = [];
        for (let filter in passedFilters) {
            if (passedFilters[filter].mandatory) mandatory.push(passedFilters[filter])
        }

        if (mandatory.length > 0) {
            let errorTxt = '';
            mandatory.forEach(item => {
                if (!isNull(item.errors)) {
                    errorTxt += item.errors.join('<br>') + '<br>';
                }
            })

            if (errorTxt.length > 0) {
                Swal.fire({
                    title: t('warning'),
                    html: errorTxt,
                    icon: 'warning',
                    confirmButtonColor: 'var(--theme-default)',
                    confirmButtonText: t('ok')
                });
            }
        }
    }

    const clearData = () => {
        localStorage.removeItem(lsFilterName);
        updateData(null, false);
    }

    if (isNull(stats)) {
        return <Spinner />
    } else {
        const filterFields = ['from', 'to', 'statistics', 'technician', 'facility', 'type']
        return (
            <Fragment>
                <Breadcrumbs parent="" title={t('breadcrumb.statistic')} />
                <Container fluid={true}>
                    <Row>
                        <Col sm="12"> 
                            <Card className="ribbon-wrapper">
                                <CardBody>
                                    <div className="ribbon ribbon-clip ribbon-primary">{t('statistic.title')}</div>
                                    <Row>
                                        <Col xl="6">
                                            <Accordion className='mb-3'>
                                                <div className="default-according style-1" id="archivefilter">
                                                    <Button color="secondary" className="acc-btn d-flex justify-content-between align-items-center" data-toggle="collapse" onClick={() => setIsFilterOpen(!isFilterOpen)}>
                                                        {t('filters')}
                                                        <div className={'filter-icon' + (isFilterOpen ? ' rotate-icon' : '')}>
                                                            <i className="fa fa-chevron-down"></i>
                                                        </div>
                                                    </Button>
                                                    <Collapse isOpen={isFilterOpen}>
                                                        <div className='p-3'>
                                                            <Filter filterFields={filterFields} filters={filters} getData={(clonedFilters) => updateData(clonedFilters, true)} lsFilterName={lsFilterName} clearData={clearData} />
                                                        </div>
                                                    </Collapse>
                                                </div>
                                            </Accordion>
                                        </Col>
                                        <Col xl="6">
                                            <div style={{ position: 'relative'}}>
                                                {loading && <Loader position="absolute" />}
                                                {/* {stats?.datasets.length == 0 &&
                                                    <div>NO DATA</div>    
                                                } */}
                                                <canvas className="" ref={canvasChart}></canvas>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <hr/>
                    <div className='d-flex flex-wrap justify-content-between mb-4'>
                        <div>
                            <Button color="danger" className="mt-2 me-2" onClick={() => navigate('/')}>{t('back')}</Button> 
                        </div>
                    </div>
                </Container>
            </Fragment>
        )
    }
}

export default Statistic