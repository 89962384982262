import React, { useEffect, useState } from 'react'
import { isNull } from '../../izUtils'

const TimeInput = ({className, name, disabled, onChange, value}) => {
    const [time, setTime] = useState("");

    useEffect(() => {
        if (!isNull(value)) setTime(value)

    }, [value])

    const handleDate = (e) => {
        setTime(e.target.value)
    };

    const handleBlur = (e) => {
        onChange(e);
    }

    return (
        <div className={'d-flex align-items-center' + (isNull(className) ? '' : ' '+className)}>
            <input type="time" name={name} className="form-control digits form-control" disabled={disabled} value={time} onChange={handleDate} onBlur={handleBlur} />
        </div>
    )
}

export default TimeInput